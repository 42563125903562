<template>
  <a-modal v-model:visible="visible" title="Image" @ok="hide" @cancel="hide" :cancelText="$t('recharge.cancel.text')">
    <div style="display: flex; align-items: center; justify-content: center">
      <img :src="show_url" alt="" style="width: 100%" />
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'RechargeVue',
  components: {},
  data() {
    return {
      //存币
      visible: false,

      //
      show_url: ''
    }
  },
  created() {},
  methods: {
    onOk() {
      this.$emit('onSend', this.img)
    },
    /**
     * 存币
     */
    show(url) {
      this.show_url = url
      this.visible = true
    },
    hide() {
      this.visible = false
    }
  }
}
</script>

<style></style>
