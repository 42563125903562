<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('setting.invitation') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <div style="position: relative">
    <img src="../../../static/share.jpg" alt="" style="width: 100%" />
    <button type="dashed" class="copy-btn" @click="onCopy()">
      {{ $t('setting.copy') }}
    </button>
  </div>

  <!-- 邀请图 -->
  <!-- <div :style="{ background: '#fff', padding: '20px 20px', marginTop: '10px', paddingBottom: '130px', display: 'flex', alignItems: 'center', justifyContent: 'center' }">
    <div style="width: 600px">
      <div>
        <h2>{{ $t('_inv._title') }}</h2>
      </div>
      <div>
        <img src="@/static/inv/inv.png" alt="" />
      </div>
      <div>
        <div style="margin-top: 20px">
          <h3 style="font-weight: 700">{{ $t('_inv._t1') }}</h3>
          <p>{{ $t('_inv._desc1') }}</p>
        </div>
        <div style="margin-top: 20px">
          <h3 style="font-weight: 700">{{ $t('_inv._t2') }}</h3>
          <p>{{ $t('_inv._desc2') }}</p>
        </div>
      </div>
    </div>
  </div> -->

  <!-- 弹框 -->
  <a-modal v-model:visible="visible" :title="$t('advertise.shop.modal.title')">
    <div style="display: flex; align-items: flex-end">
      <div>{{ $t('advertise.shop.modal.desc') }}:</div>
      <div style="margin-left: 10px">
        <span style="color: #ff5722; font-size: 15px; font-weight: 700">$</span>
        <span style="color: #ff5722; font-size: 20px; font-weight: 700">{{ total }}</span>
      </div>
    </div>
    <template #footer>
      <a-button key="submit" type="primary" :loading="btnLoad" @click="handleOk">{{ $t('advertise.shop.modal.btn') }}</a-button>
    </template>
  </a-modal>
</template>

<script>
// import crypto from '@/utils/crypto'
import Base64 from '@/utils/base64'
export default {
  name: 'settingVue',
  components: {},
  data() {
    return {
      info: {},
      http: ''
    }
  },
  created() {
    this.info = this.$common.getInfo()

    //获取信息
    const that = this
    this.$api.user.getMyAgent().then((res) => {
      res
      // let sid = crypto.set(`${that.info.sid}&${res.data.inv_store}`)
      // let sid = crypto.set(`123456&NCNULQ`)
      var base64 = new Base64()
      let sid = base64.encode(`${that.info.sid}&${res.data.inv_store}`) //加密
      console.log(sid)
      // let sid = `sid=${that.info.sid}&code=${res.data.inv_store}`
      let param = window.location.search
      that.http = window.location.host + `/#/beseller?code=${sid}`
      //生成加密url
      console.log(that.http, param)
    })
  },
  methods: {
    /**
     * 复制地址
     */
    onCopy() {
      // copyData想要复制的内容
      this.$copyText(this.http)
        .then(() => {
          this.$message.success('copy success,' + this.http)
        })
        .catch(() => {
          this.$message.error('copy fail')
        })
    }
  }
}
</script>
<style scoped>
.copy-btn {
  position: absolute;
  left: 60%;
  bottom: 13%;
  width: 6.5%;
  height: 45px;
  border: 0;
  padding: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  color: #999;
}

.copy-btn:hover {
  background-color: #fff;
  color: #fa8d4a;
}
.copy-btn:active {
  background-color: antiquewhite;
}
</style>
<style scoped src="../../../static/css/store-css.css"></style>
