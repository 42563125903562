<template>
  <main class="h_9 ff">
    <div class="h_3 fg">
      <div class="eY e_7">
        <div class="i-flocktory" data-fl-action="track-category-view" data-fl-category-id="45120"></div>
        <div class="fM">
          <div>
            <div class="e_">
              <div class="eZ">
                <!-- 商品列表 -->
                <div class="fo">
                  <!-- 头部筛选栏 -->
                  <div class="fw">
                    <div class="vH">
                      <div class="vI">
                        <div class="vJ">
                          <!-- 筛选1 -->
                          <div class="fq" hidden>
                            <div class="fs">
                              <div class="ft">
                                <div class="tU">
                                  <div class="t_2 tX">
                                    <button type="button" class="t_0">
                                      <span class="t_">
                                        <span style="vertical-align: inherit">
                                          <span style="vertical-align: inherit">人气</span>
                                        </span> </span
                                      ><i class="eO eQ" aria-hidden="true"
                                        ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                          <use xlink:href="#arrow_short_bottom"></use>
                                        </svg>
                                      </i>
                                    </button>
                                    <div class="Ab t_6 An Au Ar Ad" style="top: 40px; left: 0px">
                                      <div class="Ae Ay">
                                        <ul class="l_1 t_3">
                                          <li class="oS o_7 o_2 oT t_4 t_5">
                                            <div class="oW">
                                              <span style="vertical-align: inherit">
                                                <span style="vertical-align: inherit">人气</span>
                                              </span>
                                            </div>
                                          </li>
                                          <li class="oS o_7 o_2 oT t_4">
                                            <div class="oW">
                                              <span style="vertical-align: inherit">
                                                <span style="vertical-align: inherit">便宜第一</span>
                                              </span>
                                            </div>
                                          </li>
                                          <li class="oS o_7 o_2 oT t_4">
                                            <div class="oW">
                                              <span style="vertical-align: inherit">
                                                <span style="vertical-align: inherit">亲爱的先</span>
                                              </span>
                                            </div>
                                          </li>
                                          <li class="oS o_7 o_2 oT t_4">
                                            <div class="oW">
                                              <span style="vertical-align: inherit">
                                                <span style="vertical-align: inherit">新商品优先</span>
                                              </span>
                                            </div>
                                          </li>
                                          <li class="oS o_7 o_2 oT t_4">
                                            <div class="oW">
                                              <span style="vertical-align: inherit">
                                                <span style="vertical-align: inherit">按折扣金额</span>
                                              </span>
                                            </div>
                                          </li>
                                          <li class="oS o_7 o_2 oT t_4">
                                            <div class="oW">
                                              <span style="vertical-align: inherit">
                                                <span style="vertical-align: inherit">按评级</span>
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <button class="tW tV" type="button">
                                    <i class="eO eR" aria-hidden="true"
                                      ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                        <use xlink:href="#sort"></use>
                                      </svg>
                                    </i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="fu"></div>
                          </div>

                          <!-- 按品牌排序 -->
                          <label class="Ey vP EA" hidden>
                            <div data-testid="fasetsV2_tag_brands">
                              <input type="checkbox" name="brands" class="EG" />
                              <div class="EB vO EN EE ER">
                                <span class="EJ"
                                  ><span class="vM">
                                    <span style="vertical-align: inherit">
                                      <span style="vertical-align: inherit">品牌</span>
                                    </span>
                                  </span></span
                                >
                              </div>
                            </div>
                          </label>

                          <!-- 按价格排序 -->
                          <label class="Ey vP EA" hidden>
                            <div data-testid="fasetsV2_tag_price">
                              <input type="checkbox" name="price" class="EG" />
                              <div class="EB vO EN EE ER">
                                <span class="EJ"
                                  ><span class="vM">
                                    <span style="vertical-align: inherit">
                                      <span style="vertical-align: inherit">价格</span>
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 商品列表栏 -->
                  <div class="e_7" style="padding-bottom: 55px" :infinite-scroll-immediate-check="false" :infinite-scroll-disabled="scrollDisabled" infinite-scroll-watch-disabled="scrollDisabled" v-infinite-scroll="loadMore" infinite-scroll-distance="5">
                    <div class="fx">
                      <!-- 加载中 -->
                      <!-- <Loading v-if="loadmore && goodsData.length == 0"></Loading> -->
                      <!-- 商品列表 -->
                      <div id=":Rldd5qt:" class="uA uD">
                        <!-- 第一行商品 -->
                        <section id="3457937" class="Ig Ij Io uC" v-for="(vo, i) in goodsData" :key="i">
                          <!-- 喜欢 -->
                          <div class="_7_6 _1T">
                            <div class="Rd Rg">
                              <div class="Re">
                                <button type="button" role="button" class="fS fW ga f_ gp gm">
                                  <div class="fU">
                                    <div class="fV">
                                      <span class="_8a">
                                        <i class="eO eS _7_8 _7_9 _8d" aria-hidden="true">
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                            <use xlink:href="#heartHollowBg"></use>
                                          </svg>
                                        </i>
                                      </span>
                                    </div>
                                    <div class="fV">
                                      <span class="_8f">
                                        <span style="vertical-align: inherit">
                                          <span style="vertical-align: inherit">love</span>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <!-- 封面图 -->
                          <div class="bgE bgN bgR" aria-hidden="true">
                            <router-link :to="{ name: 'detail', query: { id: vo.id } }">
                              <div class="bgM">
                                <div class="bgT"></div>
                                <div class="bgT" v-for="ii in vo.imgs.length >= 4 ? 4 : vo.imgs.length" :key="ii"></div>
                                <ul class="bgJ">
                                  <li class="bgK">
                                    <div class="pU p_0 bgI p_4">
                                      <picture class="HI">
                                        <source srcset="@/static/svg/download.svg" type="image/svg" />
                                        <img class="pV pX pW" :src="vo.img" fetchpriority="auto" width="240" height="240" />
                                      </picture>
                                    </div>
                                  </li>
                                  <li class="bgK" v-for="ii in vo.imgs.length >= 4 ? 4 : vo.imgs.length" :key="ii">
                                    <div class="pU p_0 bgI p_4">
                                      <picture class="HI">
                                        <source srcset="@/static/svg/download.svg" type="image/svg" />
                                        <img class="pV pX pW" :src="vo.imgs[ii] && vo.imgs[ii].img" fetchpriority="auto" width="240" height="240" />
                                      </picture>
                                    </div>
                                  </li>
                                </ul>
                                <ul class="bgU">
                                  <li class="bgV"></li>
                                  <li class="bgV" v-for="ii in vo.imgs.length >= 4 ? 4 : vo.imgs.length" :key="ii"></li>
                                </ul>
                              </div>
                            </router-link>
                          </div>
                          <!-- 价格折扣 -->
                          <div class="bg_0 bg_1">
                            <div class="bg_8">
                              <div style="vertical-align: inherit">
                                <div style="vertical-align: inherit">-</div>
                              </div>
                              <div class="bha">
                                <div style="vertical-align: inherit">
                                  <div style="vertical-align: inherit">{{ $common.zkPrice(vo.price, vo.cost_price) }}</div>
                                </div>
                              </div>
                              <div style="vertical-align: inherit">
                                <div style="vertical-align: inherit">%</div>
                              </div>
                            </div>
                            <div class="bg_2">
                              <div class="bg_3 bg_5">
                                <div style="vertical-align: inherit">
                                  <div style="vertical-align: inherit">$ {{ vo.price }}</div>
                                </div>
                              </div>
                              <div class="bg_4">
                                <div style="vertical-align: inherit">
                                  <div style="vertical-align: inherit">$ {{ vo.cost_price }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- 商品名称 -->
                          <router-link class="Iq" :to="{ name: 'detail', query: { id: vo.id } }">
                            <div class="Ip2">
                              <div style="vertical-align: inherit">
                                <div style="vertical-align: inherit">{{ vo.name }}</div>
                              </div>
                            </div>
                          </router-link>
                          <div class="bhc">
                            <span class="WVbEJ tKey9">
                              <span role="img" class="T2Mzf" aria-label="4 out of 5 stars">
                                <span class="GocDN dUBUg"></span>
                                <span class="GocDN bFCnj" :style="'width: ' + 20 * parseInt(vo.star) + '%'"></span>
                              </span>
                              <!-- <span class="HZv8u">(219)</span> -->
                            </span>
                            <span class="bhf"> </span>
                          </div>
                          <!-- 店铺名称 -->
                          <!-- <ul class="bhg bhk">
                            <li class="bhh">
                              <div class="R_5 Sc R_8 Se Sg" style="background-color: #000; color: #fff">
                                <div class="Sd">
                                  <span style="vertical-align: inherit">
                                    <span style="vertical-align: inherit">{{ vo.store && vo.store.name }}</span>
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul> -->
                        </section>
                        <!--/$-->
                        <div class="uC" v-if="loadmore && goodsData.length < 21">
                          <div class="uF bqh" style="height: 385px; width: 100%"></div>
                        </div>
                        <div class="uC" v-if="loadmore && goodsData.length < 22">
                          <div class="uF bqh" style="height: 385px; width: 100%"></div>
                        </div>
                        <div class="uC" v-if="loadmore && goodsData.length < 23">
                          <div class="uF bqh" style="height: 385px; width: 100%"></div>
                        </div>
                        <div class="uC" v-if="loadmore && goodsData.length < 24">
                          <div class="uF bqh" style="height: 385px; width: 100%"></div>
                        </div>
                      </div>
                      <!--/$-->
                    </div>
                    <!-- 加载更多 -->
                    <div v-show="loadmore" style="display: flex; align-items: center; justify-content: center">
                      <img class="_2O2Fm" src="@/static/img/loading.gif" />
                    </div>
                  </div>
                </div>
                <div v-show="goodsData.length > 0">
                  <div class="e_9">
                    <div>
                      <button type="button" role="button" class="fS fW ga f_1 gh gl f_4" @click="onLoadMore()">
                        <div class="fU">
                          <div class="fV">{{ $t('_n.ckgd') }}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>

                <!-- 推广板块 -->
                <div class="fn">
                  <!-- 如你所见 -->
                  <section class="jh -jk">
                    <div class="jj">
                      <header class="jm">
                        <h2 class="bix biW biz jn">
                          <span class="jo">{{ $t('_n2._rnsj') }}</span>
                        </h2>
                      </header>
                      <div class="Jd">
                        <swiper
                          :modules="modules"
                          :slidesPerView="6"
                          :spaceBetween="24"
                          :navigation="{
                            nextEl: '.sl21-next',
                            prevEl: '.sl21-prev'
                          }"
                          :loop="false"
                          :autoplay="{
                            delay: 6000,
                            disableOnInteraction: false
                          }"
                          class="Jl"
                        >
                          <swiper-slide class="-bhDD" v-for="(vo, i) in recommend" :key="i">
                            <section class="Ig Ij Im __9" style="background: #fff">
                              <div class="bgu bgx bgy" aria-hidden="true">
                                <div class="pU p_0 bgw">
                                  <picture class="HI">
                                    <source media="(min-width: 1024px)" srcset="@/static/svg/download.svg" type="image/svg" />
                                    <source media="(min-width: 600px) and (max-width: 1023px)" srcset="@/static/svg/download.svg" type="image/svg" />
                                    <source media="(max-width: 599px)" srcset="@/static/svg/download.svg" type="image/svg" />
                                    <img class="pV pX pW" :alt="vo.goods && vo.goods.name" :src="vo.goods && vo.goods.img" fetchpriority="auto" width="240" height="240"
                                  /></picture>
                                </div>
                              </div>
                              <div class="bg_0 bg_1">
                                <div class="bg_2">
                                  <span class="bg_3 bg_5">
                                    <div style="vertical-align: inherit">
                                      <div style="vertical-align: inherit">$ {{ vo.goods && vo.goods.price }}</div>
                                    </div>
                                  </span>
                                  <!-- <span class="bg_4">
                                      <div style="vertical-align: inherit">
                                        <div style="vertical-align: inherit">$ {{vo.goods && vo.goods.cost_price}}</div>
                                      </div>
                                    </span> -->
                                </div>
                                <a href="javascript:" class="Iq">
                                  <span class="Ip2">
                                    <span style="vertical-align: inherit">
                                      <span style="vertical-align: inherit">{{ vo.goods && vo.goods.name }}</span>
                                    </span>
                                  </span>
                                </a>
                                <div class="bhc">
                                  <span class="bhf"> </span>
                                </div>
                                <div class="bhc">
                                  <span class="bhf">
                                    <span style="vertical-align: inherit">
                                      <span style="vertical-align: inherit">{{ vo.orders }} orders</span> </span
                                    >&nbsp;
                                  </span>
                                </div>
                              </div>
                            </section>
                          </swiper-slide>
                        </swiper>
                        <!-- 左侧按钮 -->
                        <button type="button" role="button" class="fS Je gg ga f_1 gn gi gu gt sl21-prev">
                          <div class="fU">
                            <div class="fV">
                              <i class="eO eS" aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                  <use xlink:href="#arrow_short_left"></use>
                                </svg>
                              </i>
                            </div>
                          </div>
                        </button>
                        <!-- 右侧按钮 -->
                        <button type="button" role="button" class="fS Jf gg ga f_1 gn gi gu gt sl21-next">
                          <div class="fU">
                            <div class="fV">
                              <i class="eO eS" aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                  <use xlink:href="#arrow_short_right"></use>
                                </svg>
                              </i>
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </section>
                  <!-- 广告图 -->
                  <!-- <div class="s_7">
                    <ul class="s_8">
                      <li class="s_9">
                        <a class="tb" href="https://sp.detmir.ru/tinkoff_platinum?erid=Kra23W1hG" target="_blank"><img src="https://gallery.retailrocket.net/52458e7c0d422d4d54590318/photo_2023-07-31%2011.53.14.jpeg " class="tc" alt="" /></a>
                      </li>
                    </ul>
                  </div> -->
                </div>
              </div>

              <aside class="fl category-list">
                <div class="fH">
                  <!-- 分类列表 -->
                  <div class="fI">
                    <div class="Ru">
                      <!-- 加载中 -->
                      <Loading v-if="cateLoad"></Loading>
                      <!-- 分类 -->
                      <ul class="Rv" style="width: 100%">
                        <li data-testid="categoryItem1">
                          <a href="javascript:" class="bqI bqK Rw US" :title="categoryList.name">
                            <span style="vertical-align: inherit">
                              <span style="vertical-align: inherit">{{ categoryList.name }}</span>
                            </span>
                          </a>
                        </li>
                        <ul class="Rv" v-for="(vo, i) in categoryList.category" :key="i">
                          <li data-testid="categoryItem4">
                            <a href="javascript:" class="bqI bqL Rw US" :title="vo.name">
                              <span style="vertical-align: inherit">
                                <span style="vertical-align: inherit">{{ vo.name }}</span>
                              </span>
                            </a>
                          </li>
                          <ul class="Rv" v-for="(vvo, ii) in vo.category" :key="ii">
                            <li data-testid="categoryItem44719">
                              <a href="javascript:" :class="['bqI', 'bqM', currentCate.id == vvo.id ? 'brw' : '']" :title="vvo.name" @click="onSelect(vvo.id, i, ii)">
                                <span style="vertical-align: inherit">
                                  <span style="vertical-align: inherit">{{ vvo.name }}</span>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </ul>
                      </ul>
                    </div>
                    <!--/$-->
                  </div>

                  <!-- 其他筛选 -->
                  <div class="fI" hidden>
                    <div class="vy" test-id="fasetsV2-wrapper">
                      <!-- 价格筛选 -->
                      <div class="Qc">
                        <div class="Qe">
                          <div class="Qh">
                            <span style="vertical-align: inherit">
                              <span style="vertical-align: inherit">价格，₽</span>
                            </span>
                          </div>
                          <i class="eO eR Qi" aria-hidden="true"
                            ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                              <use xlink:href="#arrow_short_top"></use>
                            </svg>
                          </i>
                        </div>
                        <div data-testid="fasetsV2_range_price">
                          <div class="bqy">
                            <div class="n_7 oB oK oz n_8 ox oj bqz ow">
                              <label class="oe"
                                ><input class="ob" placeholder="88" id="min" name="min" inputmode="numeric" maxlength="254" spellcheck="true" data-testid="fasetsV2_priceinput_min" value="" />
                                <div class="og"></div>
                              </label>
                            </div>
                            <div class="n_7 oB oK oz n_8 ox oj bqz ow">
                              <label class="oe"
                                ><input class="ob" placeholder="23938" id="max" name="max" inputmode="numeric" maxlength="254" spellcheck="true" data-testid="fasetsV2_priceinput_max" value="" />
                                <div class="og"></div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- 折扣筛选 -->
                      <div class="Qc">
                        <div class="Qe">
                          <div class="Qh">
                            <span style="vertical-align: inherit">
                              <span style="vertical-align: inherit">折扣</span>
                            </span>
                          </div>
                          <i class="eO eR Qi" aria-hidden="true"
                            ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                              <use xlink:href="#arrow_short_top"></use>
                            </svg>
                          </i>
                        </div>
                        <div class="bql bqm" data-testid="fasetsV2_single_mindiscounts">
                          <ul>
                            <li data-testid="fasetsV2_item_10">
                              <label class="Pg bqs"
                                ><input type="radio" id="mindiscounts10" name="mindiscounts10" class="Ph" value="" />
                                <div class="Pi">
                                  <div class="Pj"></div>
                                  <div class="Pm">
                                    <span class="bqt">
                                      <span style="vertical-align: inherit">
                                        <span style="vertical-align: inherit">10%及以上</span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </li>
                            <li data-testid="fasetsV2_item_30">
                              <label class="Pg bqs"
                                ><input type="radio" id="mindiscounts30" name="mindiscounts30" class="Ph" value="" />
                                <div class="Pi">
                                  <div class="Pj"></div>
                                  <div class="Pm">
                                    <span class="bqt">
                                      <span style="vertical-align: inherit">
                                        <span style="vertical-align: inherit">30%及以上</span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </li>
                            <li data-testid="fasetsV2_item_50">
                              <label class="Pg bqs"
                                ><input type="radio" id="mindiscounts50" name="mindiscounts50" class="Ph" value="" />
                                <div class="Pi">
                                  <div class="Pj"></div>
                                  <div class="Pm">
                                    <span class="bqt">
                                      <span style="vertical-align: inherit">
                                        <span style="vertical-align: inherit">50%及以上</span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </li>
                            <li data-testid="fasetsV2_item_70">
                              <label class="Pg bqs"
                                ><input type="radio" id="mindiscounts70" name="mindiscounts70" class="Ph" value="" />
                                <div class="Pi">
                                  <div class="Pj"></div>
                                  <div class="Pm">
                                    <span class="bqt">
                                      <span style="vertical-align: inherit">
                                        <span style="vertical-align: inherit">70%及以上</span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="vB"></div>
                    </div>
                    <!--/$-->
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Navigation, Pagination, Autoplay } from 'swiper'
import Loading from '@/components/base/Loading.vue'
export default {
  name: 'categoryVue',
  components: {
    Loading
  },
  data() {
    return {
      //当前ID
      currentId: 0,

      //分类信息
      currentCate: '',
      categoryList: [],
      cateLoad: false,

      //加载更多
      scrollDisabled: false,
      page: 1,
      goodsData: [],
      loadmore: false,

      //单次最大页数
      maxpage: 3,

      //轮播参数
      modules: [Navigation, Pagination, Autoplay],
      //
      recommend: []
    }
  },

  watch: {
    $route(_new) {
      //location.reload()
      // console.log('路由切换了', _new, _old)
      if (_new.name == 'category') {
        this.getTopGoods()
        this.currentId = this.$route.query.id
        this.getCategory(this.$route.query.id, this.$route.query.level)
        this.getGoodsList(1)
      }
    }
  },
  created() {
    console.log(this.$route.query, 'query')
    this.currentId = this.$route.query.id
    this.getTopGoods()
    this.getCategory(this.$route.query.id, this.$route.query.level)
  },
  methods: {
    getTopGoods() {
      //获取推荐
      this.$api.home.getTopGoods({ type: 1 }).then((res) => {
        this.recommend = res.data
      })
    },
    /**
     * 获取当前分类
     */
    getCategory(id, level) {
      this.cateLoad = true
      let that = this
      that.currentCate = {}
      that.categoryList = []
      this.$api.category.getCategory({ id: id, level: level }).then((res) => {
        this.cateLoad = false
        that.currentCate = res.data.current
        that.categoryList = res.data.list
        //初始化
        //that.setCurrentCate()
      })
    },
    /**
     * 子集分类
     * @param {*} id
     */
    onSelect(id, i, k) {
      this.currentId = id
      //获取
      this.getGoodsList(1)
      //当前分类
      this.setCurrent(i, k)
    },
    /**
     * 设置当前选中
     * @param {*} sort
     */
    setCurrent(i, k) {
      this.currentCate = this.categoryList.category[i].category[k]
    },

    // 触底加载
    loadMore() {
      if (this.loadmore) {
        return
      }

      if (this.maxpage > 0 && this.page > this.maxpage) {
        this.scrollDisabled = true
        return
      }

      this.getGoodsList()
    },
    getGoodsList(page = 0, keyword = '') {
      let that = this

      if (page > 0) {
        this.page = page
        this.goodsData = []
      }

      let hide = this.$message.loading({ content: 'loading...', duration: 0, key: 'msg' })
      that.loadmore = true

      this.$api.goods
        .getGoodsList({
          id: this.currentId,
          page: this.page,
          size: 12,
          keyword: keyword
        })
        .then((res) => {
          hide()
          that.page++
          res.data.data.forEach(function (item) {
            that.goodsData.push(item)
          })
          that.loadmore = false
        })
    },
    //手动再次获得4页
    onLoadMore() {
      this.scrollDisabled = false
      this.maxpage += 3
    }
  }
}
</script>

<style scoped>
@import url('@/static/css2/253.da1b60c94461170d415ff00edbfdd4.css');
/* @import url('@/static/css2/253.66d2f60a7ae372dac098802d6e46c6.css'); */
.theme-dm .brw {
  color: #fff;
  font-weight: 700;
}
.theme-dm .brw,
.theme-dm .brw:hover {
  background: #000;
}
.theme-zoo .brw {
  color: #fff;
  font-weight: 700;
}
.theme-zoo .brw,
.theme-zoo .brw:hover {
  background: #764aef;
}
.category-list a:hover {
  color: #fff;
}
</style>
