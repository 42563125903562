<template>
  <section class="K_3">
    <div class="K_4"></div>
    <!-- <div class="k1_5">
        {{ $t('shopping.back.buy') }}
    </div> -->
    <router-link :to="{ name: 'index' }">
      <div class="pt1">{{ $t('shopping.start.shopping') }}</div>
    </router-link>
  </section>
</template>

<script>
export default {
  name: 'EmptyVue'
}
</script>

<style>
@media only screen and (min-width: 600px) {
  .K_3 {
    width: 276px;
    padding-top: 68px;
  }
}
.K_3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  padding: 55px 0 16px;
  margin: 0 auto;
}

.theme-dm .K_4 {
  background-image: url('@/static/svg/8a6e0fd61e40a816eb5851ddecf9125a.svg');
}

@media only screen and (min-width: 600px) {
  .K_4 {
    margin-top: 2px;
    width: 180px;
    height: 124px;
    margin-bottom: 20px;
  }
}
.K_4 {
  width: 154px;
  height: 106px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-size: contain;
}

.pt1 {
  padding: 10px 35px;
  background-color: #0073e6;
  color: #fff;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
}

.pt1:hover {
  background-color: #0a66c3;
}
</style>
