import request from "@/utils/request";

const api = {
  getDocs: "docs/getDocs",
  getSetting: "docs/getSetting",
};

export default {
  getDocs(parameter) {
    return request({
      url: api.getDocs,
      method: "post",
      data: parameter,
    });
  },
  getSetting(parameter) {
    return request({
      url: api.getSetting,
      method: "post",
      data: parameter,
    });
  },
};
