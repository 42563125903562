import { createStore } from 'vuex'
import api from '../api'
import common from './common'
import socket from './socket'

const _base = {
  namespaced: true,
  state() {
    return {
      msgCenter: {
        msg: 0,
        order: 0,
        comment:0,
        notice:0
      }
    }
  },
  mutations: {
    setMsg(state, msg) {
      state.msgCenter = msg
    }
  },
  actions: {
    getBase(context) {
      //判断是否登录
      if (!common.isLogin()) {
        return false
      }
      api.home.getBase().then((dat) => {
        context.commit('setMsg', dat.data)
      })

      console.log(socket.getReadyState(), '连接状态')
      //判断连接
      socket.on('msg', function (data) {
        console.log('回调', data)
        context.commit('setMsg', data)
      })
    }
  }
}

const _store = {
  namespaced: true,
  state() {
    return {
      id: 0,
      info: {}
    }
  },
  mutations: {
    setStore(state, store) {
      state.info = store
    },
    setId(state, data) {
      state.id = data.id
    }
  },
  actions: {
    getStore(context, data) {
      api.storeInfo.getStoreInfo({ id: data.id }).then((dat) => {
        console.log(data, dat.data.store, 'api.getStoreInfo')
        context.commit('setStore', dat.data.store)
      })
    }
  }
}

const _goods = {
  namespaced: true,
  state() {
    return {
      id: 0,
      list3: {},
      list6: {},
      list15: {},
      comment: {}
    }
  },
  mutations: {
    setGoods(state, data) {
      state.list3 = data.list3
      state.list6 = data.list6
      state.list15 = data.list15
    },
    setCommentInfo(state, data) {
      state.comment = data
    }
  },
  actions: {
    getRecommendedList(context, data) {
      api.goods.getRecommendedList({ store_id: data.id }).then((dat) => {
        context.commit('setGoods', dat.data)
      })
    },
    getCommentInfo(context, data) {
      api.goods.getCommentInfo({ id: data.id }).then((dat) => {
        context.commit('setCommentInfo', dat.data)
      })
    }
  }
}

const _order = {
  namespaced: true,
  state() {
    return {
      buy: {}
    }
  },
  mutations: {
    setBuy(state, data) {
      state.buy = data
    },
    setCart(state, data) {
      state.comment = data
    }
  },
  actions: {}
}

// 创建一个新的 store 实例
const store = createStore({
  modules: {
    base: _base,
    store: _store,
    goods: _goods,
    order: _order
  }
})

export default store
