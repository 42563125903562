import request from '@/utils/request'

const api = {
  getNoticeList: 'notice/getNoticeList',
  getNoticeInfo: 'notice/getNoticeInfo'
}
export default {
  getNoticeList(parameter) {
    return request({
      url: api.getNoticeList,
      method: 'post',
      data: parameter
    })
  },
  getNoticeInfo(parameter) {
    return request({
      url: api.getNoticeInfo,
      method: 'post',
      data: parameter
    })
  }
}
