<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('store.setting') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <!-- 3 -->
  <a-card>
    <div>{{ $t('setting.sjdjqy') }}</div>
    <a-form name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 24 }">
      <div class="b-content" style="display: flex; align-items: center; justify-content: center" v-html="docs.content"></div>
    </a-form>
  </a-card>
</template>

<script>
export default {
  name: 'settingVue',
  components: {},
  data() {
    return {
      docs: {}
    }
  },
  created() {
    this.$api.docs.getDocs({ key: 'benefit' }).then((res) => {
      this.docs = res.data
    })
  },
  methods: {}
}
</script>

<style scoped>
.b-content >>> td {
  border: 1px solid #dfdfdf !important;
}
</style>
