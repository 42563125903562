import request from '@/utils/request'

const api = {
  getStoreInfo: 'storeInfo/getStoreInfo',
  getStoreContent: 'storeInfo/getStoreContent',
  getStoreGoodsList: 'storeInfo/getStoreGoodsList',
  getStoreGoodsRecommendList: 'storeInfo/getStoreGoodsRecommendList',
  getStoreTemplate: 'storeInfo/getStoreTemplate',
  getStoreRecommend: 'storeInfo/getStoreRecommend'
}
export default {
  getStoreInfo(parameter) {
    return request({
      url: api.getStoreInfo,
      method: 'post',
      data: parameter
    })
  },
  getStoreRecommend(parameter) {
    return request({
      url: api.getStoreRecommend,
      method: 'post',
      data: parameter
    })
  },
  getStoreTemplate(parameter) {
    return request({
      url: api.getStoreTemplate,
      method: 'post',
      data: parameter
    })
  },
  getStoreContent(parameter) {
    return request({
      url: api.getStoreContent,
      method: 'post',
      data: parameter
    })
  },
  getStoreGoodsList(parameter) {
    return request({
      url: api.getStoreGoodsList,
      method: 'post',
      data: parameter
    })
  },
  getStoreGoodsRecommendList(parameter) {
    return request({
      url: api.getStoreGoodsRecommendList,
      method: 'post',
      data: parameter
    })
  }
}
