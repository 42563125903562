export default {
  KEY_TOKEN: 'sp_token',
  KEY_INFO: 'sp_info',
  KEY_ORDER: 'order_goods',
  KEY_CART: 'cart_goods',
  KEY_LANG: 'sp_lang',

  LANGUAGE: [
    {
      text: 'English',
      lang: 'en-us'
    },
    {
      text: '한국어',
      lang: 'ko-kr'
    },
    {
      text: '日本語',
      lang: 'ja-jp'
    },

    {
      text: 'Deutsch',
      lang: 'de-de'
    },
    {
      text: 'عربي',
      lang: 'ar-sa'
    },
    {
      text: 'Русский',
      lang: 'be-by'
    },
    {
      text: 'Română',
      lang: 'ro-ro'
    },
    {
      text: 'Français',
      lang: 'fr-fr'
    },
    {
      text: 'Türkçe',
      lang: 'tr-tr'
    },
    {
      text: 'Italiano',
      lang: 'it-it'
    },
    {
      text: 'Suomalainen',
      lang: 'fi-fi'
    },
    {
      text: 'España',
      lang: 'es-es'
    },
    {
      text: 'Danmark',
      lang: 'dk-da'
    },
    {
      text: 'Nederland',
      lang: 'nl-nl'
    },
    {
      text: 'Tiếng Việt',
      lang: 'vi-vn'
    },
    {
      text: 'Português',
      lang: 'pt-pt'
    },
    {
      text: '繁體中文',
      lang: 'zh-hant'
    }
  ]
}
