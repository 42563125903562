import request from '@/utils/request'

const api = {
  getMyStoreFlow: 'flow/getMyStoreFlow',
  getConfig: 'flow/getConfig',
  payFlow: 'flow/payFlow'
}

export default {
  getMyStoreFlow(parameter) {
    return request({
      url: api.getMyStoreFlow,
      method: 'post',
      data: parameter
    })
  },
  getConfig(parameter) {
    return request({
      url: api.getConfig,
      method: 'post',
      data: parameter
    })
  },
  payFlow(parameter) {
    return request({
      url: api.payFlow,
      method: 'post',
      data: parameter
    })
  }
}
