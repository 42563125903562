<template>
  <div>
    <div class="rw" :style="{ padding: padding + 'px 0px' }">
      <div aria-live="polite" class="DA DG">
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="DC"><circle cx="50%" cy="50%" r="50%" class="DD DN"></circle></svg>
      </div>
    </div>
  </div>
</template>
<script setup>
// import { ref } from 'vue'
// let number = ref(100)
</script>
<script>
export default {
  name: 'LoadingVue',
  props: {
    padding: {
      type: Number,
      default: 100
    }
  }
}
</script>

<style scoped>
.rw {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  /* padding: 100px 0; */
}

.DA {
  display: flex;
  align-items: center;
}

.DA.DG .DC {
  height: 40px;
  width: 40px;
}

.DA.DG .DD {
  transform: rotate(-90deg) scale(0.91);
}

.theme-dm .DN {
  stroke: #000;
}

@keyframes DQ {
  0% {
    stroke-dashoffset: 314%;
  }

  50% {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -314%;
  }
}

.DD {
  position: relative;
  fill: none;
  stroke-width: 4px;
  stroke-dashoffset: -314%;
  stroke-dasharray: 314%;
  animation: DQ 1.4s cubic-bezier(0.42, 0, 0.29, 1) infinite;
  transform-origin: 50%;
}
</style>
