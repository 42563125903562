import Const from './const'
import i18n from './vue-i18n'
import { message } from 'ant-design-vue'

function checkEmail(email) {
  const data = /^([a-zA-Z0-9]+[-_\\.]?)+@([a-zA-Z0-9]+[-_\\.]?)+[a-z]+$/
  email = email.replace(/\s/g, '')
  if (email && !data.test(email)) {
    return false
  }
  return true
}
function rmSpaces(str) {
  return str.replace(/\s/g, '')
}
function isLogin() {
  let token = localStorage.getItem(Const.KEY_TOKEN)
  if (!token) {
    return false
  }
  return true
}

function getInfo() {
  let sp_info = localStorage.getItem(Const.KEY_INFO)
  if (!sp_info) {
    return {
      nickname: '',
      type: 0
    }
  }
  return JSON.parse(sp_info)
}

function setInfo(nickname = '', avatar = '') {
  let sp_info = getInfo()

  if (nickname) {
    sp_info.nickname = nickname
  }
  if (avatar) {
    sp_info.avatar = avatar
  }
  localStorage.setItem(Const.KEY_INFO, JSON.stringify(sp_info))
}

function setService(res) {
  let sp_info = getInfo()

  sp_info.whatsapp = res.whatsapp
  sp_info.telegram = res.telegram
  sp_info.line = res.line
  sp_info.line_qrcode = res.line_qrcode
  localStorage.setItem(Const.KEY_INFO, JSON.stringify(sp_info))
}

function getLocal(key) {
  let val = localStorage.getItem(key)
  return val
}

function cutPrice(price) {
  return price.substring(price.indexOf('.') + 1, price.length)
}

function isStore() {
  //是否登录
  if (!isLogin()) {
    message.warning({
      content: i18n.t('_n2._qxdl')
    })
    return false
  }

  let user = getInfo()
  if (user.type == 2) {
    message.warning({
      content: i18n.t('_n3._sjwfgm')
    })
    return false
  }

  return true
}

function zkPrice(price, cost_price) {
  return parseInt((parseFloat(cost_price) - parseFloat(price)) / parseFloat(cost_price) * 100)
}

export default {
  checkEmail,
  rmSpaces,
  isLogin,
  getLocal,
  cutPrice,
  getInfo,
  setInfo,
  setService,
  isStore,
  zkPrice
}
