<template>
  <main class="h_9 rf bdkg">
    <div class="h_3 h_7">
      <header class="Em h_8">
        <nav class="En" aria-label="breadcrumb">
          <ul class="Rx RG">
            <li class="Rz">
              <i class="eO eR RD" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                  <use xlink:href="#arrow_short_left"></use>
                </svg>
              </i>
              <!-- <a href="/" class="RE">лавная</a> -->
              <i class="eO eQ RC" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                  <use xlink:href="#arrow_short_right"></use>
                </svg>
              </i>
            </li>
          </ul>
        </nav>
        <h1 class="Eo" data-testid="page-title">{{ $t('_n3._ddzf') }}</h1>
      </header>
      <div class="rc">
        <div class="ri">
          <!-- 选择收货地址 -->
          <section class="bwtP bwyP" v-if="defaultAddress">
            <header class="bwR">
              <h3 class="bwS">
                <div style="vertical-align: inherit">
                  <div style="vertical-align: inherit">{{ $t('member.address') }}</div>
                </div>
              </h3>
            </header>
            <div class="paddr">
              <div class="addr">
                <div>{{ defaultAddress.contact }}</div>
                <div>{{ defaultAddress.phone_country }} {{ defaultAddress.phone }}</div>
                <div>{{ defaultAddress.address }}</div>
              </div>
              <div class="oper" @click="onChangeAddress()">
                <a>{{ $t('confirm.change') }}</a>
              </div>
            </div>
          </section>
          <!-- 支付方式 -->
          <section class="bwtP bwyP" v-if="currentWallet">
            <header class="bwR">
              <h3 class="bwS">
                <div style="vertical-align: inherit">
                  <div style="vertical-align: inherit">{{ $t('member.payment') }}</div>
                </div>
              </h3>
            </header>
            <div class="pusd">
              <div class="tusd">
                <div>USD</div>
                <div class="u">$ {{ currentWallet.balance }}</div>
              </div>
              <!-- <div class="oper" @click="onChangePayment()">
                <a>{{ $t('confirm.change') }}</a>
              </div> -->
            </div>
          </section>
          <!-- 订单清单 -->
          <section class="bwtP bwyP theme-dm1" hidden>
            <header class="bwR">
              <h3 class="bwS">
                <div style="vertical-align: inherit"><div style="vertical-align: inherit">收件人</div></div>
              </h3>
            </header>
            <div class="bjM">
              <!-- 收件人 -->
              <div class="bjJ1">
                <div class="bIe1 bIf">
                  <div class="pN qg qp qe pS qc p_ _qd">
                    <div class="pP">
                      <div class="bwN">
                        <i class="gm gq bwO" aria-hidden="true">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="gn">
                            <use xlink:href="#profile"></use>
                          </svg>
                        </i>
                      </div>
                    </div>
                    <label class="pU11"
                      ><input class="pR" placeholder="Имя и фамилия получателя" id="name" name="name" maxlength="254" spellcheck="false" data-testid="cartContactsName" value="" />
                      <div class="pW"></div
                    ></label>
                  </div>
                  <div class="bIg _bIf">Укажите имя и фамилию получателя</div>
                </div>
              </div>
              <div class="bjJ1">
                <div class="bIe1 bIf">
                  <div class="pN qg qp qe pS qc p_ _qd">
                    <div class="pP">
                      <div class="bwN">
                        <i class="gm gq bwO" aria-hidden="true">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="gn">
                            <use xlink:href="#phone"></use>
                          </svg>
                        </i>
                      </div>
                    </div>
                    <label class="pU11"
                      ><input class="pR" type="tel" placeholder="Телефон" id="phone" name="phone" spellcheck="false" data-testid="cartContactsPhone" value="" />
                      <div class="pW"></div
                    ></label>
                  </div>
                  <div class="bIg _bIf">Укажите телефон</div>
                </div>
              </div>
              <div class="bjJ1">
                <div class="bIe1 bIf">
                  <div class="pN qg qp qe pS qc p_ _qd">
                    <div class="pP">
                      <div class="bwN">
                        <i class="gm gq bwO" aria-hidden="true"
                          ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="gn"><use xlink:href="#email"></use></svg>
                        </i>
                      </div>
                    </div>
                    <label class="pU11"
                      ><input class="pR" type="email" placeholder="Электронная почта" id="email" name="email" maxlength="254" spellcheck="false" data-testid="cartContactsEmail" value="" />
                      <div class="pW"></div
                    ></label>
                  </div>
                  <div class="bIg _bIf">Укажите электронную почту</div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <aside class="rk">
          <div class="Mp">
            <!-- 商品合计 -->
            <div class="Mv">
              <div class="_5B">
                <div class="bmY">
                  <div class="bmZ">
                    <span class="bix biR biy" data-testid="typography"> {{ $t('_n3._njsp', { num: sumNum }) }}</span>
                    <!-- <span class="bm_">Вес заказа: 36,2&nbsp;к</span> -->
                  </div>
                  <span class="bix biR biz" data-testid="typography">$ {{ subTotal }}</span>
                </div>
                <div class="bmY">
                  <span class="bix biR biy" data-testid="typography">{{ $t('detail.delivery') }}</span>
                  <span class="biw biR biz" data-testid="typography">{{ $t('detail.free.shipping') }}</span>
                </div>
                <div class="_5C">
                  <div class="bmM">
                    <div class="bmN">
                      <span>{{ $t('confirm.total') }}</span>
                    </div>
                    <div class="bmO">
                      <div class="bmP">$ {{ sumTotal }}</div>
                    </div>
                  </div>
                  <!-- <div class="bmQ">&nbsp;+604,18 бонуса</div> -->
                </div>
              </div>
            </div>
            <div class="Mz MA">
              <button @click="onSubmit()" type="button" class="fS fW ga f_1 gh gi f_4">
                <div class="fU">
                  <div class="fV">{{ $t('confirm.pay.now') }}</div>
                </div>
              </button>
            </div>
          </div>
        </aside>
      </div>
    </div>
  </main>
  <!-- 选择地址 -->
  <create-address ref="address" @setAddress="setAddress"></create-address>

  <!-- 支付方式 -->
  <payment ref="payment" :isInit="true" @setWallet="setWallet"></payment>
</template>

<script>
import CreateAddress from '@/components/member/CreateAddress.vue'
import Payment from '@/components/member/Payment.vue'
export default {
  name: 'cartVue',
  components: {
    CreateAddress,
    Payment
  },
  data() {
    return {
      //订单号
      order_sn: [],

      //地址列表
      defaultAddress: {},

      //默认钱包
      currentWallet: {},

      //总计金额
      subTotal: '0.00',
      sumTotal: '0.00',
      sumNum: 0,

      //是否支付
      isPay: false
    }
  },
  watch: {
    // $route(){
    //   location.reload()
    // }
  },
  created() {
    let query = this.$route.query
    console.log(query, 'query')

    this.order_sn = query.order_sn
    this.init()
  },
  methods: {
    init() {
      this.getOrderInfo()
    },
    getOrderInfo() {
      let that = this
      this.$api.order.getOrderInfo({ order_sn: this.order_sn }).then((res) => {
        that.subTotal = res.data.sub_total
        that.sumTotal = res.data.total
        that.sumNum = res.data.num
      })
    },
    onChangeAddress() {
      this.$refs.address.open(1)
    },
    /**
     * 地址控件
     * @param {*} address
     */
    setAddress(address) {
      // console.log(address, '默认地址')
      this.defaultAddress = address
    },
    onChangePayment() {
      this.$refs.payment.open()
    },
    setWallet(wallet) {
      // console.log(wallet, '默认钱包')
      this.currentWallet = wallet
    },
    /**
     * 立即下单支付
     */
    onSubmit() {
      console.log(this.defaultAddress, 'this.defaultAddress')
      if (Reflect.ownKeys(this.defaultAddress).length === 0) {
        this.$message.warning(this.$t('_dd._xzdz'))
        return
      }

      if (this.isPay) {
        return
      }
      this.isPay = true

      let that = this
      this.$message.loading({ content: this.$t('_dd._zfz') + '...', key: 'msg' })
      that.$api.order
        .payOrder({
          address_id: that.defaultAddress.id,
          wallet_id: that.currentWallet.id,
          order_sn: this.order_sn
        })
        .then((res) => {
          that.isPay = false
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })

          that.$router.push({ name: 'orderAll' })
        })
        .catch(() => {
          that.isPay = false
        })
    }
  }
}
</script>

<style scoped>
@import url('@/static/css2/716.fab477407da70d6e4f6f8a00ea882b.css');
@import url('@/static/css2/581.bfe04c9564d5a12e7fc76211e0b6f2.css');
@import url('@/static/css2/pay.css');
</style>
<style scoped>
.paddr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.paddr .addr {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  gap: 15px;
  color: #1890ff;
}

.pusd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.tusd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  gap: 15px;
}

.tusd .u {
  color: var(--toastify-icon-color-success);
}

.oper a {
  font-size: 15px;
  color: #1890ff;
}

.oper a:hover {
  color: #9dccf7;
}
</style>
