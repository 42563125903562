<template>
  <section class="WC BD">
    <h4 class="WE">
      <span style="vertical-align: inherit">
        <span style="vertical-align: inherit">{{ $t('_n2._rhsh') }}</span>
      </span>
    </h4>
    <ul>
      <li class="baq">
        <i class="eO eV" aria-hidden="true"
          ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP" style="fill:#000;">
            <use xlink:href="#dm_ship_instore" ></use>
          </svg>
        </i>
        <div class="bar">
          <button type="button" class="bas">
            <p class="bat bav">
              <span style="vertical-align: inherit">
                <span style="vertical-align: inherit">{{$t('_n2._xsxd')}}</span>
              </span>
            </p>
            <p class="bau">
              <span style="vertical-align: inherit">
                <span style="vertical-align: inherit">{{$t('_n2._jscldd')}}</span>
              </span>
            </p>
          </button>
        </div>
      </li>
      <!-- <li class="baq baw">
        <i class="eO eV" aria-hidden="true"
          ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
            <use xlink:href="#dm_ship_store_pickup"></use>
          </svg>
        </i>
        <div class="bar">
          <p class="bat">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">带到商店</span>
            </span>
          </p>
          <p class="bau">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">无法使用</span>
            </span>
          </p>
        </div>
      </li> -->
      <li class="baq">
        <i class="eO eV" aria-hidden="true"
          ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
            <use xlink:href="#colored_super_express"></use>
          </svg>
        </i>
        <div class="bar">
          <p class="bat">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">{{$t('_n2._2xsfh')}}</span>
            </span>
          </p>
          <p class="bau">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">{{$t('_n2._1d5tdd')}}</span>
            </span>
          </p>
        </div>
      </li>
      <li class="baq">
        <i class="eO eV" aria-hidden="true"
          ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
            <use xlink:href="#colored_express"></use>
          </svg>
        </i>
        <div class="bar">
          <p class="bat">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">{{$t('_n2._tkzd')}}</span>
            </span>
          </p>
          <p class="bau">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">{{$t('_n2._mfps')}}</span>
            </span>
          </p>
        </div>
      </li>
      <li class="baq baw">
        <i class="eO eV" aria-hidden="true"
          ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
            <use xlink:href="#dm_ship_delivery"></use>
          </svg>
        </i>
        <div class="bar">
          <p class="bat">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">{{$t('_n2._ckfh')}}</span>
            </span>
          </p>
          <p class="bau">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">{{$t('_n2._zcps')}}</span>
            </span>
          </p>
        </div>
      </li>
      <li class="baq baw">
        <i class="eO eV" aria-hidden="true"
          ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
            <use xlink:href="#dm_ship_pickup"></use>
          </svg>
        </i>
        <div class="bar">
          <p class="bat">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">{{ $t('_n2._jsd') }}</span>
            </span>
          </p>
          <p class="bau">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">{{$t('_n2._qsjps')}}</span>
            </span>
          </p>
        </div>
      </li>
    </ul>
  </section>

  <section class="BD">
    <h4 class="U_2">
      <i class="eO eS U_4" aria-hidden="true"
        ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
          <use xlink:href="#undo"></use>
        </svg>
      </i>
      <span style="vertical-align: inherit">
        <span style="vertical-align: inherit">{{$t('_n2._thtj')}}</span>
      </span>
    </h4>
    <p class="U_3">
      <span style="vertical-align: inherit">
        <span style="vertical-align: inherit">{{ $t('_n2._thtjsm') }}</span>
      </span>
    </p>
    <!-- <a class="U_5" href="javascript:" target="_blank">
      <span style="vertical-align: inherit">
        <span style="vertical-align: inherit">更多的</span>
      </span>
    </a> -->
  </section>
</template>

<script>
export default {}
</script>

<style></style>
