<template>
  <div class="_7_0" v-if="list15.length > 0">
    <h2 class="_7_1">
      <span style="vertical-align: inherit">
        <span style="vertical-align: inherit">{{ $t('_n2._rnsj') }}</span>
      </span>
    </h2>
    <div class="Jd" style="padding: 0 15px">
      <swiper
        :modules="modules"
        :slidesPerView="5"
        :slidesPerGroup="1"
        :spaceBetween="16"
        :navigation="{
          nextEl: '.sl4-next',
          prevEl: '.sl4-prev'
        }"
        :autoplay="{
          delay: 3000,
          disableOnInteraction: false
        }"
        class="Jl"
      >
        <swiper-slide class="_7_2" v-for="(vo, i) in list15" :key="i">
          <section id="3457937" class="Ig Ij Im _7_3">
            <div class="_7_6 _1T">
              <div class="Rd Rg">
                <div class="Re">
                  <button type="button" role="button" class="fS fW ga f_ gp gm">
                    <div class="fU">
                      <div class="fV">
                        <span class="_8a">
                          <i class="eO eS _7_8 _7_9 _8d" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                              <use xlink:href="#heartHollowBg"></use>
                            </svg>
                          </i>
                        </span>
                      </div>
                      <div class="fV">
                        <span class="_8f">
                          <span style="vertical-align: inherit">
                            <span style="vertical-align: inherit">wish</span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div class="bgu bgx bgy" aria-hidden="true">
              <div class="pU p_0 bgw">
                <com-picture :src="vo.img"></com-picture>
              </div>
            </div>
            <div class="bg_0 bg_1">
              <span class="bg_8">
                <span style="vertical-align: inherit"> <span style="vertical-align: inherit">-</span> </span
                ><span class="bha">
                  <span style="vertical-align: inherit">
                    <span style="vertical-align: inherit">{{ $common.zkPrice(vo.price, vo.cost_price) }}</span>
                  </span>
                </span>
                <span style="vertical-align: inherit">
                  <span style="vertical-align: inherit">%</span>
                </span>
              </span>
              <div class="bg_2">
                <span class="bg_3 bg_5">
                  <span style="vertical-align: inherit">
                    <span style="vertical-align: inherit">$ {{ vo.price }}</span>
                  </span> </span
                ><span class="bg_4">
                  <span style="vertical-align: inherit">
                    <span style="vertical-align: inherit">$ {{ vo.cost_price }}</span>
                  </span>
                </span>
              </div>
            </div>
            <router-link :to="{ name: 'detail', query: { id: vo.id } }" class="Iq">
              <span class="Ip2">
                <span style="vertical-align: inherit">
                  <span style="vertical-align: inherit">{{ vo.name }}</span>
                </span>
              </span>
            </router-link>
            <div class="bhc">
              <span class="WVbEJ tKey9">
                <span role="img" class="T2Mzf" aria-label="4 out of 5 stars">
                  <span class="GocDN dUBUg"></span>
                  <span class="GocDN bFCnj" :style="'width: ' + 20 * parseInt(vo.star) + '%'"></span>
                </span>
                <!-- <span class="HZv8u">(219)</span> -->
              </span>
              <span class="bhf">
                <span style="vertical-align: inherit">
                  <span style="vertical-align: inherit">{{ vo.orders }} {{ $t('detail.orders') }}</span> </span
                >&nbsp;
              </span>
            </div>
            <ul class="bhg">
              <li class="bhh">
                <div class="R_5 Sc R_8 Se Sg" style="background: #fff; border: 1px solid #000; color: #000">
                  <div class="Sd">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">{{ $t('_n2._rx') }}</span>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <!-- <span class="bg_8_1 bg_8_btn">
              <span class="bha_1">
                <div style="vertical-align: inherit">
                  <div style="vertical-align: inherit">立即购买</div>
                </div>
              </span>
            </span> -->
          </section>
        </swiper-slide>
      </swiper>
      <button type="button" role="button" class="fS Je _7_5 gg ga f_1 gn gi gu gt sl4-prev">
        <div class="fU">
          <div class="fV">
            <i class="eO eS" aria-hidden="true"
              ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP"><use xlink:href="#arrow_short_left"></use></svg>
            </i>
          </div>
        </div>
      </button>
      <button type="button" role="button" class="fS Jf _7_4 gg ga f_1 gn gi gu gt sl4-next">
        <div class="fU">
          <div class="fV">
            <i class="eO eS" aria-hidden="true"
              ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                <use xlink:href="#arrow_short_right"></use>
              </svg>
            </i>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Navigation, Pagination, Autoplay } from 'swiper'
import ComPicture from '../base/ComPicture.vue'
export default {
  components: { ComPicture },
  name: 'GoodsSee',
  props: {
    goods_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      modules: [Pagination, Navigation, Autoplay]
    }
  },
  computed: {
    ...mapState({
      list15: (state) => state.goods.list15
    })
  }
}
</script>

<style></style>
