import request from '@/utils/request'

const api = {
  file: 'upload/file'
}
export default {
  file(parameter) {
    return request({
      url: api.file,
      method: 'post',
      data: parameter
    })
  }
}
