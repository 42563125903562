<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('_st._jrong') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <a-row :gutter="24">
    <!-- 已借贷金额 -->
    <a-col :sm="24" :md="12" :xl="13" :style="{ marginBottom: '24px' }">
      <a-card :body-style="{ padding: '20px 24px 10px' }" :bordered="true">
        <div class="chart-header">
          <div class="meta">
            <div class="chart-title">{{ $t('_st._yqk') }}</div>
          </div>
          <div class="icon">
            <a-tooltip>
              <template #title>
                <div>dollar</div>
              </template>
              <dollar-outlined :style="{ fontSize: '24px' }" />
            </a-tooltip>
          </div>
        </div>
        <div class="chart-number" style="display: flex; justify-content: space-between">
          <div>${{ totalAmount }}</div>
        </div>

        <div class="chart-footer">
          <div style="width: 100%; display: flex; gap: 6px; flex-direction: column; align-items: flex-start">
            <div style="display: flex; gap: 15px">
              <div style="color: #ff9800; font-size: 15px; line-height: 32px">{{ $t('_st._yjk') }}: $ {{ wallet && wallet.finance }}</div>
              <div style="color: #ff9800; font-size: 15px; line-height: 32px">{{ $t('_st._dklx') }}: $ {{ wallet && wallet.interest }}</div>
              <div class="icon" style="display: flex; align-items: center" v-if="wallet.finance_limit > 0">
                <a-tooltip>
                  <template #title>
                    <div>{{ $t('_st._dkll', { n: wallet && parseFloat(wallet.interest_rate).toFixed(2) }) }}</div>
                  </template>
                  <question-circle-outlined :style="{ fontSize: '14px', color: '#ff9800' }" />
                </a-tooltip>
              </div>
            </div>
            <div style="color: #4caf50; font-size: 15px">{{ $t('_st._zzhk') }}: $ {{ wallet && wallet.repay }}</div>
          </div>
          <a-button type="primary" :style="{ marginLeft: '10px' }" @click="onApply">{{ $t('_st._sqjk') }}</a-button>
          <a-button type="" :style="{ marginLeft: '10px' }" @click="onRepayment">{{ $t('_st._sqhk') }}</a-button>
        </div>
      </a-card>
    </a-col>
  </a-row>
  <!-- 钱包记录 -->
  <div>
    <a-card>
      <a-tabs v-model:activeKey="activeKey" @change="onChange($event)">
        <a-tab-pane :key="1" :tab="$t('_st._dkjl')">
          <ApplyLoanList ref="loan"></ApplyLoanList>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
  <!-- 申请贷款 -->
  <ApplyLoan ref="apply" @Success="ApplyLoanSuccess"></ApplyLoan>

  <!-- 申请还款 -->
  <ApplyRepayment ref="repayment" @Success="ApplyRepaymentSuccess" :total="totalAmount"></ApplyRepayment>
</template>

<script>
import { DollarOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue'
import ApplyLoanList from '@/components/store/ApplyLoanList.vue'
import ApplyLoan from '@/components/store/ApplyLoan.vue'
import ApplyRepayment from '@/components/store/ApplyRepayment.vue'

export default {
  name: 'walletVue',
  components: {
    DollarOutlined,
    QuestionCircleOutlined,
    ApplyLoanList,
    ApplyLoan,
    ApplyRepayment
  },
  data() {
    return {
      //tabs
      activeKey: 1,
      // 钱包
      wallet: { finance: '0.00', repay: '0.00', interest: '0.00', interest_rate: 0 }
    }
  },
  created() {
    this.getWallet()
  },
  computed: {
    totalAmount() {
      let total = parseFloat(this.wallet.finance) + parseFloat(this.wallet.interest)
      return total.toFixed(2)
    }
  },
  methods: {
    onApply() {
      this.$refs.apply.show()
    },
    ApplyLoanSuccess() {
      this.$refs.loan.refresh()
    },
    getWallet() {
      let that = this
      this.$api.user.getWallet().then((res) => {
        that.wallet = res.data[0]
      })
    },
    onRepayment() {
      this.$refs.repayment.show()
    },
    ApplyRepaymentSuccess() {
      this.$refs.loan.refresh()
    }
  }
}
</script>

<style scoped src="@/static/css/store-css.css"></style>

<style lang="less">
.chart-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  .meta {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
  }
}

.chart-number {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  color: #000;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 38px;
  height: 38px;
}

.chart-footer {
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.xy_span {
  padding: 4px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 8px;
  cursor: pointer;
}
.xy_span.active {
  border: 1px solid #009688;
  color: #009688;
}

.token_css {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
  border: 1px solid #b7eb8f;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
