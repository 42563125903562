<template>
  <div id="headerReg" style="margin-top: 130px">
    <div class="header990">
      <div id="aliLogo" class="util-left">
        <router-link :to="{ name: 'index' }"> </router-link>
      </div>
    </div>
    <!-- header990 -->
    <div style="clear: both"></div>
  </div>
  <div id="root" data-spm-anchor-id="a2g0s.buyerloginandregister.0.i0.5a1355a3LaEiqz">
    <div class="login-container">
      <div class="batman-pc">
        <div class="comet-tabs">
          <div class="comet-tabs-wrapper" style="text-align: center">
            <div class="comet-tabs-nav">
              <div class="comet-tabs-nav-item comet-tabs-nav-item-active">{{ $t('forgot.title') }}</div>
            </div>
          </div>
          <!-- email and code -->
          <div class="comet-tabs-container" v-if="step == 1">
            <div class="comet-tabs-pane comet-tabs-pane-active">
              <div class="fm-login nc-outer-box">
                <div class="fm-field fm-field-phone">
                  <span class="comet-input-label-wrapper">
                    <span class="comet-input-label-content">
                      <input :placeholder="$t('signup.email')" autocapitalize="off" type="text" class="comet-input" v-model="params.email" @input="onInput" />
                    </span>
                    <span class="comet-input-suffix">
                      <span class="comet-icon comet-icon-wrongfill comet-input-clear-icon-hidden comet-input-clear-icon">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667 85.333333 747.637333 85.333333 512 276.362667 85.333333 512 85.333333zM405.088 359.936a32 32 0 0 0-43.712 46.688L466.741333 512l-105.365333 105.376-1.44 1.536a32 32 0 0 0 46.688 43.712L512 557.258667l105.376 105.365333 1.536 1.44a32 32 0 0 0 43.712-46.688L557.258667 512l105.365333-105.376 1.44-1.536a32 32 0 0 0-46.688-43.712L512 466.741333l-105.376-105.365333z"></path>
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>
                <div class="fm-field code-input">
                  <span class="comet-input-label-wrapper code-inpt">
                    <span class="comet-input-label-content">
                      <input :placeholder="$t('signup.code.text')" autocapitalize="off" type="text" class="comet-input" v-model="params.code" @input="onInput" />
                    </span>
                    <span class="comet-input-suffix">
                      <span class="comet-icon comet-icon-wrongfill comet-input-clear-icon-hidden comet-input-clear-icon">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667 85.333333 747.637333 85.333333 512 276.362667 85.333333 512 85.333333zM405.088 359.936a32 32 0 0 0-43.712 46.688L466.741333 512l-105.365333 105.376-1.44 1.536a32 32 0 0 0 46.688 43.712L512 557.258667l105.376 105.365333 1.536 1.44a32 32 0 0 0 43.712-46.688L557.258667 512l105.365333-105.376 1.44-1.536a32 32 0 0 0-46.688-43.712L512 466.741333l-105.376-105.365333z"></path>
                        </svg>
                      </span>
                    </span>
                  </span>
                  <span class="code-btn">
                    <a @click="onSendCode()">{{ codeBtnText }}</a>
                  </span>
                </div>
                <div class="fm-baxia-container" style="margin-top: 2px">
                  <div id="baxia-login-check-code" class="fm-baxia-box"></div>
                </div>
                <button @click="onCheck()" type="submit" class="comet-btn comet-btn-primary comet-btn-large comet-btn-block login-submit" :disabled="!isSubmit" style="background-color: #000; color: #fff">
                  <span>{{ $t('forgot.btn.check') }}</span>
                </button>
              </div>
            </div>
          </div>

          <!-- password -->
          <div class="comet-tabs-container" v-if="step == 2">
            <div class="comet-tabs-pane comet-tabs-pane-active">
              <div class="fm-login nc-outer-box">
                <div class="fm-field fm-field-phone">
                  <span class="comet-input-label-wrapper">
                    <span class="comet-input-label-content">
                      <input maxlength="40" type="password" :placeholder="$t('signup.password')" autocapitalize="off" class="comet-input" v-model="params.passwd" @input="onResetInput" />
                    </span>
                    <span class="comet-input-suffix">
                      <span class="comet-icon comet-icon-wrongfill comet-input-clear-icon-hidden comet-input-clear-icon">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667 85.333333 747.637333 85.333333 512 276.362667 85.333333 512 85.333333zM405.088 359.936a32 32 0 0 0-43.712 46.688L466.741333 512l-105.365333 105.376-1.44 1.536a32 32 0 0 0 46.688 43.712L512 557.258667l105.376 105.365333 1.536 1.44a32 32 0 0 0 43.712-46.688L557.258667 512l105.365333-105.376 1.44-1.536a32 32 0 0 0-46.688-43.712L512 466.741333l-105.376-105.365333z"></path>
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>
                <div class="fm-field fm-field-phone">
                  <span class="comet-input-label-wrapper">
                    <span class="comet-input-label-content">
                      <input maxlength="40" type="password" :placeholder="$t('signup.confirm.password')" autocapitalize="off" class="comet-input" v-model="params.confirm_passwd" @input="onResetInput" />
                    </span>
                    <span class="comet-input-suffix">
                      <span class="comet-icon comet-icon-wrongfill comet-input-clear-icon-hidden comet-input-clear-icon">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667 85.333333 747.637333 85.333333 512 276.362667 85.333333 512 85.333333zM405.088 359.936a32 32 0 0 0-43.712 46.688L466.741333 512l-105.365333 105.376-1.44 1.536a32 32 0 0 0 46.688 43.712L512 557.258667l105.376 105.365333 1.536 1.44a32 32 0 0 0 43.712-46.688L557.258667 512l105.365333-105.376 1.44-1.536a32 32 0 0 0-46.688-43.712L512 466.741333l-105.376-105.365333z"></path>
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>
                <div class="fm-baxia-container" style="margin-top: 2px">
                  <div id="baxia-login-check-code" class="fm-baxia-box"></div>
                </div>
                <button @click="onReset()" type="submit" class="comet-btn comet-btn-primary comet-btn-large comet-btn-block login-submit" :disabled="!isSubmit">
                  <span>{{ $t('forgot.reset.now') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <section class="fm-sns">
          <p class="fm-sns-title"><span class="left"></span><label>Or continue with</label><span class="right"></span></p>
          <div class="fm-sns-btns">
            <div class="fm-sns-item-wrap"><a class="fm-sns-item facebook"></a></div>
            <div class="fm-sns-item-wrap"><a class="fm-sns-item google"></a></div>
            <div class="fm-sns-item-wrap"><a class="fm-sns-item twitter"></a></div>
            <div class="fm-sns-item-wrap"><a class="fm-sns-item apple"></a></div>
          </div>
          <p class="fm-sns-trigger"><span class="show-all">Show all</span></p>
        </section> -->
      </div>
    </div>
  </div>
  <!-- <div class="footer-copywrite">
    <div class="container">
      <a href="javascript:" ref="nofollow">Intellectual Property Protection</a>
      -
      <a href="javascript:" ref="nofollow">Privacy Policy</a>
      -
      <a href="javascript:" ref="nofollow">Terms of Use</a>
      -
      <a href="javascript:" ref="nofollow">Information for EU consumers</a>
      -
      <a href="javascript:" ref="nofollow">User Information Legal Enquiry Guide</a>
      ©️2022-2023 .com. All rights reserved.
    </div>
  </div> -->
</template>

<script>
import { countdown } from '@/utils/tools'
export default {
  name: 'forgotVue',
  data() {
    return {
      //当前步骤
      step: 1,

      //参数
      params: {
        email: '',
        code: '',
        passwd: '',
        confirm_passwd: ''
      },

      //发送验证码
      hasSend: true,
      codeBtnText: this.$t('base.send.code'),

      //消息内容
      msgTitle: '',
      msgContent: '',
      msgRecord: {},

      //是否输入账户和密码
      isSubmit: false
    }
  },
  created() {},
  beforeRouteEnter(to, from, next) {
    // ...
    window.document.body.style.backgroundColor = '#FFFFFF'
    next()
  },
  beforeRouteLeave(to, from, next) {
    // ...
    window.document.body.style.backgroundColor = ''
    next()
  },
  methods: {
    /**
     * 发送验证码
     */
    onSendCode() {
      if (!this.hasSend) {
        return
      }

      if (!this.$common.checkEmail(this.params.email)) {
        this.$message.warning(this.$t('email.valid'))
        return
      }

      let key = this.$md5(this.params.email)

      this.$message.loading({ content: 'loading...', key: 'msg' })

      let that = this
      this.$api.code
        .sendCode({
          email: this.params.email,
          scene: 'forgot'
        })
        .then((res) => {
          that.$message.success({
            content: this.$t('base.send.success') /* + ",code:" + res.data.code*/,
            key: 'msg'
          })
          countdown.start(key, res.data.exp)
          countdown.run(key, that.CallTime)
        })
    },
    /**
     * 倒计时回调
     * @param {*} time
     */
    CallTime(time) {
      console.log(time)
      if (time == 0) {
        this.hasSend = true
        this.codeBtnText = this.$t('base.send.code')
      } else {
        this.hasSend = false
        this.codeBtnText = this.$t('base.send.time', {
          time: time
        })
      }
    },
    //输入框验证
    validParam(tips = false, reset = false) {
      // email.valid
      if (this.params.email.length == 0 || !this.$common.checkEmail(this.params.email)) {
        tips && this.$message.warning(this.$t('email.valid'))
        return false
      }

      //验证码
      if (this.params.code.length <= 0) {
        tips && this.$message.warning('Please enter Email Code')
        return false
      }

      if (reset) {
        if (this.params.passwd.length == 0) {
          tips && this.$message.warning(this.$t('password.valid'))
          return false
        }

        if (this.params.confirm_passwd.length == 0) {
          tips && this.$message.warning(this.$t('confirm.password.valid'))
          return false
        }

        if (this.params.passwd !== this.params.confirm_passwd) {
          tips && this.$message.warning(this.$t('confirm.passwords.not.match'))
          return false
        }
      }

      return true
    },
    onInput() {
      this.isSubmit = false
      if (!this.validParam(false)) {
        return
      }
      this.isSubmit = true
    },
    /**
     * 验证邮箱验证码
     */
    onCheck() {
      if (!this.validParam(true)) {
        return
      }
      this.$message.loading({ content: 'verifying...', key: 'msg' })

      let that = this
      const data = {
        email: this.params.email,
        code: this.params.code,
        scene: 'forgot'
      }
      this.$api.code.checkCode(data).then((res) => {
        this.$message.success({ content: res.msg, key: 'msg' })
        that.step = 2
      })
    },
    /**
     * 重置密码验证
     */
    onResetInput() {
      this.isSubmit = false
      if (!this.validParam(false, true)) {
        return
      }
      this.isSubmit = true
    },
    /**
     * 重置密码
     */
    onReset() {
      if (!this.validParam(true, true)) {
        return
      }
      this.$message.loading({ content: 'reset password...', key: 'msg' })

      let that = this
      this.$api.auth.resetPasswd(this.params).then((res) => {
        this.$message.success({ content: res.msg, key: 'msg' })
        setTimeout(function () {
          that.$router.push({ name: 'index' })
        }, 1000)
      })
    }
  }
}
</script>
<style scoped>
body {
  background-color: #fff !important;
}
.footer-copywrite {
  background-color: #333;
  color: #fff;
  line-height: 18px;
  padding: 8px 0;
}
.footer-copywrite {
  font-size: 12px;
  position: fixed;
  z-index: 1;
  width: 100%;
  bottom: 0;
}

.footer-copywrite .container {
  opacity: 0.6;
}

.footer-copywrite .container {
  opacity: 0.6;
  width: 990px;
  margin: auto;
}

.footer-copywrite a {
  color: #fff;
  margin: 0 2px;
}
body {
  background-color: #fff;
}
.comet-input-label-content input.comet-input {
  padding-top: 0px !important;
}

.login-container {
  padding-top: 48px;
}

.code-btn {
  min-width: 25%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.code-input {
  display: flex;
  align-items: center;
}

.code-inpt {
  min-width: 60%;
  flex: 1 1;
}
</style>
<style scoped src="../../static/css/store-signin.css"></style>
