import { createI18n } from 'vue-i18n'
import Const from './const'
import zh_hans from '@/lang/zh-hans'
import zh_hant from '@/lang/zh-hant'
import en_Us from '@/lang/en-us'
import ja_Jp from '@/lang/ja-jp'
import ko_Kr from '@/lang/ko-kr'
import de_De from '@/lang/de-de'
import ar_Sa from '@/lang/ar-sa'
import be_by from '@/lang/be-by'
import ro_ro from '@/lang/ro-ro'
import fr_fr from '@/lang/fr-fr'
import tr_tr from '@/lang/tr-tr'
import it_it from '@/lang/it-it'
import fi_fi from '@/lang/fi-fi'
import es_es from '@/lang/es-es'
import dk_da from '@/lang/dk-da'
import nl_nl from '@/lang/nl-nl'
import vi_vn from '@/lang/vi-vn'
import pt_pt from '@/lang/pt-pt'
//语言库
const messages = {
  'zh-hans': zh_hans,
  'zh-hant': zh_hant,
  'en-us': en_Us,
  'ja-jp': ja_Jp,
  'ko-kr': ko_Kr,
  'de-de': de_De,
  'ar-sa': ar_Sa,
  'be-by': be_by,
  'ro-ro': ro_ro,
  'fr-fr': fr_fr,
  'tr-tr': tr_tr,
  'it-it': it_it,
  'fi-fi': fi_fi,
  'es-es': es_es,
  'dk-da': dk_da,
  'nl-nl': nl_nl,
  'vi-vn': vi_vn,
  'pt-pt': pt_pt
}

const defLang = { text: 'English', lang: 'en-us' }

//本地缓存
let lang = JSON.parse(localStorage.getItem(Const.KEY_LANG))
if (!lang) {
  lang = defLang
}
console.log('Cache Lang', lang)

//浏览器语言
// const langugage = (
//   (navigator.language ? navigator.language : navigator.userLanguage) || "en-us"
// ).toLowerCase();
// const langugage = 'en-us'
// console.log('Browser Lang', langugage)

// 创建对象
const i18n = createI18n({
  fallbackLocale: 'en-us',
  globalInjection: true,
  legacy: false,
  locale: lang.lang,
  messages
})

//设置语言
export const setLangauge = (lang) => {
  if (lang === undefined) {
    //浏览器是否缓存
    lang = localStorage.getItem(Const.KEY_LANG)

    //如果未缓存。或者缓存的不是
    if (!lang || messages[lang.lang] === undefined) {
      lang = defLang
    }
    //初始化
    localStorage.setItem(Const.KEY_LANG, JSON.stringify(lang))
  } else {
    localStorage.setItem(Const.KEY_LANG, JSON.stringify(lang))
  }

  Object.keys(messages).forEach((lang) => {
    document.body.classList.remove(`lang-${lang}`)
  })
  document.body.classList.add(`lang-${lang.lang}`)
  document.body.setAttribute('lang', lang.lang)

  //i18n.locale = lang;
}

// 初始化
const current = localStorage.getItem(Const.KEY_LANG)
console.log('Current', current)
setLangauge(current == null ? undefined : JSON.parse(current))

export default i18n
