<template>
  <a-modal v-model:visible="visible" :title="$t('setting.user.passwd')" @ok="onOk()" :style="{width:'600px'}" :okText="$t('recharge.ok.text')" :cancelText="$t('recharge.cancel.text')" :confirm-loading="confirmLoading">
    <div :style="{ padding: '0 20px'}">
      <a-form ref="form" :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" autocomplete="off">
        <a-form-item :label="$t('setting.passwd.title1')" name="old_passwd" :rules="[{ required: true, message: $t('setting.passwd.desc1') }]">
          <a-input type="password" v-model:value="params.old_passwd" :placeholder="$t('setting.passwd.desc1')" />
        </a-form-item>
        <a-form-item :label="$t('setting.passwd.title2')" name="new_passwd" :rules="[{ required: true, message: $t('setting.passwd.desc2') }]">
          <a-input type="password" v-model:value="params.new_passwd" :placeholder="$t('setting.passwd.desc2')" />
        </a-form-item>
        <a-form-item :label="$t('setting.passwd.title3')" name="confirm_passwd" :rules="[{ required: true, message: $t('setting.passwd.desc3') }]">
          <a-input type="password" v-model:value="params.confirm_passwd" :placeholder="$t('setting.passwd.desc3')" />
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "UpdateInfo",
  components: {
  },
  data () {
    return {
      //参数
      params: {},

      //弹框
      visible: false,
      confirmLoading: false,
    };
  },
  created () {
  },
  methods: {
    /**
     * 存币
     */
    show () {
      this.visible = true;
    },
    hide () {
      this.visible = false;
    },
    onOk () {
      this.$refs.form.validateFields().then(values => {
        console.log(values)
        this.confirmLoading = true

        //提交评论
        let that = this;
        this.$api.user.savePasswd(values).then((res) => {
          that.confirmLoading = false
          that.$message.success(res.msg);
          setTimeout(() => {
            that.hide();
            that.$refs.form.resetFields()
            that.$emit("onSuccess");
          }, 500);
        }).catch(err=>{
          err
          that.confirmLoading = false
        });
      })
    },
  },
};
</script>

<style>
</style>