import { createFromIconfontCN } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
const Icon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_3909637_wrer6nhicgh.js", // 在 iconfont.cn 上生成
});

export const whatsapp = defineComponent({
  setup() {
    return () => <Icon type="icon-whatsapp" style="font-size:14.5px;margin-right: 3px;" />;
  },
});

export const telegram = defineComponent({
  setup() {
    return () => <Icon type="icon-telegram" style="font-size:15px;margin-right: 3px;" />;
  },
});

export const line = defineComponent({
  setup() {
    return () => <Icon type="icon-line" style="font-size:18px;margin-right: 3px;" />;
  },
});

