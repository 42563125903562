<template>
  <section class="Bw">
    <div class="T_1">
      <!-- 评论头部 -->
      <section class="BZ" id="rating_and_reviews">
        <h3 class="Cg">
          <span style="vertical-align: inherit">
            <span style="vertical-align: inherit">{{ $t('detail.customer.reviews') }}</span>
          </span>
        </h3>
        <!-- 评分信息 -->
        <div class="B_ B_0">
          <div class="B_2">
            <div class="B_4">
              <div class="B_5">
                <span style="vertical-align: inherit">
                  <span style="vertical-align: inherit">{{ comment.avg }}</span>
                </span>
              </div>
              <p class="B_6">
                <span style="vertical-align: inherit">
                  <span style="vertical-align: inherit">{{ comment.total }} {{ $t('_n2._tiao') }}</span> </span
                >&nbsp;<span style="vertical-align: inherit">
                  <span style="vertical-align: inherit">{{ $t('detail.comment') }}</span>
                </span>
              </p>
            </div>
            <!-- 评星 -->
            <ul class="B_7" v-if="comment.s1">
              <!-- 5星 -->
              <li class="B_8">
                <a href="javascript:" class="B_9">
                  <div class="efokaq12">
                    <div>
                      <svg width="24px" height="24px" focusable="false" class="nui-icon nui-icon-large-star-full">
                        <path id="_x3C_Path_x3E__2_" class="nui-icon-large-star-full-0" d="m11.996 1.5 2.6 8.02H23l-6.802 4.959 2.596 8.021-6.798-4.958L5.198 22.5l2.596-8.021L1 9.52h8.4z"></path>
                      </svg>
                    </div>
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">5</span>
                    </span>
                  </div>

                  <div class="Cd">
                    <div class="Ce" :style="'width:' + comment.s5.rate + '%'"></div>
                  </div>

                  <div class="Cf">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">{{ comment.s5.num }}</span>
                    </span>
                  </div>
                </a>
              </li>
              <!-- 4星 -->
              <li class="B_8">
                <a href="javascript:" class="B_9">
                  <div class="efokaq12">
                    <div>
                      <svg width="24px" height="24px" focusable="false" class="nui-icon nui-icon-large-star-full">
                        <path id="_x3C_Path_x3E__2_" class="nui-icon-large-star-full-0" d="m11.996 1.5 2.6 8.02H23l-6.802 4.959 2.596 8.021-6.798-4.958L5.198 22.5l2.596-8.021L1 9.52h8.4z"></path>
                      </svg>
                    </div>
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">4</span>
                    </span>
                  </div>
                  <div class="Cd">
                    <div class="Ce" :style="'width:' + comment.s4.rate + '%'"></div>
                  </div>
                  <div class="Cf">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">{{ comment.s4.num }}</span>
                    </span>
                  </div>
                </a>
              </li>
              <!-- 3星 -->
              <li class="B_8">
                <a href="javascript:" class="B_9">
                  <div class="efokaq12">
                    <div>
                      <svg width="24px" height="24px" focusable="false" class="nui-icon nui-icon-large-star-full">
                        <path id="_x3C_Path_x3E__2_" class="nui-icon-large-star-full-0" d="m11.996 1.5 2.6 8.02H23l-6.802 4.959 2.596 8.021-6.798-4.958L5.198 22.5l2.596-8.021L1 9.52h8.4z"></path>
                      </svg>
                    </div>
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">3</span>
                    </span>
                  </div>
                  <div class="Cd">
                    <div class="Ce" :style="'width:' + comment.s3.rate + '%'"></div>
                  </div>
                  <div class="Cf">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">{{ comment.s3.num }}</span>
                    </span>
                  </div>
                </a>
              </li>
              <!-- 2星 -->
              <li class="B_8">
                <a href="javascript:" class="B_9">
                  <div class="efokaq12">
                    <div>
                      <svg width="24px" height="24px" focusable="false" class="nui-icon nui-icon-large-star-full">
                        <path id="_x3C_Path_x3E__2_" class="nui-icon-large-star-full-0" d="m11.996 1.5 2.6 8.02H23l-6.802 4.959 2.596 8.021-6.798-4.958L5.198 22.5l2.596-8.021L1 9.52h8.4z"></path>
                      </svg>
                    </div>
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">2</span>
                    </span>
                  </div>
                  <div class="Cd">
                    <div class="Ce" :style="'width:' + comment.s2.rate + '%'"></div>
                  </div>
                  <div class="Cf">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">{{ comment.s2.num }}</span>
                    </span>
                  </div>
                </a>
              </li>
              <!-- 1星 -->
              <li class="B_8">
                <a href="javascript:" class="B_9">
                  <div class="efokaq12">
                    <div>
                      <svg width="24px" height="24px" focusable="false" class="nui-icon nui-icon-large-star-full">
                        <path id="_x3C_Path_x3E__2_" class="nui-icon-large-star-full-0" d="m11.996 1.5 2.6 8.02H23l-6.802 4.959 2.596 8.021-6.798-4.958L5.198 22.5l2.596-8.021L1 9.52h8.4z"></path>
                      </svg>
                    </div>
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">1</span>
                    </span>
                  </div>
                  <div class="Cd">
                    <div class="Ce" :style="'width:' + comment.s1.rate + '%'"></div>
                  </div>
                  <div class="Cf">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">{{ comment.s1.num }}</span>
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <!-- 右侧按钮 -->
          <footer class="B_1">
            <a class="Ch" href="javascript:" data-testid="productAllReviews">
              <div class="fS fW ga f_1 gh gl f_4">
                <div class="fU">
                  <div class="fV">
                    <span>
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">{{ $t('_n2._sypl') }} ( </span>
                      </span>
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">{{ comment.total }} </span>
                      </span>
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">)</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </a>
            <!-- <button type="button" role="button" data-testid="productAddReview" class="fS Ch fW ga f_1 gh gi f_4">
              <div class="fU">
                <div class="fV">
                  <span style="vertical-align: inherit">
                    <span style="vertical-align: inherit">{{ $t('_n2._lxfk') }}</span>
                  </span>
                </div>
              </div>
            </button> -->
            <div></div>
          </footer>
        </div>
      </section>
    </div>

    <!-- 评论列表 -->
    <ul class="T_3">
      <!-- 一条评论 -->
      <li class="T_4" v-for="(vo, i) in commentList" :key="i">
        <section class="AY">
          <article class="AZ">
            <div class="A_">
              <div>
                <!-- <div class="mv mB">
                  <div :class="['mx', parseInt(vo.star / 0.5) > 10 - v ? 'my' : '']" v-for="(v, n) in 10" :key="n"></div>
                </div> -->
                <div class="bhc">
                  <span class="WVbEJ tKey9">
                    <span role="img" class="T2Mzf" aria-label="4 out of 5 stars" style="font-size:1.5rem">
                      <span class="GocDN dUBUg" style="font-size:1.5rem"></span>
                      <span class="GocDN bFCnj" :style="'width: ' + 20 * parseInt(vo.star?vo.star:0) + '%;font-size:1.5rem'"></span>
                    </span>
                    <!-- <span class="HZv8u">(219)</span> -->
                  </span>
                  <span class="bhf"> </span>
                </div>
                <p class="A_4">
                  <span style="vertical-align: inherit">
                    <span style="vertical-align: inherit">5</span>
                  </span>
                  <span style="vertical-align: inherit">
                    <span style="vertical-align: inherit">颗星，满分 5 颗星</span>
                  </span>
                </p>
                <div class="R_5 Sc R_8 Se A_1 Sg" style="background-color: rgb(240, 252, 242); color: rgb(0, 138, 56)">
                  <div class="Sd">
                    <span class="A_2">
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit; display: flex">
                          <div v-for="(svo, sk) in vo.attr" :key="sk" style="margin-right: 10px">{{ sk }}:{{ svo }}</div>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="Xw">
                <button type="button" class="XA" data-testid="reviewCardOptions">
                  <i class="eO eU Xx" aria-hidden="true"
                    ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                      <use xlink:href="#more"></use></svg></i
                  ><span class="Xy">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">打开菜单</span>
                    </span>
                  </span>
                </button>
                <div class="Ab Ao Au As Ad">
                  <div class="Ae Ay">
                    <ul>
                      <li class="Xz">
                        <button type="button" class="XA">
                          <span style="vertical-align: inherit">
                            <span style="vertical-align: inherit">复制链接进行评论</span>
                          </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <dl>
              <!-- <dt class="A_9">
                <span style="vertical-align: inherit">
                  <span style="vertical-align: inherit">评论</span>
                </span>
              </dt> -->
              <dd class="Bb">
                <div style="vertical-align: inherit">
                  {{ vo.comment }}
                </div>
              </dd>
            </dl>
            <!-- <div class="XN">
              <h3 class="XO XP">
                <span style="vertical-align: inherit">
                  <span style="vertical-align: inherit">店铺回复</span>
                </span>
              </h3>
              <p class="XR">
                <span style="vertical-align: inherit">
                  <span style="vertical-align: inherit">你以为你的评论是正确的吗？</span>
                </span>
              </p>
              <time class="XS">
                <span style="vertical-align: inherit">
                  <span style="vertical-align: inherit">2022 年 9 月 16 日</span>
                </span>
              </time>
            </div> -->
            <ul class="XC" v-if="vo.imgs">
              <li class="XD">
                <div class="XE">
                  <button type="button" class="XF" v-for="(vvo, ii) in vo.imgs" :key="ii">
                    <img class="XG" alt="" loading="lazy" :src="vvo" />
                  </button>
                </div>
              </li>
            </ul>
            <footer class="Bc">
              <div class="Bd">
                <p class="Be">
                  <span style="vertical-align: inherit">
                    <span style="vertical-align: inherit">{{ vo.nickname }}</span>
                  </span>
                </p>
                <time class="Bf">
                  <span style="vertical-align: inherit">
                    <span style="vertical-align: inherit">{{ vo.create_time }}</span>
                  </span>
                </time>
              </div>
              <div class="Xi">
                <label class="Xj" data-test-id="reviewCardReactionsDislike"
                  ><input class="Xk Xq" type="checkbox" />
                  <div class="Xn Xl">
                    <i class="eO eT Xp" aria-hidden="true"
                      ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                        <use xlink:href="#dislike"></use></svg></i
                    ><span>
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">0</span>
                      </span>
                    </span>
                  </div> </label
                ><label class="Xj" data-test-id="reviewCardReactionsLike"
                  ><input class="Xk Xq" type="checkbox" />
                  <div class="Xn Xm">
                    <i class="eO eT Xp" aria-hidden="true"
                      ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                        <use xlink:href="#like"></use></svg></i
                    ><span>
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">0</span>
                      </span>
                    </span>
                  </div>
                </label>
              </div>
            </footer>
          </article>
        </section>
      </li>
    </ul>
    <a class="T_5" href="javascript:" data-testid="productAllReviewsBottom">
      <div class="fS fW ga f_1 gn gi f_4">
        <div class="fU">
          <div class="fV">
            <span>
              <span style="vertical-align: inherit">
                <span style="vertical-align: inherit">{{ $t('_n2._sypl') }} ( </span>
              </span>
              <span style="vertical-align: inherit">
                <span style="vertical-align: inherit">{{ comment.total }} </span>
              </span>
              <span style="vertical-align: inherit">
                <span style="vertical-align: inherit">)</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </a>
    <div class="_M _N" data-testid="pictureBlock"></div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'GoodsComment',
  props: {
    goods_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      commentList: []
    }
  },
  watch: {
    goods_id(id) {
      console.log(id, 'goods_id')
      this.$store.dispatch('goods/getCommentInfo', { id: this.goods_id })
      this.getCommentList(1)
    }
  },
  created() {},
  computed: {
    ...mapState({
      comment: (state) => state.goods.comment
    })
  },
  mounted() {
    if (this.goods_id) {
      this.$store.dispatch('goods/getCommentInfo', { id: this.goods_id })
      this.getCommentList(1)
    }
  },
  methods: {
    getCommentList(page) {
      //获取商品
      let hide = this.$message.loading({ content: 'loading...', key: 'msg' })
      this.$api.goods
        .getCommentList({
          id: this.goods_id,
          page: page,
          size: 5
        })
        .then((res) => {
          hide()
          this.commentList = res.data.data
        })
    }
  }
}
</script>

<style>
.efokaq12 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: 0px 5px;
}
</style>
