<template>
  <div class="lBg bjs bg1 Bzj" v-if="isShow">
    <div class="lbW">
      <div class="lBox">
        <div class="mBox">
          <!-- 关闭按钮 -->
          <button type="button" role="button" class="Jf1" @click="onClose()">
            <div class="fU">
              <div class="fV">
                <i class="eO eS" aria-hidden="true"
                  ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                    <use xlink:href="#cross"></use>
                  </svg>
                </i>
              </div>
            </div>
          </button>
          <!-- 标题 -->
          <div class="Rbc">
            <h3 :class="['Mbt', stepShow == UI.Signin ? 'Active' : '']" @click="onChangeType(UI.Signin)">{{ $t('signup.signin') }}</h3>
            <h3 :class="['Mbt', stepShow == UI.Signup ? 'Active' : '']" @click="onChangeType(UI.Signup)">{{ $t('signup.register') }}</h3>
          </div>

          <!-- 登录 -->
          <div class="Srk" v-if="stepShow == UI.Signin">
            <div class="">
              <!-- 账号框 -->
              <div class="_K31C">
                <div class="_K31E">
                  <div class="_KOJ">
                    <div class="_Ic1">
                      <!-- <i class="_Ic2" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eg">
                          <use xlink:href="#email"></use>
                        </svg>
                      </i> -->
                    </div>
                    <label class="kPs">
                      <input @keyup.enter="onSignin" v-model="signin.email" class="kpP" :placeholder="$t('signup.email')" name="promoCode" maxlength="254" autocomplete="off" />
                    </label>
                    <div class="kpO">
                      <div type="submit" role="button" class="kfz 1_3W">
                        <div class="e1Z">
                          <i class="eOS" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                              <use xlink:href="#cross"></use>
                            </svg>
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 密码框 -->
              <div class="_K31C">
                <div class="_K31E">
                  <div class="_KOJ">
                    <div class="_Ic1">
                      <!-- <i class="_Ic2" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eg">
                          <use xlink:href="#email"></use>
                        </svg>
                      </i> -->
                    </div>
                    <label class="kPs">
                      <input @keyup.enter="onSignin" v-model="signin.passwd" type="password" class="kpP" :placeholder="$t('signup.password')" name="promoCode" maxlength="254" spellcheck="false" />
                      <div class="ppu"></div>
                    </label>
                    <div class="kpO">
                      <div type="submit" role="button" class="kfz 1_3W">
                        <div class="e1Z">
                          <i class="eOS" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                              <use xlink:href="#cross"></use>
                            </svg>
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 忘记密码 -->
              <div class="_K31C">
                <router-link class="Wjl" :to="{ name: 'forgot' }"> {{ $t('signup.forgot.password') }}? </router-link>
              </div>

              <div class="_K31C">
                <button class="Dlbtn -disb" @click="onSignin()">
                  <span class="bha_1">
                    <div style="vertical-align: inherit">
                      <div style="vertical-align: inherit">{{ $t('signup.signin') }}</div>
                    </div>
                  </span>
                </button>
              </div>

              <!-- 商家登录 -->
              <div class="_K31C" style="flex-direction: inherit; justify-content: center; color: #888">
                {{ $t('signup.have.store') }}？
                <router-link :to="{ name: 'storeSignin' }" class="Wjl">{{ $t('signup.goto.signin') }}</router-link>
              </div>
            </div>
          </div>

          <!-- 注册 -->
          <div class="Srk" v-if="stepShow == UI.Signup">
            <!-- 注册信息 -->
            <div class="" v-if="signupStep == STEP.Email">
              <!-- 用户注册 -->
              <div class="_K31C" style="flex-direction: inherit; justify-content: flex-start" v-if="signup.type == USER.Store">
                {{ $t('_zc._yhzh') }}？
                <a class="Wjl" @click="onChangeUserType(USER.User)">{{ $t('_zc._qzc') }}</a>
              </div>

              <!-- 商家注册 -->
              <div class="_K31C" style="flex-direction: inherit; justify-content: flex-start" v-if="signup.type == USER.User">
                {{ $t('footer.be.seller') }}？
                <a class="Wjl" @click="onChangeUserType(USER.Store)">{{ $t('_zc._qzc') }}</a>
              </div>

              <!-- 账号框 -->
              <div class="_K31C">
                <div class="_K31E">
                  <div class="_KOJ">
                    <div class="_Ic1"></div>
                    <label class="kPs">
                      <input v-model="signup.email" class="kpP" :placeholder="$t('signup.email')" name="promoCode" maxlength="254" autocomplete="off" />
                    </label>
                    <div class="kpO">
                      <div class="kfz 1_3W">
                        <div class="e1Z" @click="signup.email = ''">
                          <i class="eOS" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                              <use xlink:href="#cross"></use>
                            </svg>
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 密码框 -->
              <div class="_K31C">
                <div class="_K31E">
                  <div class="_KOJ">
                    <div class="_Ic1"></div>
                    <label class="kPs">
                      <input v-model="signup.passwd" type="password" class="kpP" :placeholder="$t('signup.password')" name="promoCode" maxlength="254" spellcheck="false" />
                    </label>
                    <div class="kpO">
                      <div class="kfz 1_3W">
                        <div class="e1Z" @click="signup.passwd = ''">
                          <i class="eOS" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                              <use xlink:href="#cross"></use>
                            </svg>
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 确认密码框 -->
              <div class="_K31C">
                <div class="_K31E">
                  <div class="_KOJ">
                    <div class="_Ic1">
                      <!-- <i class="_Ic2" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eg">
                          <use xlink:href="#email"></use>
                        </svg>
                      </i> -->
                    </div>
                    <label class="kPs">
                      <input v-model="signup.qr_passwd" type="password" class="kpP" :placeholder="$t('signup.qr.password')" name="promoCode" maxlength="254" spellcheck="false" />
                    </label>
                    <div class="kpO">
                      <div class="kfz 1_3W">
                        <div class="e1Z" @click="signup.qr_passwd = ''">
                          <i class="eOS" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                              <use xlink:href="#cross"></use>
                            </svg>
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <template v-if="signup.type == USER.Store">
                <!-- 邀请码 -->
                <div class="_K31C">
                  <div class="_K31E">
                    <div class="_KOJ">
                      <div class="_Ic1"></div>
                      <label class="kPs">
                        <input v-model="signup.inv_code" class="kpP" :placeholder="$t('signup.invitation')" name="promoCode" maxlength="254" autocomplete="off" />
                      </label>
                      <div class="kpO">
                        <div class="kfz 1_3W">
                          <div class="e1Z" @click="signup.inv_code = ''">
                            <i class="eOS" aria-hidden="true">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                <use xlink:href="#cross"></use>
                              </svg>
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 商家信息 -->
                <div class="_K31C" style="flex-direction: inherit; justify-content: flex-start">
                  {{ $t('beseller.store') }}
                </div>

                <!-- 店铺名称 -->
                <div class="_K31C">
                  <div class="_K31E">
                    <div class="_KOJ">
                      <div class="_Ic1"></div>
                      <label class="kPs">
                        <input v-model="signup.store_name" class="kpP" :placeholder="$t('beseller.store.name')" name="promoCode" maxlength="254" autocomplete="off" />
                      </label>
                      <div class="kpO">
                        <div class="kfz 1_3W">
                          <div class="e1Z" @click="signup.store_name = ''">
                            <i class="eOS" aria-hidden="true">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                <use xlink:href="#cross"></use>
                              </svg>
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 店铺地址 -->
                <div class="_K31C">
                  <div class="_K31E">
                    <div class="_KOJ">
                      <div class="_Ic1"></div>
                      <label class="kPs">
                        <input v-model="signup.store_address" class="kpP" :placeholder="$t('beseller.store.address')" name="promoCode" maxlength="254" autocomplete="off" />
                      </label>
                      <div class="kpO">
                        <div class="kfz 1_3W">
                          <div class="e1Z" @click="signup.store_address = ''">
                            <i class="eOS" aria-hidden="true">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                <use xlink:href="#cross"></use>
                              </svg>
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <!-- 滑条验证器 -->
              <div class="fm-baxia-container" style="margin: 20px 0">
                <div id="baxia-join-check-code" class="fm-baxia-box">
                  <verify-slider ref="verify" @success="onVerifySuccess"></verify-slider>
                </div>
                <!-- <span class="error-text" v-show="!isVerify">{{ $t('base.slide') }}</span> -->
              </div>

              <!-- 注册协议 -->
              <div class="_K31C">
                <div class="Wjl01">
                  <span>{{ $t('signup.agree') }}</span>
                  <router-link :to="{ name: 'docs', query: { key: 'member' } }" class="Wjl">《{{ $t('signup.member.agreement') }}》</router-link>
                  <span>{{ $t('signup.and') }}</span>
                  <router-link :to="{ name: 'docs', query: { key: 'privacy' } }" class="Wjl">《{{ $t('signup.privacy.policy') }}》</router-link>
                </div>
              </div>

              <div class="_K31C">
                <button class="Dlbtn -disb" @click="onNextSendEmail()">
                  <span class="bha_1">
                    <div style="vertical-align: inherit">
                      <div style="vertical-align: inherit">{{ $t('signup.next') }}</div>
                    </div>
                  </span>
                </button>
              </div>
            </div>

            <!-- 实名认证 -->
            <div class="verficate-view batman-pc" v-show="signupStep == STEP.Auth">
              <div class="cosmos-tabs">
                <div class="cosmos-tabs-container">
                  <div class="cosmos-tabs-pane cosmos-tabs-pane-active">
                    <div class="fm-join">
                      <div class="fm-loading-wrap">
                        <!-- <h1 class="batman-verify-title">
                          {{ $t('signup.smrz') }}
                          <br />{{ $t('signup.derb') }}
                        </h1> -->
                        <p class="batman-verify-desc">
                          <span style="font-weight: bold"></span>
                        </p>
                        <div class="">
                          <!-- 身份证正面 -->
                          <div style="margin-bottom: 15px">
                            <div class="_K31C" style="flex-direction: inherit; justify-content: flex-start">
                              {{ $t('_scrz._yyzz') }}
                            </div>
                            <div>
                              <upload-file @onUpload="onFrontUpload" :imageUrl="imageFrontUrl"></upload-file>
                            </div>
                          </div>

                          <!-- 身份证反面 -->
                          <div style="margin-bottom: 15px" hidden>
                            <div class="_K31C" style="flex-direction: inherit; justify-content: flex-start">
                              {{ $t('auth.sffm') }}
                            </div>
                            <div>
                              <upload-file @onUpload="onBackUpload" :imageUrl="imageBackUrl"></upload-file>
                            </div>
                          </div>

                          <!-- 真实昵称 -->
                          <div class="_K31C" hidden>
                            <div class="_K31E">
                              <div class="_KOJ">
                                <div class="_Ic1"></div>
                                <label class="kPs">
                                  <input v-model="signup.name" class="kpP" :placeholder="$t('signup.qsrxm')" name="name" autocomplete="off" />
                                </label>
                                <div class="kpO">
                                  <div class="kfz 1_3W">
                                    <div class="e1Z" @click="signup.email = ''">
                                      <i class="eOS" aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                          <use xlink:href="#cross"></use>
                                        </svg>
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- 真实昵称 -->
                          <div class="_K31C" hidden>
                            <div class="_K31E">
                              <div class="_KOJ">
                                <div class="_Ic1"></div>
                                <label class="kPs">
                                  <input v-model="signup.idcard" class="kpP" :placeholder="$t('signup.qsrhm')" name="card" autocomplete="off" />
                                </label>
                                <div class="kpO">
                                  <div class="kfz 1_3W">
                                    <div class="e1Z" @click="signup.email = ''">
                                      <i class="eOS" aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                          <use xlink:href="#cross"></use>
                                        </svg>
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="fm-baxia-container" style="margin-top: 8px">
                          <div id="baxia-join-noMember" class="fm-baxia-box"></div>
                        </div>

                        <div class="_K31C">
                          <button class="Dlbtn -disb" @click="onNextSendEmail2()">
                            <span class="bha_1">
                              <div style="vertical-align: inherit">
                                <div style="vertical-align: inherit">{{ $t('signup.next') }}</div>
                              </div>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 发送邮箱验证 -->
            <div class="" v-if="signupStep == STEP.Code">
              <!-- 说明 -->
              <div class="_K31C">
                <div class="email_ak1">
                  <div class="e_ak2">{{ $t('signup.email.code') }}</div>
                  <div class="e_prev">
                    <div>{{ $t('signup.send.email', { email: signup.email }) }}</div>
                    <div>
                      <a class="Wjl" @click="onPrevSignup()">{{ $t('signup.modify.email') }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 账号框 -->
              <div class="e_K31C">
                <div class="_K31E" style="flex: 1 75%">
                  <div class="_KOJ">
                    <div class="_Ic1"></div>
                    <label class="kPs">
                      <input v-model="signup.code" class="kpP" :placeholder="$t('signup.code.text')" name="promoCode" maxlength="254" autocomplete="off" />
                    </label>
                    <!-- <div class="kpO">
                      <div class="kfz 1_3W">
                        <div class="e1Z">
                          <i class="eOS" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                              <use xlink:href="#cross"></use>
                            </svg>
                          </i>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="e_send" style="flex: 1 25%">
                  <a @click="onSendCode()"> {{ send_code_text }} </a>
                </div>
              </div>

              <!-- 忘记密码 -->
              <div class="_K31C">
                <button class="Dlbtn -disb" @click="onSignup()">
                  <span class="bha_1">
                    <div style="vertical-align: inherit">
                      <div style="vertical-align: inherit">{{ $t('signup.submit.signup') }}</div>
                    </div>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerifySlider from '@/components/common/VerifySlider.vue'
import { countdown } from '@/utils/tools'
import UploadFile from '@/components/UploadFile.vue'

const UI = {
  Signin: 1,
  Signup: 2
}

const USER = {
  User: 1,
  Store: 2
}

const STEP = {
  Email: 1,
  Auth: 2,
  Code: 3
}

export default {
  name: 'SigninVue',
  components: { VerifySlider, UploadFile },
  emits: ['tokenStatus'],
  data() {
    return {
      UI,
      STEP,
      USER,

      //显示
      isShow: false,

      //正在登录中
      isLogin: false,
      //登录
      signin: {
        type: 1,
        email: '',
        passwd: ''
      },

      //注册步骤
      stepShow: UI.Signin,

      //滑块是否验证
      isVerify: false,

      //注册步骤
      signupStep: STEP.Email,

      //认证
      imageFrontUrl: '',

      imageBackUrl: '',

      //注册
      signup: {
        type: 1,
        email: '1234561@qq.com',
        passwd: '123456',
        qr_passwd: '123456',
        code: '',
        inv_code: '',
        name: '',
        idcard: '',
        store_name: '',
        store_address: ''
      },

      //倒计时
      hasSend: true,
      send_code_text: this.$t('base.send.code')
    }
  },
  methods: {
    onShow(email = '') {
      this.isVerify = false
      this.isShow = true

      //初始化
      this.stepShow = UI.Signin
      this.signupStep = STEP.Email
      this.signin = {
        type: 1,
        email: email,
        passwd: ''
      }

      this.signup = {
        type: USER.User,
        email: '',
        passwd: '',
        qr_passwd: '',
        inv_code: '',
        name: '',
        idcard: '',
        store_name: '',
        store_address: ''
      }

      //重置
      // let that = this
      // this.$nextTick(function () {
      //   that.$refs.verify.reset()
      // })
    },
    onClose() {
      this.isShow = false
    },
    /**
     * 切换登录注册
     */
    onChangeType(type) {
      this.stepShow = type
    },
    onChangeUserType(type) {
      this.signup.type = type
    },
    /**
     * 输入框验证
     */
    validSignin(tips = false) {
      //邮箱未输入
      if (this.signin.email.length <= 0) {
        tips && this.$message.warning('Please enter a valid Email Address')
        return false
      }

      //密码未输入
      if (this.signin.passwd.length <= 0) {
        tips && this.$message.warning('Please enter a valid Email Address')
        return false
      }

      return true
    },
    onSignin() {
      if (this.isLogin) {
        return
      }
      this.isLogin = true

      if (!this.validSignin(true)) {
        return
      }
      this.$message.loading({ duration: 0, content: 'loading...', key: 'msg' })

      let that = this
      this.signin.email = this.$common.rmSpaces(this.signin.email)
      this.$api.auth
        .login(this.signin)
        .then((res) => {
          this.isLogin = false
          this.$message.success({ content: res.msg, key: 'msg' })
          //存入缓存token
          localStorage.setItem(this.$Const.KEY_TOKEN, res.data.token)

          //连接socket
          // this.$socket.open()

          //存入缓存
          let info = res.data
          delete info.token
          localStorage.setItem(this.$Const.KEY_INFO, JSON.stringify(info))

          setTimeout(() => {
            that.onClose()
            //回调
            that.$emit('tokenStatus', [])
          }, 1000)
        })
        .catch(() => {
          this.isLogin = false
        })
    },

    /**
     * 输入框验证
     */
    validParam(tips = false) {
      //邮箱未输入
      if (this.signup.email.length <= 0) {
        tips && this.$message.warning(this.$t('_zc._sryxdz'))
        return false
      }

      //无效的邮箱
      if (!this.$common.checkEmail(this.signup.email)) {
        tips && this.$message.warning(this.$t('_zc._yxgscw'))
        return false
      }

      //密码未输入
      if (this.signup.passwd.length <= 0) {
        tips && this.$message.warning(this.$t('_zc._qsrmm'))
        return false
      }

      //两次密码相等
      if (this.signup.passwd != this.signup.qr_passwd) {
        tips && this.$message.warning(this.$t('_zc._lcmmbxd'))
        return false
      }

      if (this.signup.type == USER.Store) {
        //邀请码
        if (this.signup.inv_code.length <= 0) {
          tips && this.$message.warning(this.$t('_zc._qsryqm'))
          return false
        }

        if (this.signup.store_name.length <= 0) {
          tips && this.$message.warning(this.$t('_zc._srdpm'))
          return false
        }
      }

      return true
    },
    /**
     * 滑条验证成功
     */
    onVerifySuccess() {
      if (!this.validParam()) {
        this.$refs.verify.reset()
        return
      }

      //滑条验证成功
      this.isVerify = true
    },
    /**
     * 下一步
     */
    onNextSendEmail() {
      if (!this.validParam(true)) {
        return
      }

      //滑动验证未通过
      if (!this.isVerify) {
        this.$message.warning(this.$t('_zc._hdyzwtg'))
        return
      }

      //验证
      let email = this.$common.rmSpaces(this.signup.email)
      let key = this.$md5(email)

      this.$message.loading({ content: 'loading...', key: 'msg' })

      let that = this
      this.$api.auth
        .checkEmail({
          email: email,
          inv_code: this.signup.inv_code,
          type: this.signup.type
        })
        .then(() => {
          this.$message.destroy()
          countdown.run(key, that.CallTime)
          if (that.signup.type == USER.Store) {
            that.signupStep = STEP.Code
          } else if (that.signup.type == USER.User) {
            that.signupStep = STEP.Auth
          }
        })
        .catch(() => {})
    },
    onFrontUpload(img) {
      this.imageFrontUrl = img
    },
    onBackUpload(img) {
      this.imageBackUrl = img
    },
    onNextSendEmail2() {
      this.signupStep = STEP.Code
    },
    onPrevSignup() {
      this.signupStep = STEP.Email
      this.isVerify = false
    },
    /**
     * 发送验证码
     */
    onSendCode() {
      if (!this.hasSend) {
        return
      }
      let email = this.$common.rmSpaces(this.signup.email)
      let key = this.$md5(email)

      this.$message.loading({ content: 'loading...', key: 'msg' })

      let that = this
      this.$api.code
        .sendCode({
          email: email,
          scene: 'signup'
        })
        .then((res) => {
          that.$message.success({
            content: this.$t('base.send.success') /* + ",code:" + res.data.code*/,
            key: 'msg'
          })
          countdown.start(key, res.data.exp)
          countdown.run(key, that.CallTime)
        })
    },
    /**
     * 倒计时回调
     * @param {*} time
     */
    CallTime(time) {
      console.log(time)
      if (time == 0) {
        this.hasSend = true
        this.send_code_text = this.$t('base.send.code')
      } else {
        this.hasSend = false
        this.send_code_text = this.$t('base.send.time', {
          time: time
        })
      }
    },
    /**
     * 注册
     */
    onSignup() {
      if (this.isLogin) {
        return
      }
      this.isLogin = true

      let email = this.$common.rmSpaces(this.signup.email)
      let data = {
        type: this.signup.type,
        email: email,
        passwd: this.signup.passwd,
        code: this.signup.code,
        inv_code: this.signup.inv_code,
        name: this.signup.name,
        idcard: this.signup.idcard,
        front: this.imageFrontUrl,
        back: this.imageBackUrl,
        store_address: this.signup.store_address,
        store_name: this.signup.store_name
      }

      this.$message.loading({ content: 'loading...', key: 'msg' })

      let that = this
      this.$api.auth
        .signup(data)
        .then(() => {
          this.isLogin = false
          that.$message.success({
            content: this.$t('signup.success'),
            key: 'msg'
          })
          setTimeout(function () {
            countdown.clear()
            if (that.signup.type == 1) {
              that.onShow(email)
            } else if (that.signup.type == 2) {
              that.isShow = false
              that.$router.push({ name: 'storeSignin' })
            }
          }, 2000)
        })
        .catch(() => {
          this.isLogin = false
        })
    }
  }
}
</script>

<style scoped>
@import url('@/static/css2/signin.css');
</style>
