<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="ui-icons-sprite">
    <symbol id="arrow_long_left" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.63 3.656a1.75 1.75 0 0 1 2.24 2.688c-1.29 1.076-2.808 2.57-4.099 3.906H21.75a1.75 1.75 0 1 1 0 3.5H8.77c1.291 1.335 2.808 2.83 4.1 3.906a1.75 1.75 0 0 1-2.242 2.688C9.05 19.028 7.244 17.22 5.86 15.772a116.64 116.64 0 0 1-2.233-2.402c-.755-.84-.779-1.875 0-2.74l.504-.554c.428-.468 1.03-1.118 1.73-1.848 1.383-1.447 3.19-3.256 4.768-4.572Z" /></symbol>
    <symbol id="arrow_long_right" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.922 3.656a1.75 1.75 0 1 0-2.24 2.688c1.29 1.076 2.807 2.57 4.098 3.906H4.802a1.75 1.75 0 0 0 0 3.5H17.78c-1.29 1.335-2.808 2.83-4.099 3.906a1.75 1.75 0 1 0 2.241 2.688c1.58-1.316 3.385-3.125 4.77-4.572a116.74 116.74 0 0 0 2.232-2.402c.756-.84.78-1.875 0-2.74-.118-.13-.289-.32-.503-.554a116.829 116.829 0 0 0-1.73-1.848c-1.384-1.447-3.19-3.256-4.769-4.572Z" /></symbol>
    <symbol id="arrow_short_left" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.013 2.76a2.01 2.01 0 0 0-2.906-.254c-1.687 1.48-3.468 3.236-4.842 4.78-.684.768-1.301 1.52-1.76 2.185-.229.33-.449.68-.62 1.031-.14.286-.385.84-.385 1.498 0 .458.124.834.202 1.039.09.234.2.45.302.631.206.365.47.75.747 1.12.56.752 1.3 1.622 2.067 2.471 1.514 1.675 3.327 3.477 4.371 4.302.907.716 2.201.533 2.89-.41.69-.942.513-2.286-.394-3.002-.764-.604-2.388-2.195-3.86-3.823-.725-.802-1.365-1.562-1.812-2.161a11.567 11.567 0 0 1-.15-.207c.32-.461.808-1.065 1.43-1.764 1.248-1.402 2.905-3.038 4.475-4.416.87-.763.98-2.115.245-3.02Zm-6.47 9.73.013-.032a.175.175 0 0 0-.013.031Z" /></symbol>
    <symbol id="arrow_short_bottom" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.24 7.487a2.01 2.01 0 0 1 .254 2.906c-1.48 1.687-3.236 3.468-4.78 4.842-.768.684-1.52 1.301-2.185 1.76-.33.229-.68.449-1.031.62-.286.14-.84.385-1.498.385-.458 0-.834-.124-1.039-.202-.234-.09-.45-.2-.631-.302a10.91 10.91 0 0 1-1.12-.747c-.752-.56-1.622-1.3-2.471-2.067-1.675-1.514-3.477-3.327-4.302-4.371-.716-.907-.533-2.201.41-2.89.942-.69 2.286-.513 3.002.394.604.764 2.195 2.388 3.823 3.86.802.725 1.562 1.365 2.161 1.812.074.055.143.105.207.15.461-.32 1.065-.808 1.764-1.43 1.402-1.248 3.038-2.905 4.416-4.475.764-.87 2.115-.98 3.02-.245Zm-9.73 6.47.032-.013a.172.172 0 0 1-.031.013Z" /></symbol>
    <symbol id="arrow_short_right" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.987 2.76a2.01 2.01 0 0 1 2.906-.254c1.687 1.48 3.468 3.236 4.842 4.78.684.768 1.301 1.52 1.76 2.185.229.33.449.68.62 1.031.14.286.385.84.385 1.498 0 .458-.124.834-.202 1.039-.09.234-.2.45-.302.631-.206.365-.47.75-.747 1.12-.56.752-1.3 1.622-2.067 2.471-1.514 1.675-3.327 3.477-4.371 4.302-.907.716-2.201.533-2.89-.41-.69-.942-.513-2.286.394-3.002.764-.604 2.388-2.195 3.86-3.823.725-.802 1.365-1.562 1.812-2.161.055-.074.105-.143.15-.207-.32-.461-.808-1.065-1.43-1.764C11.46 8.794 9.802 7.158 8.232 5.78c-.87-.763-.98-2.115-.245-3.02Zm6.47 9.73-.013-.032.013.031Z" /></symbol>
    <symbol id="arrow_short_top" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.76 16.513a2.01 2.01 0 0 1-.254-2.906c1.48-1.687 3.236-3.468 4.78-4.842.768-.684 1.52-1.301 2.185-1.76.33-.229.68-.449 1.031-.62.286-.14.84-.385 1.498-.385.458 0 .834.124 1.039.202.234.09.45.2.631.302.365.206.75.47 1.12.747.752.56 1.622 1.3 2.471 2.067 1.675 1.514 3.477 3.327 4.302 4.371.716.907.533 2.201-.41 2.89-.942.69-2.286.513-3.002-.394-.604-.764-2.195-2.388-3.823-3.86-.802-.725-1.562-1.365-2.161-1.812a12.152 12.152 0 0 0-.207-.15c-.461.32-1.065.808-1.764 1.43-1.402 1.248-3.038 2.905-4.416 4.475-.763.87-2.115.98-3.02.245Zm9.73-6.47-.032.013a.172.172 0 0 1 .031-.013Z" /></symbol>
    <symbol id="smile" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.084 3.228a3.123 3.123 0 0 1 0 4.419 3.123 3.123 0 0 1-4.418 0 3.123 3.123 0 0 1 0-4.419 3.123 3.123 0 0 1 4.418 0ZM8.25 5.438a3.125 3.125 0 1 1-6.25 0 3.125 3.125 0 0 1 6.25 0ZM2.742 18.254c.128.141 3.217 3.434 8.946 3.434 5.605 0 9.215-3.147 9.365-3.28.882-.783 1.49-1.764.226-3.068-1.262-1.307-2.027-.952-2.969-.235-.026.02-3.035 2.23-6.622 2.23-3.588 0-5.778-1.975-5.906-2.092-.87-.784-1.692-1.38-2.962-.065-1.27 1.313-.878 2.205-.078 3.076Z" /></symbol>
    <symbol id="bell" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.056 14.991c-.315-.523-.624-1.037-.14-2.726 1.432-4.994.219-6.948-1.754-7.514 0 0-.245-.045-.178-.281.175-.61.012-2.017-1.3-2.394-1.313-.376-2.197.73-2.372 1.34-.068.237-.3.145-.3.145-1.973-.566-4.037.449-5.47 5.443-.484 1.689-1.018 1.96-1.562 2.238-.566.287-1.142.58-1.685 2.473-.52 1.815 3.582 3.759 6.716 4.657 3.133.899 7.642 1.425 8.163-.39.542-1.893.209-2.447-.118-2.99ZM11.62 20.8a.483.483 0 0 0-.27-.703l-3.387-.971a.484.484 0 0 0-.604.453c-.04 1.062.61 2.043 1.636 2.337 1.026.294 2.096-.194 2.625-1.116Z" /></symbol>
    <symbol id="attention" viewBox="0 0 24 24">
      <g clip-path="url(#clip0_5121_8736)"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.86 16.157c-1.465-2.526-5.164-8.747-8.068-12.4C11.888.102 10.167.434 7.478 4.31S2.315 13.5.702 17.153C-.912 20.807.164 22.8 4.897 22.8c4.732 0 9.035-.11 14.735-.443 5.701-.332 4.691-3.674 3.227-6.2ZM11.534 7.2c1.976.042 1.785 1.959 1.46 3.743-.044.241-.083.482-.12.718-.24 1.505-.445 2.8-1.893 2.756-1.518-.045-1.415-1.606-1.307-3.246l.032-.507c.013-.24.02-.477.028-.71.047-1.519.086-2.79 1.8-2.754Zm-.604 8.695c1.116.193 1.156.706 1.104 1.192-.053.486-.233.994-1.327.9-1.095-.093-1.123-.584-1.105-1.116.017-.532.212-1.17 1.328-.976Z" /></g>
      <defs>
        <clipPath id="clip0_5121_8736"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath>
      </defs>
    </symbol>
    <symbol id="cart" viewBox="0 0 24 24"><path d="M20.219 6.63h-9.735c-.522 0-.981-.344-1.132-.845l-.885-2.94A1.184 1.184 0 0 0 7.335 2H3.183C2.53 2 2 2.532 2 3.187v1.019c0 .655.53 1.187 1.183 1.187h1.64c.522 0 .982.343 1.133.846l2.447 8.178a2.645 2.645 0 0 0 3.032 1.855c1.21-.23 2.797-.574 4.544-1.056 1.984-.548 3.431-1.163 4.367-1.628a2.832 2.832 0 0 0 1.549-2.204A14.01 14.01 0 0 0 22 9.738c0-.582-.046-1.147-.104-1.632A1.683 1.683 0 0 0 20.22 6.63ZM7.598 19.88c0 1.171.947 2.12 2.113 2.12a2.117 2.117 0 0 0 2.113-2.12c0-1.171-.947-2.12-2.113-2.12a2.117 2.117 0 0 0-2.113 2.12ZM18.373 22a2.117 2.117 0 0 1-2.113-2.12c0-1.171.947-2.12 2.113-2.12s2.113.949 2.113 2.12c0 1.171-.947 2.12-2.113 2.12Z" /></symbol>
    <symbol id="check" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.35 8.6a2.11 2.11 0 0 0-2.784-.185 4.323 4.323 0 0 0-.594.547c-.307.337-.53.645-.69.92-.414.69-.359 1.55.081 2.224.555.845 1.17 1.658 2.463 3.017 1.574 1.65 2.602 2.607 3.575 3.33a3.028 3.028 0 0 0 3.629 0c.972-.723 2.302-1.833 3.902-3.462 2.26-2.298 5.205-5.516 5.76-6.362.441-.674.494-1.533.083-2.224a5.414 5.414 0 0 0-.69-.919 4.387 4.387 0 0 0-.593-.547 2.112 2.112 0 0 0-2.787.185l-7.49 7.527L6.35 8.6Z" /></symbol>
    <symbol id="cross" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.446 6.92a1.2 1.2 0 0 0 0-1.69l-.869-.876a1.2 1.2 0 0 0-1.703 0L15.66 5.579l-.012.011-3.735 3.83-5.008-4.953a1.2 1.2 0 0 0-1.703.015l-.861.883a1.2 1.2 0 0 0 .015 1.69l5.02 4.967-4.89 5.016a1.2 1.2 0 0 0 .015 1.69l.876.867a1.2 1.2 0 0 0 1.703-.015l4.88-5.004 5.134 5.08a1.2 1.2 0 0 0 1.703-.016l.861-.883a1.2 1.2 0 0 0-.015-1.69l-5.147-5.092 3.73-3.824 1.22-1.23Z" /></symbol>
    <symbol id="comments" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.266 5.543a4.228 4.228 0 0 1 1.815-1.694c.783-.39 1.558-.599 3.698-.599h6.442c2.14 0 2.916.209 3.699.599.781.39 1.396.963 1.815 1.694.419.73.64 1.453.64 3.45v.958c0 1.998-.221 2.723-.64 3.453a4.242 4.242 0 0 1-1.815 1.694c-.783.39-1.559.598-3.699.598H13.21l-4.466 4.799c-.493.529-1.38.181-1.38-.541v-4.302l-.008-1.346-1.438 1.12a4.721 4.721 0 0 1-.836-.329 4.235 4.235 0 0 1-1.815-1.693c-.417-.73-.641-1.455-.641-3.453v-.957c0-1.998.224-2.721.641-3.451Z" /></symbol>
    <symbol id="dislike" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 18.324c.225-1.568-.921-4.005 1.386-3.74 2.308.264 4-.575 4.24-1.507.241-.933-.244-1.61-.22-2.193.023-.582.854-.876.692-2.383-.163-1.506-1.063-1.275-1.2-2.014-.139-.74.607-.78.103-2.298s-3.61-2.162-7.221-1.867c-3.611.295-5.534 1.746-5.67 2.965-.137 1.22-.105 3.58-.049 4.996.056 1.416.967 2.604 1.876 3.779.91 1.174 2.098 1.686 2.117 3.328.02 1.641-1.246 3.665.732 4.264 1.978.6 2.988-1.761 3.213-3.33ZM6.245 7.261c-.07-2.187.158-4.382-1.932-4.386-2.09-.005-2.18 2.58-2.004 5.385.176 2.806.196 3.609 2.113 3.737 1.917.128 1.893-2.55 1.823-4.736Z" /></symbol>
    <symbol id="expand" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m19.25 6.733-3.752 3.781a.793.793 0 0 1-1.128 0l-.886-.891a.805.805 0 0 1 0-1.135l3.686-3.716-2.94.069a.959.959 0 0 1-.986-.862 2.704 2.704 0 0 1 .081-.936 1.06 1.06 0 0 1 .794-.742c.475-.11 2.556-.245 4.095-.292 1.09-.032 1.91.025 2.481.098.635.08 1.118.567 1.2 1.206.07.575.13 1.4.095 2.496-.046 1.55-.18 3.625-.287 4.104a1.06 1.06 0 0 1-.74.798 2.628 2.628 0 0 1-.93.082.96.96 0 0 1-.855-.99l.071-3.07ZM4.75 17.267l3.752-3.781a.793.793 0 0 1 1.128 0l.886.891a.805.805 0 0 1 0 1.135L6.83 19.228l2.94-.069a.959.959 0 0 1 .986.862 2.704 2.704 0 0 1-.081.936 1.06 1.06 0 0 1-.794.742c-.475.11-2.556.245-4.095.292-1.09.032-1.91-.025-2.481-.098a1.373 1.373 0 0 1-1.2-1.206c-.07-.575-.13-1.4-.095-2.496.046-1.549.18-3.625.287-4.104a1.06 1.06 0 0 1 .74-.797 2.627 2.627 0 0 1 .93-.083.96.96 0 0 1 .855.99l-.071 3.07Z"
      />
    </symbol>
    <symbol id="filter" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 6.375a1.375 1.375 0 1 1 2.75 0 1.375 1.375 0 0 1-2.75 0ZM4.921 4.5a4.375 4.375 0 0 1 7.908 0h7.296a1.875 1.875 0 0 1 0 3.75h-7.296a4.376 4.376 0 0 1-7.908 0H3.875a1.875 1.875 0 0 1 0-3.75h1.046ZM15.125 22a4.376 4.376 0 0 0 3.954-2.5h1.046a1.875 1.875 0 0 0 0-3.75h-1.046a4.376 4.376 0 0 0-7.908 0H3.875a1.875 1.875 0 0 0 0 3.75h7.296a4.375 4.375 0 0 0 3.954 2.5Zm0-5.75a1.375 1.375 0 1 0 0 2.75 1.375 1.375 0 0 0 0-2.75Z" /></symbol>
    <symbol id="lock" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.55 3.77c.914-.184 1.713.025 2.305.617.617.616 1.168 1.81 1.186 3.894 0 .028.001.055.003.083-.475.045-.98.095-1.502.15l-3.866.4c-.122-1.136-.228-2.248-.045-3.206.102-.54.286-.947.553-1.245.258-.287.665-.553 1.367-.694ZM6.44 9.176c-.125-1.132-.267-2.593-.02-3.891.155-.808.47-1.635 1.09-2.325.628-.7 1.494-1.176 2.598-1.398 1.532-.307 3.132.028 4.337 1.232 1.168 1.166 1.812 2.996 1.845 5.392.687-.036 1.203-.037 1.48.013 1.044.187 1.53.595 1.798 3.189.097.934.226 1.823.344 2.64.179 1.237.4 2.488.272 3.74-.134 1.314-.316 3.09-2.414 3.308a54.97 54.97 0 0 0-2.16.286c-1.831.266-4.055.588-6.992.664-2.232.057-2.84-.36-3.42-3.25-.415-2.065-.512-3.842-.584-5.16-.028-.514-.052-.959-.09-1.323-.123-1.193-.04-2.684 1.916-3.117Zm6.608 5.815c.007.07.114.29.256.58.364.747.956 1.96.639 2.27-.242.235-2.699.791-3.231.335-.378-.325.065-1.571.344-2.358.114-.322.2-.567.193-.64-.01-.099-.117-.244-.248-.423-.208-.282-.475-.646-.516-1.033-.065-.633.203-1.557 1.402-1.68 1.199-.125 1.518.61 1.583 1.242.06.577-.149 1.02-.294 1.328-.078.164-.137.29-.128.379Z"
      />
    </symbol>
    <symbol id="like" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.676c.225 1.568-.921 4.005 1.386 3.74 2.308-.264 4 .575 4.24 1.507.241.933-.244 1.61-.22 2.193.023.582.854.876.692 2.383-.163 1.506-1.063 1.275-1.2 2.014-.139.74.607.78.103 2.298s-3.61 2.162-7.221 1.867c-3.611-.295-5.534-1.746-5.67-2.965-.137-1.22-.105-3.58-.049-4.996.056-1.416.967-2.604 1.876-3.779.91-1.174 2.098-1.686 2.117-3.328.02-1.641-1.246-3.664.732-4.264 1.978-.6 2.988 1.761 3.213 3.33ZM6.245 16.739c-.07 2.187.158 4.382-1.932 4.386-2.09.005-2.18-2.58-2.004-5.385.176-2.806.196-3.609 2.113-3.737 1.917-.128 1.893 2.55 1.823 4.736Z" /></symbol>
    <symbol id="minimize" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m15.984 6.072 3.75-3.78a.793.793 0 0 1 1.127 0l.888.89a.807.807 0 0 1-.002 1.137l-3.686 3.715 2.942-.07a.96.96 0 0 1 .985.862c.012.119.017.248.007.389a2.76 2.76 0 0 1-.089.547 1.064 1.064 0 0 1-.793.744c-.476.108-2.557.245-4.096.29a15.879 15.879 0 0 1-2.48-.097 1.37 1.37 0 0 1-1.198-1.207c-.073-.573-.13-1.4-.098-2.496.046-1.549.18-3.625.288-4.104.087-.378.365-.695.74-.797a2.65 2.65 0 0 1 .93-.081.959.959 0 0 1 .855.99l-.07 3.068ZM8.016 17.927l-3.75 3.782a.793.793 0 0 1-1.127 0l-.888-.892a.807.807 0 0 1 .001-1.136l3.687-3.715-2.942.069a.96.96 0 0 1-.985-.861 2.199 2.199 0 0 1-.007-.39c.015-.217.047-.398.089-.547.102-.376.416-.656.793-.743.475-.108 2.557-.245 4.095-.29 1.09-.034 1.91.025 2.482.097a1.37 1.37 0 0 1 1.197 1.206c.073.574.13 1.4.098 2.497-.046 1.548-.18 3.625-.288 4.103a1.064 1.064 0 0 1-.74.798 2.65 2.65 0 0 1-.93.081.959.959 0 0 1-.855-.99l.07-3.069Z"
      />
    </symbol>
    <symbol id="menu" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 7h17A1.5 1.5 0 0 0 22 5.5v-.75a1.5 1.5 0 0 0-1.5-1.5h-17A1.5 1.5 0 0 0 2 4.75v.75A1.5 1.5 0 0 0 3.5 7Zm9.5 6.875H3.5a1.5 1.5 0 0 1-1.5-1.5v-.75a1.5 1.5 0 0 1 1.5-1.5H13a1.5 1.5 0 0 1 1.5 1.5v.75a1.5 1.5 0 0 1-1.5 1.5Zm7.5 6.875h-17a1.5 1.5 0 0 1-1.5-1.5v-.75A1.5 1.5 0 0 1 3.5 17h17a1.5 1.5 0 0 1 1.5 1.5v.75a1.5 1.5 0 0 1-1.5 1.5Z" /></symbol>
    <symbol id="minus" viewBox="0 0 24 24"><path d="M12 15c4.667 0 7 0 7-3.333C19 9 16.667 9 12 9s-7 .533-7 2.667C5 14.333 5.73 15 12 15Z" /></symbol>
    <symbol id="order_ascending" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.029 3.207C2 3.352 2 3.527 2 3.875c0 .348 0 .523.029.668A1.5 1.5 0 0 0 3.207 5.72c.145.029.32.029.668.029h2.5c.348 0 .523 0 .668-.029A1.5 1.5 0 0 0 8.22 4.543c.029-.145.029-.32.029-.668 0-.348 0-.523-.029-.668A1.5 1.5 0 0 0 7.043 2.03C6.898 2 6.723 2 6.375 2h-2.5c-.348 0-.523 0-.668.029A1.5 1.5 0 0 0 2.03 3.207ZM2 12c0-.348 0-.523.029-.668a1.5 1.5 0 0 1 1.178-1.178c.145-.029.32-.029.668-.029h8.75c.348 0 .523 0 .668.029a1.5 1.5 0 0 1 1.178 1.178c.029.145.029.32.029.668 0 .348 0 .523-.029.668a1.5 1.5 0 0 1-1.178 1.178c-.145.029-.32.029-.668.029h-8.75c-.348 0-.523 0-.668-.029a1.5 1.5 0 0 1-1.178-1.178C2 12.523 2 12.348 2 12Zm0 8.125c0-.349 0-.523.029-.668a1.5 1.5 0 0 1 1.178-1.178c.145-.029.32-.029.668-.029h16.25c.349 0 .523 0 .668.029a1.5 1.5 0 0 1 1.178 1.178c.029.145.029.32.029.668 0 .349 0 .523-.029.668a1.5 1.5 0 0 1-1.178 1.178c-.145.029-.32.029-.668.029H3.875c-.348 0-.523 0-.668-.029a1.5 1.5 0 0 1-1.178-1.178C2 20.648 2 20.473 2 20.125Z"
      />
    </symbol>
    <symbol id="order_descending" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.029 20.793C2 20.648 2 20.473 2 20.125c0-.349 0-.523.029-.668a1.5 1.5 0 0 1 1.178-1.178c.145-.029.32-.029.668-.029h2.5c.348 0 .523 0 .668.029a1.5 1.5 0 0 1 1.178 1.178c.029.145.029.32.029.668 0 .349 0 .523-.029.668a1.5 1.5 0 0 1-1.178 1.178c-.145.029-.32.029-.668.029h-2.5c-.348 0-.523 0-.668-.029a1.5 1.5 0 0 1-1.178-1.178ZM2 12c0 .348 0 .523.029.668a1.5 1.5 0 0 0 1.178 1.178c.145.029.32.029.668.029h8.75c.348 0 .523 0 .668-.029a1.5 1.5 0 0 0 1.178-1.178c.029-.145.029-.32.029-.668 0-.348 0-.523-.029-.668a1.5 1.5 0 0 0-1.178-1.178c-.145-.029-.32-.029-.668-.029h-8.75c-.348 0-.523 0-.668.029a1.5 1.5 0 0 0-1.178 1.178C2 11.477 2 11.652 2 12Zm0-8.125c0 .348 0 .523.029.668A1.5 1.5 0 0 0 3.207 5.72c.145.029.32.029.668.029h16.25c.349 0 .523 0 .668-.029a1.5 1.5 0 0 0 1.178-1.178c.029-.145.029-.32.029-.668 0-.348 0-.523-.029-.668a1.5 1.5 0 0 0-1.178-1.178C20.648 2 20.473 2 20.125 2H3.875c-.348 0-.523 0-.668.029A1.5 1.5 0 0 0 2.03 3.207C2 3.352 2 3.527 2 3.875Z"
      />
    </symbol>
    <symbol id="plus" viewBox="0 0 24 24"><path d="M11.97 4c-2.383 0-2.248.802-2.248 2.24 0 2.76.278 3.586-2.898 3.586-.47 0-2.824-.324-2.824 2.308 0 2.41 2.234 2.03 2.704 2.03 3.018 0 3.018 0 3.018 3.164 0 .482-.43 2.672 2.388 2.672s2.283-3.293 2.283-3.775c0-2.225.107-2.06 3.183-2.06 1.383 0 2.424.303 2.424-2.155 0-2.458-1.954-2.184-2.424-2.184-3.183 0-3.323.174-3.323-3.482 0-1.666 0-2.344-2.283-2.344Z" /></symbol>
    <symbol id="profile" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.7 6.935c-.045 3.242 1.872 5.485 4.486 5.54 2.862.06 5.007-1.893 5.07-5.458.06-3.453-1.907-4.999-4.743-5.017-2.56-.016-4.772 1.86-4.814 4.935Zm4.438 7.446c-5.765-.002-8.448 2.97-8.253 4.5.195 1.53.787 2.915 7.839 3.1 7.051.185 8.204-.997 8.39-2.528.188-1.532-2.21-5.07-7.976-5.072Z" /></symbol>
    <symbol id="sale" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.167 5.54c0 1.917-1.6 3.47-3.574 3.47-1.975 0-3.574-1.553-3.574-3.47 0-1.92 1.6-3.473 3.574-3.473 1.974 0 3.574 1.554 3.574 3.472Zm11.828-2.01-.53-.53a1.874 1.874 0 0 0-2.65 0L2.964 17.85a1.874 1.874 0 0 0 0 2.651l.53.53c.733.733 1.92.733 2.653 0L20.995 6.183a1.876 1.876 0 0 0 0-2.653Zm-2.588 11.46c-1.973 0-3.574 1.553-3.574 3.47 0 1.92 1.601 3.473 3.574 3.473 1.974 0 3.574-1.554 3.574-3.473 0-1.917-1.6-3.47-3.574-3.47Z" /></symbol>
    <symbol id="search" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.344 14.913h-.017c-1.148-.005-2.293-.529-3.225-1.475-1.897-1.923-1.872-4.413.065-6.341.925-.922 2-1.409 3.112-1.409h.018c1.147.005 2.293.527 3.225 1.47 1.895 1.925 1.87 4.414-.065 6.343-.927.923-2.003 1.412-3.113 1.412Zm11.281 3.99-3.49-3.491c-.3-.3-.68-.446-1.07-.505 2.34-3.248 2.04-7.314-.927-10.325C14.507 2.927 12.434 2.011 10.307 2h-.034c-2.091 0-4.061.86-5.696 2.493-3.402 3.389-3.44 8.127-.093 11.523 1.635 1.657 3.705 2.576 5.831 2.585h.034c1.629 0 3.185-.533 4.57-1.539.06.386.206.764.504 1.061l3.491 3.491c.5.5 1.309.5 1.808 0l.903-.903a1.279 1.279 0 0 0 0-1.808Z" /></symbol>
    <symbol id="pin" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.9 21.496C13.47 22.32 13.112 23 12.444 23c-.667 0-1.024-.68-1.455-1.504-.496-.946-1.09-2.08-2.367-2.59-2.389-.956-5.733-2.867-5.733-8.6C2.889 4.572 6.71.75 12.428.75 18.144.75 22 4.572 22 10.306c0 5.733-3.344 7.644-5.733 8.6-1.277.51-1.871 1.644-2.367 2.59ZM12.648 14c2.37 0 3.852-2.124 3.852-3.987s-1.482-4.293-4.148-3.987C9.685 6.333 8.5 8.173 8.5 10.32S10.278 14 12.648 14Z" /></symbol>
    <symbol id="video" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 12.635c0-3.669.228-6.246.486-7.995.285-1.921 2.128-3.101 3.862-2.468 1.486.543 3.51 1.493 6.084 3.155 3.02 1.949 4.987 3.737 6.21 5.055 1.17 1.263 1.143 3.283-.08 4.49-1.227 1.215-3.176 2.783-6.13 4.329-2.762 1.445-4.89 2.245-6.402 2.688-1.682.492-3.4-.7-3.659-2.53-.207-1.468-.371-3.613-.371-6.724Z" /></symbol>
    <symbol id="socials_fb" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.952 22v-9.071H7V9.348h2.952v-3.37c0-1.657 1.536-3.722 3.614-3.919 1.927-.182 3.434.114 3.434.114v3.31h-2.187c-.689 0-1.247.583-1.247 1.3v2.565h3.313l-.512 3.58H13.54V22H9.952Z" /></symbol>
    <symbol id="socials_ok" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.026 6.94A2.027 2.027 0 0 0 12 4.914a2.027 2.027 0 0 0-2.026 2.024c0 1.116.909 2.024 2.026 2.024a2.027 2.027 0 0 0 2.026-2.024Zm2.918 0A4.947 4.947 0 0 1 12 11.878a4.947 4.947 0 0 1-4.944-4.94A4.947 4.947 0 0 1 12 2a4.947 4.947 0 0 1 4.944 4.94Zm-1.57 5.935a1.43 1.43 0 0 1 2.018.45c.437.7.242 1.636-.435 2.088a8.806 8.806 0 0 1-2.9 1.26l2.652 2.745c.57.59.57 1.546 0 2.136-.57.59-1.494.59-2.063 0l-2.644-2.737-2.648 2.74A1.43 1.43 0 0 1 8.323 22a1.43 1.43 0 0 1-1.032-.442 1.549 1.549 0 0 1 0-2.137l2.682-2.776a8.81 8.81 0 0 1-2.959-1.35c-.666-.47-.84-1.409-.387-2.1.453-.69 1.36-.869 2.027-.4a5.922 5.922 0 0 0 6.721.08Z" /></symbol>
    <symbol id="socials_vk" viewBox="0 0 24 24">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.541 6.554c.14-.463 0-.804-.662-.804h-2.188c-.556 0-.812.294-.951.619 0 0-1.113 2.712-2.69 4.474-.51.51-.741.672-1.02.672-.138 0-.34-.162-.34-.626V6.554c0-.556-.161-.804-.625-.804H9.627c-.348 0-.557.258-.557.503 0 .527.788.649.87 2.132v3.223c0 .706-.128.834-.406.834-.742 0-2.546-2.724-3.617-5.841-.21-.606-.42-.851-.979-.851H2.75c-.625 0-.75.294-.75.619 0 .58.742 3.454 3.454 7.255 1.808 2.596 4.355 4.004 6.673 4.004 1.391 0 1.563-.313 1.563-.851v-1.962c0-.625.132-.75.572-.75.325 0 .881.162 2.18 1.414 1.483 1.483 1.728 2.149 2.562 2.149h2.188c.625 0 .938-.313.757-.93-.197-.614-.905-1.506-1.845-2.564-.51-.603-1.275-1.251-1.507-1.576-.325-.417-.232-.603 0-.974 0 0 2.666-3.755 2.944-5.03Z" />
    </symbol>
    <symbol id="socials_youtube" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.66 14.737v-5.93l5.898 2.976-5.897 2.954ZM22.61 7.78s-.213-1.454-.867-2.095c-.83-.84-1.76-.844-2.187-.893-3.055-.214-7.637-.214-7.637-.214h-.01s-4.581 0-7.636.214c-.427.049-1.357.053-2.187.893-.655.641-.868 2.095-.868 2.095S1 9.488 1 11.196v1.601c0 1.708.218 3.415.218 3.415s.213 1.455.868 2.095c.83.841 1.92.814 2.407.902 1.746.162 7.421.212 7.421.212s4.587-.006 7.642-.22c.426-.049 1.357-.053 2.187-.894.654-.64.868-2.095.868-2.095s.218-1.707.218-3.415v-1.601c0-1.708-.218-3.416-.218-3.416Z" /></symbol>
    <symbol id="socials_viber" viewBox="0 0 24 24">
      <path d="M20.398 2.34c-.601-.55-3.035-2.297-8.457-2.324 0 0-6.394-.38-9.511 2.449C.695 4.184.086 6.699.023 9.816c-.066 3.118-.148 8.957 5.536 10.54h.007l-.003 2.417s-.036.98.613 1.18c.789.242 1.25-.504 2-1.305.414-.441.984-1.085 1.414-1.582 3.887.325 6.879-.414 7.219-.523.785-.254 5.226-.816 5.949-6.656.746-6.024-.36-9.828-2.36-11.547Zm.66 11.113c-.613 4.879-4.214 5.184-4.878 5.395-.285.09-2.91.738-6.215.523 0 0-2.461 2.942-3.23 3.707-.118.117-.262.168-.356.14-.133-.03-.168-.183-.168-.413.004-.325.023-4.016.023-4.016s-.004 0 0 0c-4.812-1.32-4.53-6.293-4.476-8.894.054-2.606.55-4.739 2.015-6.172C6.41 1.359 11.84 1.71 11.84 1.71c4.586.02 6.785 1.387 7.293 1.848 1.691 1.433 2.555 4.863 1.926 9.894Z" />
      <path
        d="M12.402 13.746s.43.035.66-.246l.454-.566c.218-.278.746-.457 1.261-.172.387.218.762.457 1.125.71.34.25 1.043.829 1.043.829.336.277.41.687.184 1.12v.009c-.25.43-.57.82-.942 1.148l-.007.008c-.325.27-.645.422-.957.457a.567.567 0 0 1-.141.008 1.209 1.209 0 0 1-.406-.063l-.012-.011c-.48-.137-1.285-.473-2.629-1.207a16.1 16.1 0 0 1-2.207-1.442c-.348-.273-.68-.566-.992-.875l-.035-.031-.031-.035-.036-.032a.253.253 0 0 1-.03-.035 11.05 11.05 0 0 1-.884-.98 15.656 15.656 0 0 1-1.457-2.188c-.742-1.328-1.082-2.125-1.218-2.605l-.012-.008a1.172 1.172 0 0 1-.063-.402.567.567 0 0 1 .008-.14c.04-.31.192-.626.461-.946l.008-.008a4.708 4.708 0 0 1 1.16-.934h.008c.437-.226.851-.152 1.133.18 0 0 .586.695.836 1.035.257.36.496.73.718 1.114.286.511.106 1.03-.175 1.246l-.57.449c-.286.23-.25.656-.25.656s.843 3.16 3.995 3.957ZM17.65 10.89c-.01-1.92-.56-3.66-1.76-4.76-1.21-1.29-2.91-1.84-4.65-1.83-.32.07-.25.59.01.59.45.01 1.47.13 1.46.15 3.19.73 4.38 3.43 4.35 5.86.02.28.54.38.59-.01Z"
      />
      <path d="M11.81 5.87c2.19.03 4.29 1.46 4.25 4.36-.02.31-.54.45-.62.01-.06-2.72-1.89-3.74-3.62-3.76-.34-.07-.32-.52-.01-.61Z" />
      <path d="M12.23 7.51c1.28-.1 2.25.81 2.24 2.24-.05.35-.58.28-.6.01.04-.86-.36-1.6-1.65-1.66-.28-.11-.26-.53.01-.59Z" />
    </symbol>
    <symbol id="socials_telegram" viewBox="0 0 24 24"><path d="M2.376 11.319c5.368-2.4 8.947-3.982 10.738-4.744C18.228 4.39 19.293 4.01 19.985 4c.152-.002.493.037.713.22.147.13.24.313.262.512.024.148.055.48.031.743-.277 2.987-1.476 10.234-2.086 13.58-.259 1.416-.766 1.889-1.26 1.937-1.068.1-1.88-.726-2.916-1.422-1.62-1.09-2.536-1.768-4.11-2.831-1.818-1.229-.639-1.905.397-3.008.27-.29 4.983-4.685 5.075-5.085.01-.05.022-.235-.087-.333-.107-.099-.266-.065-.38-.039-.163.04-2.752 1.795-7.768 5.268-.735.517-1.4.77-1.996.755-.66-.014-1.924-.38-2.864-.694-1.155-.386-2.071-.589-1.991-1.24.042-.342.499-.69 1.37-1.044Z" /></symbol>
    <symbol id="phone" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.544 15.861c-2.132-.468-5.716-4.167-5.914-5.62-.198-1.454 2.496-2.698 2.84-4.127.344-1.428-2.218-4.356-4.326-4.098-2.108.258-5.317 5.218-3.708 9.3 1.608 4.082 10.972 11.41 14.566 10.626 3.594-.783 5.286-3.835 4.958-5.295-.329-1.46-3.553-4.438-4.912-3.695-1.359.743-1.372 3.377-3.504 2.91Z" /></symbol>
    <symbol id="tooltip" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 10.904C.956 18.9 4.568 22.807 11.976 22.993c7.409.186 10.804-3.216 11.017-11.199C23.206 3.812 18.763 1 12.679 1S1.044 2.91 1 10.904Zm8.284-3.168c0 .722.604 1.074 1.007 1.074.268 0 .529-.083.813-.174.34-.11.712-.229 1.169-.229.621 0 1.108.336 1.108 1.041 0 .521-.269.974-1.125 1.763l-.134.124c-.597.546-1.192 1.092-1.192 1.623 0 .487.386.957.957.957.62 0 1.41-.588 2.737-1.981l.02-.022c.419-.436 1.457-1.516 1.457-3.034 0-1.63-1.091-2.99-3.492-2.99-1.109 0-2.15.42-2.72.857-.42.32-.605.621-.605.99Zm2.905 6.918c.94 0 1.578.823 1.578 1.578 0 .89-.638 1.713-1.578 1.713-1.075 0-1.713-.823-1.713-1.713 0-.755.638-1.578 1.713-1.578Z" /></symbol>
    <symbol id="receipt" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 2c1 0 1.5.5 2 1s1 1 2 1 1.5-.5 2-1 1-1 2-1 1.5.5 2 1 1 1 2 1 1.5-.5 2-1 1-1 2-1c2 0 2 20 0 20-1 0-1.5-.5-2-1s-1-1-2-1-1.5.5-2 1-1 1-2 1-1.5-.5-2-1-1-1-2-1-1.5.5-2 1-1 1-2 1C2 22 2 2 4 2Zm3 7.5A1.5 1.5 0 0 1 8.5 8h7a1.5 1.5 0 0 1 0 3h-7A1.5 1.5 0 0 1 7 9.5ZM8.5 13a1.5 1.5 0 1 0 0 3h4a1.5 1.5 0 0 0 0-3h-4Z" /></symbol>
    <symbol id="bonusCard" viewBox="0 0 24 24"><path d="M2.499 16.376C.219 11.73-.725 9.926 9.197 5.74 19.12 1.553 19.282 2.728 21.694 7.9c2.415 5.172 2.2 6.117-6.92 10.39-9.121 4.27-9.994 2.733-12.275-1.914Zm3.224-1.037c.73.794 1.368.9 2.223.123.856-.777.957-1.16.16-2.04-.798-.877-1.2-.92-2.19-.055-.99.868-.924 1.177-.193 1.972Zm3.133-2.653c.156 1.287.216 1.403 1.794 1.28 1.579-.125 1.403-.945 1.342-1.733-.058-.79-.26-1.278-1.537-1.16-1.276.117-1.755.324-1.599 1.613Zm4.143-2.992c-.819.734-1.2 1.047-.227 2.08.974 1.034 1.399.682 2.242-.016.845-.698.887-1.007.146-1.93-.739-.923-1.341-.867-2.16-.132l-.001-.002Zm3.016-.134c.242 1.367.43 1.507 1.885 1.302 1.455-.202 1.354-.713 1.25-1.747-.102-1.032-.575-1.342-1.643-1.192-1.07.15-1.734.268-1.492 1.636Z" /></symbol>
    <symbol id="trash" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 3a1 1 0 0 1 1-1h5.496a1 1 0 0 1 1 1v.5a1 1 0 0 1-1 1H9.25a1 1 0 0 1-1-1V3Zm7.498 14.329a.92.92 0 0 1-.92.921h-.66a.921.921 0 0 1-.92-.921V10.42a.92.92 0 0 1 .766-.907h.967a.92.92 0 0 1 .767.907v6.909ZM19.5 9.513V17c0 2.5-1.25 5-3.75 5h-7.5c-2.5 0-3.75-2.5-3.75-5V9.513H3.101a.957.957 0 0 1-.922-.678 4.194 4.194 0 0 1 .005-2.41.948.948 0 0 1 .917-.662H20.91c.422 0 .797.265.918.662.092.311.172.721.172 1.213 0 .482-.079.888-.169 1.198a.955.955 0 0 1-.921.677H19.5Zm-10.486 0a.92.92 0 0 0-.766.907v6.909c0 .508.412.921.92.921h.66a.92.92 0 0 0 .92-.921V10.42a.92.92 0 0 0-.767-.907h-.967Z" /></symbol>
    <symbol id="email" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.8 2.731C8.336 1.286 11.19 1 12.648 1c3.607 0 6.256 1.303 7.973 3.341C22.302 6.337 23 8.928 23 11.423c0 1.159-.086 2.604-.81 3.77a3.978 3.978 0 0 1-1.697 1.51c-.752.36-1.642.528-2.661.528-1.102 0-1.892-.63-2.395-1.309a5.218 5.218 0 0 1-.484-.805c-.16.257-.338.5-.537.725-.73.822-1.75 1.389-3.064 1.389-1.02 0-1.91-.169-2.662-.528a3.979 3.979 0 0 1-1.697-1.51c-.723-1.166-.81-2.611-.81-3.77 0-2.27 1.679-4.158 3.646-4.742 1.02-.303 2.185-.271 3.221.316.31.175.592.391.845.644.017-.312.04-.625.07-.937.059-.636.646-1.115 1.33-1.088.685.028 1.225.553 1.225 1.192 0 .79-.02 1.72-.096 2.691.03 1.262.175 2.43.418 3.378.203.796.456 1.357.703 1.69.12.162.212.235.26.264.01.007.019.01.024.013l.005.002c.72 0 1.186-.12 1.49-.265.29-.14.493-.33.649-.581.35-.565.466-1.428.466-2.577 0-2.12-.598-4.144-1.833-5.61-1.199-1.423-3.085-2.428-5.958-2.428-1.2 0-3.528.25-5.512 1.38-1.892 1.079-3.575 3.008-3.575 6.658 0 4.24 1.746 6.425 3.912 7.63 2.284 1.27 5.232 1.562 7.767 1.562.707 0 1.28.534 1.28 1.192 0 .66-.573 1.193-1.28 1.193-2.649 0-6.18-.286-9.08-1.9C3.142 19.422 1 16.415 1 11.424c0-4.508 2.173-7.195 4.8-8.692Zm7.8 8.687a1.238 1.238 0 0 1-.895-.847c-.266-.948-.679-1.362-.986-1.536-.3-.17-.674-.211-1.112-.081-.949.281-1.863 1.278-1.862 2.47 0 1.148.116 2.01.466 2.576.155.25.358.442.65.58.303.146.769.266 1.491.266.428 0 .763-.155 1.09-.523.36-.406.677-1.049.923-1.909.09-.318.169-.652.235-.996Z"
      />
    </symbol>
    <symbol id="pen" viewBox="0 0 24 24"><path d="M16 9c1.088 1.088 2.497 1.137 1.224 2.5-1.274 1.363-6.935 6.956-7.76 7.786a1.046 1.046 0 0 1-.216.173c-.842.607-4.63 1.637-4.847 1.685-.239.052-1.164-.108-.85-.94.282-.745 1.213-4.383 1.599-5.022a.505.505 0 0 1 .156-.259C5.99 14.251 12.436 8.035 13 7.5c1.173-1.115 2 .5 3 1.5Zm4.684-4.957c2.062 1.866.077 3.53-.802 4.424-.61.62-1.665.215-2.882-.967-1.152-1.12-2.416-1.716-.694-3.41 1.723-1.695 2.316-1.912 4.378-.047Z" /></symbol>
    <symbol id="more" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.63 14.238c1.6.027 2.615-1.033 2.608-2.775C6.231 9.721 4.985 9 3.608 9S1.026 9.972 1 11.52c-.026 1.546 1.03 2.69 2.63 2.718Zm8.37 0c1.384 0 2.619-1.046 2.619-2.433 0-1.387-.828-2.844-2.55-2.804-1.724.04-2.689.949-2.688 2.594 0 1.646 1.236 2.643 2.62 2.643Zm11-2.63c0 1.432-.84 2.632-2.492 2.63-1.652-.002-2.773-.934-2.746-2.605C17.79 9.96 18.87 9.004 20.516 9 22.164 8.996 23 10.174 23 11.607Z" /></symbol>
    <symbol id="box" viewBox="0 0 24 24">
      <path
        d="M15.139 9.484c-.488.25-1.25.605-1.875.907-.905.436-1.194.473-1.923.121-.73-.352-6.526-3.14-7.594-3.665-1.069-.524-.818-.836-.172-1.183.647-.346 1.355-.64 1.904-.906.549-.264 1.095-.416 1.96 0 .866.417 6.554 3.144 7.431 3.587.877.443.756.89.269 1.14Zm-4.923 12.39c-.7-.37-6.653-3.253-7.243-3.562-.59-.31-.976-.45-.973-2.069.003-1.618.011-7.217.006-7.797-.009-.958.3-1.23.84-.958s6.84 3.33 7.634 3.777c.648.365.946.365.946 2.114v7.383c0 1.113-.508 1.483-1.21 1.113Zm4.037-10.933c.862-.468 1.546-.746 2.052-.832 0 0 .058.855.058 1.764 0 .91-.145 1.922.718 1.6.863-.32.6-.913.6-1.8V9.424a44.831 44.831 0 0 1 2.835-1.363c1.228-.53 1.485.393 1.484 1.641-.002 1.25-.004 5.853-.004 6.677 0 1.448.005 1.748-1.238 2.411-1.243.663-5.896 2.746-6.467 2.957-.956.354-1.646.67-1.65-1.11-.002-.98-.01-4.197-.013-6.694-.004-2.497.331-2.3 1.625-3.002Zm5.886-3.957c-.488.25-1.25.605-1.875.907-.905.436-1.194.473-1.923.121-.73-.352-6.526-3.14-7.594-3.665-1.069-.524-.818-.836-.172-1.183.647-.346 1.355-.64 1.905-.906.549-.264 1.095-.416 1.96 0 .866.417 6.554 3.144 7.431 3.587.877.443.756.89.269 1.14h-.001Z"
      />
    </symbol>
    <symbol id="coupon" viewBox="0 0 24 24"><path d="m16.093 4.33-1.69-1.904s-.676-.762-1.24-.248c-.563.514-10.495 9.243-11.077 10.119-.582.877 1.972 3.372 1.972 3.372.507-.578 2.566-2.05 4.144-.272 1.58 1.779.079 3.314-.763 4.082.004.782 2.04 2.773 2.555 2.495.92-.496 11.453-10.463 11.829-10.805.375-.343.037-.725.037-.725L19.831 8.16c-.794.381-2.163 2.276-4.394.262-1.824-1.648.08-3.335.657-4.09h-.001Z" /></symbol>
    <symbol id="heartFill" viewBox="0 0 24 24"><path d="M22.109 8.74c-.071-1.72-.383-2.292-.56-2.626a5.194 5.194 0 0 0-1.89-2.03c-.8-.49-1.715-.75-2.65-.752a5.122 5.122 0 0 0-3.255 1.164 5.263 5.263 0 0 0-1.81 2.979 5.263 5.263 0 0 0-1.812-2.983 5.124 5.124 0 0 0-3.256-1.164 5.09 5.09 0 0 0-2.65.751 5.198 5.198 0 0 0-1.891 2.03c-.176.333-.489.907-.559 2.626-.015.297 0 .595.045.888.594 4.464 3.215 7.066 5.644 8.55a8.613 8.613 0 0 0 4.481 1.262 8.607 8.607 0 0 0 4.482-1.262c2.43-1.48 5.05-4.083 5.644-8.55a4.39 4.39 0 0 0 .037-.883Z" /></symbol>
    <symbol id="heartHollow" viewBox="0 0 24 24">
      <path
        d="M22.494 9.003v-.03c-.04-.82-.13-1.44-.26-1.94-.14-.52-.31-.84-.41-1.02-.52-1-1.29-1.83-2.24-2.42-.97-.58-2.06-.9-3.18-.9-1.42 0-2.79.49-3.88 1.39-.19.15-.35.33-.51.5-.17-.17-.33-.35-.52-.5-1.1-.9-2.47-1.39-3.89-1.39-1.12 0-2.21.31-3.17.9-.95.58-1.73 1.42-2.24 2.41-.1.19-.27.51-.41 1.03-.14.5-.23 1.12-.27 1.95v.02c-.02.35 0 .71.05 1.06v.02c.61 4.51 3.27 7.16 5.67 8.63 1.43.88 3.08 1.34 4.76 1.34 1.68 0 3.32-.47 4.76-1.34 2.4-1.47 5.07-4.12 5.68-8.63v-.02c.05-.35.06-.7.04-1.06h.02Zm-3.35.62c-.43 3.26-2.3 5.14-4.11 6.24a5.795 5.795 0 0 1-6.04 0c-1.81-1.11-3.68-2.99-4.12-6.24v-.03c-.03-.15-.03-.29-.03-.44v-.02c.03-.66.1-1.03.15-1.23.05-.17.09-.24.14-.35.24-.47.6-.86 1.04-1.12.43-.27.93-.41 1.43-.41.64 0 1.26.22 1.76.63.61.5 1.39 1.41 1.94 2.09.34.43.99.41 1.32-.03.53-.72 1.31-1.68 2-2.06a2.8 2.8 0 0 1 1.76-.64c.5 0 1 .14 1.43.41.43.27.79.65 1.03 1.12 0 0 .1.19.15.36.05.2.12.57.15 1.23v.03c0 .15 0 .29-.02.44v.02h.02Z"
      />
    </symbol>
    <symbol id="heartFillBg" viewBox="0 0 24 24">
      <path
        d="M24 8.563v-.036c-.046-.983-.149-1.726-.297-2.325a5.27 5.27 0 0 0-.47-1.223 7.323 7.323 0 0 0-2.56-2.9C19.562 1.384 18.316 1 17.035 1c-1.624 0-3.19.264-4.437 1.342-.217.18-.4.396-.583.6-.195-.204-.377-.42-.595-.6C10.163 1.264 8.597 1 6.973 1c-1.28 0-2.527.372-3.625 1.079A7.204 7.204 0 0 0 .786 4.967c-.114.228-.308.612-.468 1.235-.16.6-.263 1.342-.31 2.337v.024c-.022.42 0 .851.058 1.27v.024c.697 5.406 3.74 8.582 6.484 10.344 1.635 1.055 3.522 1.401 5.443 1.401 1.921 0 3.796-.358 5.443-1.4 2.745-1.763 5.798-4.939 6.495-10.345v-.023a6.71 6.71 0 0 0 .046-1.271H24Zm-3.83.743c-.493 3.908-2.63 6.16-4.7 7.48a6.372 6.372 0 0 1-3.454 1.018 6.4 6.4 0 0 1-3.454-1.019c-2.07-1.33-4.208-3.584-4.711-7.479V9.27c-.034-.18-.034-.347-.034-.527v-.024c.034-.791.114-1.235.171-1.474.057-.204.103-.288.16-.42a3.234 3.234 0 0 1 1.19-1.342 2.981 2.981 0 0 1 1.635-.492c.732 0 1.44.264 2.012.755.698.6 1.59 1.69 2.219 2.505.389.516 1.132.492 1.51-.036.605-.863 1.497-2.013 2.286-2.469a3.115 3.115 0 0 1 2.013-.767c.572 0 1.144.168 1.635.492a3.367 3.367 0 0 1 1.35 1.774c.057.24.137.683.171 1.474v.036c0 .18 0 .347-.023.527v.024h.023Z"
        fill="#fff"
      />
      <path d="M22.109 8.74c-.071-1.72-.383-2.292-.56-2.626a5.194 5.194 0 0 0-1.89-2.03c-.8-.49-1.715-.75-2.65-.752a5.122 5.122 0 0 0-3.255 1.164 5.263 5.263 0 0 0-1.81 2.979 5.263 5.263 0 0 0-1.812-2.983 5.124 5.124 0 0 0-3.256-1.164 5.09 5.09 0 0 0-2.65.751 5.198 5.198 0 0 0-1.891 2.03c-.176.333-.489.907-.559 2.626-.015.297 0 .595.045.888.594 4.464 3.215 7.066 5.644 8.55a8.613 8.613 0 0 0 4.481 1.262 8.607 8.607 0 0 0 4.482-1.262c2.43-1.48 5.05-4.083 5.644-8.55a4.39 4.39 0 0 0 .037-.883Z" />
    </symbol>
    <symbol id="heartHollowBg" viewBox="0 0 24 24">
      <g clip-path="url(#clip0_1845_19751)">
        <path d="M23.703 6.202a5.272 5.272 0 0 0-.47-1.223 7.323 7.323 0 0 0-2.56-2.9C19.562 1.384 18.316 1 17.035 1c-1.624 0-3.19.264-4.437 1.342-.217.18-.4.396-.583.6-.195-.204-.377-.42-.595-.6C10.163 1.264 8.597 1 6.973 1c-1.28 0-2.527.372-3.625 1.079A7.204 7.204 0 0 0 .786 4.967c-.114.228-.308.612-.468 1.235-.31 1.16-.417 2.436-.252 3.631v.024c.697 5.406 3.74 8.582 6.484 10.344 1.635 1.055 3.522 1.401 5.443 1.401 1.921 0 3.796-.358 5.443-1.4 2.745-1.763 5.798-4.939 6.495-10.345.064-1.235.072-2.442-.228-3.655Z" fill="#fff" />
        <path
          d="M22.494 9.003v-.03c-.04-.82-.13-1.44-.26-1.94-.14-.52-.31-.84-.41-1.02-.52-1-1.29-1.83-2.24-2.42-.97-.58-2.06-.9-3.18-.9-1.42 0-2.79.49-3.88 1.39-.19.15-.35.33-.51.5-.17-.17-.33-.35-.52-.5-1.1-.9-2.47-1.39-3.89-1.39-1.12 0-2.21.31-3.17.9-.95.58-1.73 1.42-2.24 2.41-.1.19-.27.51-.41 1.03-.14.5-.23 1.12-.27 1.95v.02c-.02.35 0 .71.05 1.06v.02c.61 4.51 3.27 7.16 5.67 8.63 1.43.88 3.08 1.34 4.76 1.34 1.68 0 3.32-.47 4.76-1.34 2.4-1.47 5.07-4.12 5.68-8.63v-.02c.05-.35.06-.7.04-1.06h.02Zm-3.35.62c-.43 3.26-2.3 5.14-4.11 6.24a5.795 5.795 0 0 1-6.04 0c-1.81-1.11-3.68-2.99-4.12-6.24v-.03c-.03-.15-.03-.29-.03-.44v-.02c.03-.66.1-1.03.15-1.23.05-.17.09-.24.14-.35.24-.47.6-.86 1.04-1.12.43-.27.93-.41 1.43-.41.64 0 1.26.22 1.76.63.61.5 1.39 1.41 1.94 2.09.34.43.99.41 1.32-.03.53-.72 1.31-1.68 2-2.06a2.8 2.8 0 0 1 1.76-.64c.5 0 1 .14 1.43.41.43.27.79.65 1.03 1.12 0 0 .1.19.15.36.05.2.12.57.15 1.23v.03c0 .15 0 .29-.02.44v.02h.02Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1845_19751"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath>
      </defs>
    </symbol>
    <symbol id="sparkle" viewBox="0 0 24 24"><path d="M2 12c8.236-1.072 9.026-1.941 10-11 .975 9.059 1.765 9.928 10 11-8.235 1.072-9.025 1.941-10 11-.974-9.059-1.764-9.928-10-11Z" /></symbol>
    <symbol id="sort" viewBox="0 0 18 14">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.684.597v9.984l1.733-1.653a.843.843 0 0 1 1.07-.068 2 2 0 0 1 .493.535.732.732 0 0 1-.031.812c-.214.308-1.336 1.472-2.204 2.31-.615.595-1.125.999-1.5 1.263a1.213 1.213 0 0 1-1.393 0 12.873 12.873 0 0 1-1.5-1.262c-.867-.839-1.999-2.012-2.213-2.32a.734.734 0 0 1-.032-.812 2.047 2.047 0 0 1 .494-.535.844.844 0 0 1 1.07.068l1.67 1.593V.597c0-.33.294-.597.657-.597h1.03c.362 0 .656.267.656.597ZM14.493 13.402V3.42l1.732 1.653a.843.843 0 0 0 1.07.068 2.002 2.002 0 0 0 .493-.535.732.732 0 0 0-.03-.812c-.214-.308-1.337-1.472-2.205-2.31A12.815 12.815 0 0 0 14.054.22a1.212 1.212 0 0 0-1.393 0c-.374.264-.885.668-1.5 1.262-.868.839-2 2.012-2.213 2.32a.734.734 0 0 0-.032.812 2.047 2.047 0 0 0 .494.535.844.844 0 0 0 1.07-.068l1.67-1.593v9.915c0 .33.294.597.656.597h1.031c.362 0 .656-.267.656-.598Z"
      />
    </symbol>
    <symbol id="express" viewBox="0 0 16 16"><path d="M7.792.996c-.89.995-5.724 7.006-6.615 8.418-.98 1.343-.906 1.798.289 1.905 1.065.097 2.99.198 4.064.208.797.007 1.369.19 1.136 1.182-.215.916-.68 2.995-.93 3.976-.25.982.384 1.552 1.44.509 1.146-1.131 6.578-7.643 7.63-8.912 1.081-1.305.938-1.96-.702-1.982-1.255-.016-3.497-.017-4.616-.035-1.118-.018-1.026-.647-.894-1.364.131-.716.515-1.912.87-3.592.24-1.132-.58-1.534-1.672-.313Z" fill="inherit" /></symbol>
    <symbol id="undo" viewBox="0 0 16 16">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.544 7.623a4.085 4.085 0 0 0-1.167-1.489 4.803 4.803 0 0 0-1.59-.815c-1.061-.32-2.078-.323-2.91-.335-1.24-.023-5.02-.058-5.02-.058l1.754-1.602a1.115 1.115 0 0 0 .1-1.489 2.6 2.6 0 0 0-.295-.316 3.047 3.047 0 0 0-.5-.369 1.152 1.152 0 0 0-1.206.044c-.458.296-1.257.9-2.504 2.105A18.255 18.255 0 0 0 .328 5.383a1.595 1.595 0 0 0 0 1.937c.393.52.994 1.23 1.878 2.083 1.247 1.207 2.046 1.81 2.504 2.106.366.237.832.264 1.207.045a3.12 3.12 0 0 0 .499-.37c.115-.104.213-.21.296-.317A1.114 1.114 0 0 0 6.61 9.38L4.85 7.955c.075 0 3.64-.03 5.026-.056.437-.007.8-.015 1.156.006.35.018.673.064.949.137.562.163.848.371 1.052.76.099.203.178.461.216.764.034.3.044.654.015.952a2.602 2.602 0 0 1-.188.792c-.101.218-.23.392-.407.551-.356.313-.971.534-1.687.637-.281.043-1.208.108-1.096 1.302.113 1.2 1.016 1.183 1.405 1.142.944-.106 2.023-.337 2.999-1.079a4.262 4.262 0 0 0 1.155-1.402c.27-.536.416-1.092.49-1.62a6.892 6.892 0 0 0 .036-1.56 5.186 5.186 0 0 0-.428-1.658Z"
      />
    </symbol>
    <symbol id="hanger" viewBox="0 0 16 16">
      <g clip-path="url(#clip0_2626_5849)" fill="#0073E6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.228 5.505c-.386-.61-.805-2.178 1.079-2.99 1.884-.813 2.729 1.214 2.738 1.59.01.375-.116 1.025.331 1.351.447.327 4.185 1.046 4.747 1.118.563.072 1.47.689 1.53 1.564.058.874-.646 1.21-1.959 1.733-1.313.522-5.827 2.276-7.773 3.15-1.947.872-2.54-.726-1.839-1.817.702-1.092 1.768-2.825 2.093-3.29.325-.463 1.56-.232 1.164.503-.398.735-1.454 2.07-1.583 2.301-.129.232-.402 1.221.97.64 1.374-.582 6.078-2.582 6.625-2.808.546-.226.56-.678-.326-.775-.885-.096-3.565-.73-4.042-.831-.477-.102-1.016-.46-1.118-1.017-.102-.558-.074-1.13-.141-1.636-.067-.506-.48-.76-.996-.537-.516.225-.614.528-.518.843.097.314.389.437.654.499.266.062.4.68.054.969-.347.287-1.305.05-1.69-.56Z" />
        <path d="M.373 10.687a.752.752 0 0 1 .925-.505l1.526.426a.73.73 0 0 1 .513.907.751.751 0 0 1-.925.505l-1.527-.426a.73.73 0 0 1-.512-.907ZM3.08 13.09a.736.736 0 0 1-.563.88l-1.369.308a.746.746 0 0 1-.894-.55.736.736 0 0 1 .563-.88l1.368-.31a.746.746 0 0 1 .895.551Z" />
      </g>
      <defs>
        <clipPath id="clip0_2626_5849"><path fill="#fff" d="M0 0h16v16H0z" /></clipPath>
      </defs>
    </symbol>
    <symbol id="lightning" viewBox="0 0 16 16"><path d="M5.274 10.05c-2.171.222-.91-1.632-.91-1.632s2.803-6.199 3.864-6.993c.454-.34.605.311.454 1.166a55.491 55.491 0 0 1-.455 2.797c-.204 1.05 1.137.932 1.137.932h1.817c1.273 0 .682 1.398.682 1.398s-2.122 4.036-3.636 6.527c-.424.698-.939.466-.909-.234.03-.7.228-1.865.228-2.796 0-1.018 0-1.399-2.272-1.166Z" fill="#0073E6" /></symbol>
    <symbol id="loader" viewBox="0 0 40 40"><path d="M0 20A20 20 0 1 0 20 0v4A16 16 0 1 1 4 20H0Z" /></symbol>
    <symbol id="location" viewBox="0 0 24 24"><path d="M2 10.847C2 8.309 20.88.676 21.901 2.199 22.921 3.723 15.757 22 13.205 22c-2.551 0-1.832-5.906-3.55-7.616C7.934 12.674 2 13.385 2 10.847Z" /></symbol>
    <symbol id="camera" viewBox="0 0 46 36">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.152.098c2.279.121 2.767.92 3.189 1.766.527 1.059 1.077 2.088 1.661 2.752a3.21 3.21 0 0 0 2.322 1.123c2.1.073 6.716.146 8.394.223 2.266.104 3.373 2.988 3.276 5.622-.071 1.923-.089 5.332-.156 9.108-.089 5-.347 8.646-.454 10.085-.188 2.526-1.053 4.955-5.47 5.024-4.158.064-25.92.192-31.04.199h-.756C5.032 35.99.427 35.272.3 30.854.27 29.823-.022 26.501 0 20.632c.023-5.48.452-10.01.593-11.124.336-2.653 3.615-3.87 4.52-3.89 2.72-.058 4.914.046 5.808.005.796-.037 1.908-.492 2.495-1.215.843-1.037 1.36-1.905 1.806-2.524.607-.84 1.756-1.87 3.785-1.866 2.843.006 5.106-.08 8.144.08ZM14.794 20.663c-.161 4.53 3.726 8.833 8.933 8.836 5.207.004 9.361-3 8.868-9.49-.494-6.49-3.362-7.907-8.563-8.182-5.201-.276-9.077 4.305-9.238 8.836Zm8.957-4.343c2.467.084 3.945 1.728 3.926 4.298-.018 2.57-2.241 3.48-4.348 3.332-2.108-.147-3.635-1.621-3.582-3.784.054-2.163 1.536-3.93 4.004-3.846ZM21.675 2.975c-.478.02-1.234.051-1.43.45-.196.398-.323 1.244-.466 1.714-.142.47.18.756.925.77.745.014 3.32.005 4.05.01.729.006 1.403-.04 1.346-.617-.057-.577-.16-1.151-.248-1.397-.09-.246-.118-.696-1.018-.77-.9-.075-2.68-.18-3.159-.16Z"
      />
    </symbol>
    <symbol id="refresh" viewBox="0 0 24 24"><path d="M21.044 3.827a1.528 1.528 0 0 0-1.678.322l-.556.556a10.006 10.006 0 0 0-7.854-2.654c-4.634.469-8.37 4.156-8.887 8.78-.332 2.907.546 5.687 2.468 7.833A10.006 10.006 0 0 0 11.99 22c3.835 0 7.376-2.234 9.025-5.697a1.528 1.528 0 0 0-.088-1.502 1.539 1.539 0 0 0-1.307-.722c-.586 0-1.122.322-1.376.839-1.376 2.966-4.595 4.526-7.834 3.804-2.546-.565-4.615-2.653-5.151-5.199-.449-2.087.039-4.214 1.356-5.843a6.865 6.865 0 0 1 5.375-2.575c1.708 0 3.337.643 4.615 1.814l-1.58 1.58a1.544 1.544 0 0 0-.342 1.698c.244.585.79.956 1.424.956h4.342c.858 0 1.551-.693 1.551-1.551V5.25c0-.634-.37-1.19-.956-1.434v.01Z" /></symbol>
    <symbol id="attach" viewBox="0 0 17 20">
      <path
        d="M16.417 8.69c-.718-1.734-2.212-4.256-4.227-6.147C10.421.898 7.64-.817 4.445.425 3.049.967 2.007 1.814 1.339 2.947.75 3.942.464 5.144.504 6.503c.078 2.572 1.277 5.212 2.27 6.965l.04.08c1.07 1.89 2.172 3.841 3.469 5.063.816.758 1.641 1.202 2.536 1.34.206.03.403.049.61.049.796 0 1.621-.246 2.437-.729 1.012-.591 1.72-1.399 2.103-2.384.325-.828.413-1.773.266-2.798-.246-1.655-1.062-3.26-1.74-4.473-.177-.315-.354-.64-.53-.985-.119-.226-.246-.473-.384-.729-.315-.581-.688-1.25-1.15-1.842-.57-.739-1.17-1.212-1.828-1.448-.865-.315-1.79-.207-2.664.315-.993.581-1.474 1.665-1.327 2.956.089.768.383 1.635.914 2.64.344.65.767 1.34 1.268 2.039.187.266.344.483.472.64.747.867 1.396 1.005 2.182.444.659-.463.698-1.222.108-2.118-.059-.08-.147-.207-.246-.345-.068-.099-.147-.207-.216-.306a15.77 15.77 0 0 1-1.071-1.714c-.344-.65-.55-1.221-.6-1.606V7.4c.079-.04.177-.079.236-.06.05.02.246.109.56.523.256.325.521.768.875 1.428.098.197.216.404.325.63.206.385.412.799.609 1.153.511.907 1.219 2.257 1.396 3.458.078.513.049.946-.089 1.29-.147.375-.442.69-.894.957-.462.275-.855.374-1.19.325-.324-.05-.668-.247-1.051-.611-.905-.857-1.77-2.325-2.978-4.463-1.18-2.079-1.848-4.069-1.897-5.615-.02-.798.117-1.458.422-1.97.334-.572.885-.996 1.68-1.311 1.485-.571 3.107-.05 4.817 1.557 1.68 1.576 2.949 3.753 3.529 5.142a1.42 1.42 0 0 0 1.867.769 1.457 1.457 0 0 0 .747-1.892l.03-.02Z"
      />
    </symbol>
    <symbol id="history" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m3.04 4.752 2.088-3.636v-.012C5.248.84 5.728.096 6.664 0c.408-.036 1.164.06 1.512.648.144.24.588 1.128.588 1.128 3.144-1.02 6.696-.636 9.528 1.056 4.56 2.724 6.636 8.34 4.932 13.368-1.068 3.168-3.324 5.616-6.348 6.9a11.399 11.399 0 0 1-9.312-.168c-3.96-1.86-6.54-5.88-6.564-10.26 0-.624.3-1.188.804-1.5a1.76 1.76 0 0 1 1.704-.108c.612.288 1.02.876 1.02 1.536-.012 3.732 2.556 6.9 6.252 7.728 2.904.648 6.048-.516 7.836-2.88 1.476-1.944 2.004-4.38 1.428-6.696a7.842 7.842 0 0 0-4.308-5.268c-1.764-.828-3.756-.948-5.64-.36l.264.888c.288.852.336 1.392-.408 2.028-.81.388-1.317.255-1.88.107a34.347 34.347 0 0 0-.088-.023l-4.092-1.02a1.762 1.762 0 0 1-.852-2.352Zm12.444 7.14a1.496 1.496 0 0 1 1.968.756l-.072-.036a1.506 1.506 0 0 1-.756 1.968l-3.672 1.644H12.916c-.029.02-.066.03-.098.041l-.022.007c-.012.012-.036.012-.048.012-.024.012-.048.024-.084.024-.024.012-.048.012-.072.012h-.408c-.036-.012-.06-.012-.096-.012h-.036c-.026 0-.048-.007-.069-.013-.018-.006-.034-.011-.05-.011-.025-.012-.037-.012-.049-.012-.012 0-.024-.006-.036-.012-.012-.006-.024-.012-.036-.012a.091.091 0 0 0-.048-.024l-.072-.036c-.012-.012-.036-.012-.036-.012a.486.486 0 0 1-.108-.06c-.012-.012-.024-.012-.024-.012a.365.365 0 0 1-.084-.06c-.012-.012-.024-.012-.024-.012a.308.308 0 0 0-.058-.06l-.026-.024a.365.365 0 0 1-.096-.096c-.012-.018-.024-.033-.036-.048a.666.666 0 0 1-.036-.048c-.012-.012-.024-.036-.024-.036-.006-.012-.015-.024-.024-.036-.009-.012-.018-.024-.024-.036l-.013-.023c-.006-.01-.01-.018-.01-.025l-.016-.024c-.01-.016-.02-.032-.02-.048l-.073-.18v-.036a.226.226 0 0 1-.024-.096c-.012-.036-.012-.06-.012-.084V14.916l-.18-5.292a1.496 1.496 0 0 1 1.44-1.536 1.489 1.489 0 0 1 1.536 1.44l.096 3.108 1.668-.744Z"
      />
    </symbol>
    <symbol id="dm_ship_instore" viewBox="0 0 16 16">
      <path d="M3.917 3.786s.073-.399.203-.633c.13-.233.434-.578 1.324-.626.89-.047 4.688-.026 5.72.014 1.03.04 1.385.42 1.46.737.075.317.031.93.031.93l-8.738-.422Z" fill="#0073E6" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.49 6.408c-.015.704-.06 5.142-.018 5.997.042.854.242.859 1.475.847.161-.001.403.002.704.006.811.01 2.052.026 3.314-.027h.055c.169.002.548.006.563-.299.006-.126.013-.504.02-.957.015-.868.034-2.014.065-2.198l.005-.03c.023-.147.057-.364.493-.364h1.22l.032-.001c.131-.009.477-.03.469.465-.014.844.017 1.904.036 2.535.007.246.012.427.012.505 0 .276.17.348.296.348.252.002.442.002.528 0 .73-.015.726-.11.701-.78-.006-.164-.013-.362-.011-.603.004-.505.005-1.464.005-2.451 0-1.405.001-2.865.013-3.142.02-.473-.263-.909-1.036-.949-.774-.04-6.905.19-7.659.304-.09.014-.176.026-.259.037-.61.082-1.01.136-1.023.757Zm1.52 3.636-.004.088c-.017.501-.009.774 0 1.096l.001.036c.008.295.012.418.584.425.595.007.98.009 1.752.007.755-.001.756-.142.757-.499v-.025l.001-.142c.002-.35.005-.768-.006-1.118-.012-.399-.208-.447-.602-.463a32.531 32.531 0 0 0-1.705 0c-.763.013-.767.162-.779.595Z"
        fill="#0073E6"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.596 3.749a30.6 30.6 0 0 0-1.396.018c-.306.013-.65.132-.87.488-.22.355-1.079 1.59-1.251 2.115-.173.524-.174 1.65 1.209 1.67 1.382.021 1.646-1.077 1.717-1.45.072-.372.945-2.834.591-2.841ZM11.033 3.812c.41-.001 1.323-.013 1.666.048.343.061 1.516 1.861 1.606 2.728.09.866-.473 1.384-1.377 1.403-.904.018-1.693-.618-1.738-.997-.045-.379-.082-.526-.105-.64-.024-.112-.506-2.542-.506-2.542h.454Z" fill="#F72A16" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.117 3.796a252.8 252.8 0 0 1 2.574.008c.209.005.55 2.53.583 2.813.032.282.222 1.555-1.396 1.55C8.26 8.16 8.165 7.107 8.159 6.68c-.006-.427-.234-2.88-.042-2.884Z" fill="#F72A16" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.157 3.785c-.23-.004-2.49-.05-2.556-.043-.065.008-.553 2.433-.588 2.653-.035.22-.065 1.682 1.529 1.654 1.593-.027 1.642-1.45 1.655-1.618.012-.17.073-1.67.087-1.941.014-.272.103-.7-.127-.705Z" fill="#F72A16" />
    </symbol>
    <symbol id="dm_ship_instore_disabled" viewBox="0 0 32 32">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.047 7.247s.154-.852.428-1.351c.274-.5.916-1.236 2.795-1.337 1.88-.102 9.9-.058 12.077.028 2.177.085 2.925.9 3.084 1.576.16.675.066 1.985.066 1.985l-18.45-.901Zm-.901 5.6c-.032 1.505-.126 10.982-.038 12.808.087 1.826.51 1.835 3.114 1.81.34-.003.85.004 1.487.012 1.712.023 4.332.057 6.995-.056.03-.002.07-.001.117 0 .357.003 1.158.013 1.19-.64.013-.27.026-1.075.042-2.042.03-1.856.071-4.303.138-4.697l.01-.061c.048-.316.12-.78 1.041-.78h2.574a1.2 1.2 0 0 0 .069-.003c.276-.017 1.006-.064.99.993-.03 1.803.037 4.068.076 5.416.015.526.026.913.026 1.078 0 .59.36.744.624.745.533.003.934.003 1.114 0 1.54-.032 1.533-.234 1.482-1.665-.013-.351-.028-.776-.024-1.29.009-1.078.01-3.128.01-5.235.002-3.001.003-6.119.027-6.711.041-1.01-.554-1.94-2.187-2.026-1.633-.086-14.578.403-16.17.649-.19.03-.373.054-.547.078-1.29.176-2.132.292-2.16 1.617Zm3.207 7.767-.006.187a32.06 32.06 0 0 0 0 2.34l.002.077c.017.63.024.895 1.233.909 1.256.015 2.067.019 3.698.016 1.596-.004 1.597-.304 1.6-1.067v-.053c0-.098 0-.2.002-.303.004-.75.009-1.64-.013-2.389-.025-.852-.439-.955-1.273-.988-.833-.033-1.886-.028-3.6 0-1.61.027-1.618.345-1.643 1.271Z"
        fill="#B2B2B2"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.593 7.167a63.87 63.87 0 0 0-2.947.039c-.647.027-1.372.282-1.838 1.042-.466.759-2.278 3.398-2.642 4.517-.365 1.12-.366 3.524 2.553 3.569 2.92.045 3.475-2.302 3.627-3.097.151-.796 1.994-6.053 1.247-6.07ZM22.073 7.302c.867-.002 2.792-.027 3.516.103.724.13 3.202 3.975 3.391 5.826.19 1.85-.998 2.957-2.907 2.997-1.909.039-3.575-1.32-3.67-2.13-.095-.809-.171-1.123-.221-1.365-.05-.241-1.068-5.431-1.068-5.431s.092.002.959 0Z" fill="#E5E5E5" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.914 7.268c.405-.009 4.995.008 5.435.017.441.01 1.163 5.405 1.23 6.008.068.602.47 3.322-2.947 3.31-3.416-.012-3.616-2.264-3.628-3.175-.013-.912-.495-6.15-.09-6.16Z" fill="#E5E5E5" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.246c-.485-.01-5.258-.11-5.396-.093-.138.017-1.167 5.196-1.242 5.666-.075.47-.137 3.592 3.228 3.534 3.364-.06 3.468-3.096 3.494-3.457.026-.36.155-3.567.185-4.146.03-.58.217-1.494-.269-1.504Z" fill="#E5E5E5" />
    </symbol>
    <symbol id="dm_ship_store_pickup" viewBox="0 0 16 16">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.751 5.005s.057-.312.158-.495c.102-.182.34-.451 1.035-.489.695-.037 3.663-.02 4.468.01.806.032 1.083.33 1.141.577.06.247.025.727.025.727l-6.827-.33Zm-.333 2.048c-.012.55-.046 4.017-.014 4.685.032.668.19.671 1.152.662.126 0 .315.002.55.005.634.008 1.604.02 2.589-.021h.043c.132.001.429.005.44-.234.005-.099.01-.393.016-.747.011-.679.026-1.574.05-1.718l.005-.022c.018-.116.044-.286.385-.286h.978c.102-.007.372-.024.366.363-.01.659.014 1.487.028 1.98.006.193.01.334.01.395 0 .215.133.272.23.272.198.001.346.002.413 0 .57-.012.567-.085.548-.609-.004-.128-.01-.284-.009-.472.004-.394.004-1.144.004-1.915 0-1.097.002-2.238.01-2.454.015-.37-.205-.71-.809-.741-.604-.032-5.394.147-5.983.237l-.203.029c-.477.064-.788.106-.799.591Zm1.187 2.841-.002.068a11.6 11.6 0 0 0 0 .857v.028c.007.23.01.327.456.332.465.005.765.007 1.37.006.59-.001.59-.111.59-.39v-.02l.002-.11c.001-.274.003-.6-.005-.874-.01-.312-.162-.35-.471-.362-.309-.012-.698-.01-1.332 0-.596.01-.599.126-.608.465Z"
        fill="#0073E6"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.063 4.976c-.276-.006-.85.004-1.09.014a.807.807 0 0 0-.68.38c-.172.278-.843 1.244-.978 1.653-.135.41-.135 1.29.945 1.306 1.08.016 1.286-.842 1.342-1.133.056-.291.738-2.214.461-2.22ZM13.31 5.025c.322 0 1.034-.01 1.302.038.268.047 1.185 1.454 1.255 2.13.07.677-.37 1.082-1.076 1.097-.706.014-1.323-.483-1.358-.78-.035-.295-.063-.41-.082-.498l-.395-1.987h.355Z" fill="#F72A16" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.033 5.013c.15-.004 1.848.002 2.01.006.164.003.431 1.977.456 2.197.025.22.173 1.216-1.09 1.211-1.265-.004-1.339-.828-1.343-1.161-.005-.334-.184-2.25-.034-2.253Z" fill="#F72A16" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.064 5.004c-.18-.004-1.946-.04-1.997-.034-.051.007-.432 1.901-.46 2.073-.027.172-.05 1.314 1.195 1.292 1.245-.021 1.283-1.132 1.293-1.264.01-.132.057-1.305.068-1.517.011-.212.08-.546-.1-.55Z" fill="#F72A16" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M.5 8.655c-.002-.82.106-.981.616-1.014.51-.033.947-.006 1.2-.004.254.002.287-.199.294-.83.008-.63.537-.799 1.063-.265.527.535.998 1.222 1.234 1.607.235.386.24.79-.135 1.236-.376.445-.974 1.13-1.343 1.418-.37.287-.81.195-.8-.363.009-.558.064-.74-.268-.757a19.04 19.04 0 0 0-1.187-.024C.68 9.663.502 9.476.5 8.655Z" fill="#00C2FC" />
    </symbol>
    <symbol id="dm_ship_store_pickup_disabled" viewBox="0 0 33 33">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.544 9.836s.111-.63.309-.999.662-.913 2.019-.988c1.356-.075 7.147-.042 8.719.02 1.572.064 2.112.666 2.227 1.166.115.5.048 1.467.048 1.467l-13.322-.666Zm-.65 4.14c-.023 1.111-.091 8.117-.028 9.466.063 1.35.369 1.356 2.248 1.338.246-.002.615.003 1.074.009 1.236.016 3.128.041 5.051-.042h.084c.258.003.837.01.86-.472.009-.2.018-.796.03-1.51.022-1.372.051-3.18.1-3.471l.006-.046c.036-.234.087-.576.752-.576h1.859l.05-.002c.2-.013.726-.048.714.734-.02 1.332.027 3.006.055 4.003.011.388.02.674.02.796 0 .436.259.55.45.55.384.003.673.003.804 0 1.112-.023 1.107-.172 1.07-1.23-.01-.26-.02-.573-.018-.954.007-.796.007-2.311.008-3.869.001-2.218.002-4.522.02-4.96.029-.746-.4-1.434-1.58-1.498-1.179-.063-10.525.299-11.675.48-.137.022-.269.04-.395.058-.93.13-1.54.216-1.56 1.195Zm2.315 5.74-.004.138a24.326 24.326 0 0 0 0 1.73l.002.056c.011.467.017.662.89.672.907.011 1.492.014 2.67.012 1.152-.002 1.152-.225 1.155-.788v-.264c.004-.553.008-1.212-.008-1.765-.018-.63-.317-.706-.919-.73-.602-.025-1.362-.021-2.6 0-1.162.02-1.168.254-1.186.939Z"
        fill="#B2B2B2"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.104 9.777c-.54-.012-1.66.008-2.127.029-.468.02-.991.209-1.328.77-.336.56-1.644 2.511-1.907 3.339-.263.828-.265 2.605 1.843 2.638 2.108.033 2.51-1.702 2.619-2.29.11-.588 1.44-4.474.9-4.486ZM26.393 9.876c.626-.001 2.017-.02 2.54.077.522.096 2.31 2.938 2.448 4.305.136 1.368-.722 2.187-2.1 2.216-1.378.029-2.58-.976-2.65-1.574-.068-.598-.123-.83-.159-1.01-.036-.178-.771-4.014-.771-4.014s.066.002.692 0Z" fill="#E5E5E5" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.946 9.852c.293-.007 3.607.005 3.925.013.318.007.84 3.994.888 4.44.049.445.339 2.456-2.128 2.447-2.466-.01-2.61-1.674-2.62-2.347-.009-.674-.357-4.546-.065-4.553Z" fill="#E5E5E5" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.009 9.835c-.35-.008-3.797-.081-3.897-.068-.1.012-.842 3.84-.896 4.187-.054.347-.1 2.656 2.33 2.612 2.429-.043 2.504-2.288 2.523-2.555.018-.266.112-2.636.134-3.064.021-.429.156-1.104-.194-1.112ZM1.395 17.213c-.004-1.658.206-1.983 1.2-2.05.995-.066 1.85-.011 2.345-.008.494.004.558-.401.573-1.676.015-1.274 1.046-1.615 2.074-.536 1.028 1.08 1.947 2.469 2.406 3.248.459.778.47 1.597-.263 2.496-.733.9-1.9 2.285-2.62 2.866-.72.58-1.58.394-1.561-.734.017-1.127.126-1.495-.522-1.529a35.94 35.94 0 0 0-2.318-.05c-.963.01-1.31-.369-1.314-2.027Z" fill="#E5E5E5" />
    </symbol>
    <symbol id="dm_ship_pickup" viewBox="0 0 32 32" fill="none">
      <g clip-path="url(#clip0_6_1646)">
        <path d="M4.173 11.365c.911-6.732 7.227-9.019 12.14-9.019 5.06 0 10.754 2.806 11.563 9.448 1.012 8.303-4.4 12.402-7.948 13.456-2.89.86-2.053 3.865-3.615 3.865-1.561 0-1.011-3.006-4.046-3.865-3.036-.859-9.393-4.294-8.094-13.885Z" fill="#0073E6" />
        <path d="M15.396 21.255v-6.553c0-.631-.353-1.205-.91-1.464l-5.557-2.662c-.36-.178-.776.097-.776.51v6.496c0 .623.345 1.198.894 1.465l5.556 2.718c.361.178.785-.097.785-.51h.008ZM12.053 7.776l-2.73 1.279c-.244.113-.244.477 0 .59l6.042 2.832c.416.194.887.194 1.295 0l2.488-1.165-7.095-3.536ZM22.617 9.103l-6.02-2.832a1.512 1.512 0 0 0-1.295 0l-2.142.996 7.134 3.51 2.323-1.083c.243-.114.243-.478 0-.59Z" fill="#fff" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="m23.071 10.576-2.197 1.052v2.953l-1.138.542V12.17l-2.213 1.06c-.557.267-.91.841-.91 1.464v6.553c0 .413.423.688.784.51l5.556-2.719c.55-.267.895-.833.895-1.464v-6.488c0-.413-.416-.688-.777-.51Z" fill="#fff" />
      </g>
      <defs>
        <clipPath id="clip0_6_1646"><path fill="#fff" transform="translate(4 2.346)" d="M0 0h24v26.769H0z" /></clipPath>
      </defs>
    </symbol>
    <symbol id="dm_ship_pickup_disabled" viewBox="0 0 32 32" fill="none">
      <g clip-path="url(#clip0_6_1652)">
        <path d="M4.173 11.365c.911-6.732 7.227-9.019 12.14-9.019 5.06 0 10.754 2.806 11.563 9.448 1.012 8.303-4.4 12.402-7.948 13.456-2.89.86-2.053 3.865-3.615 3.865-1.561 0-1.011-3.006-4.046-3.865-3.036-.859-9.393-4.294-8.094-13.885Z" fill="#B2B2B2" />
        <path d="M15.396 21.255v-6.553c0-.631-.353-1.205-.91-1.464l-5.557-2.662c-.36-.178-.776.097-.776.51v6.496c0 .623.345 1.198.894 1.465l5.556 2.718c.361.178.785-.097.785-.51h.008ZM12.053 7.776l-2.73 1.279c-.244.113-.244.477 0 .59l6.042 2.832c.416.194.887.194 1.295 0l2.488-1.165-7.095-3.536ZM22.617 9.103l-6.02-2.832a1.512 1.512 0 0 0-1.295 0l-2.142.996 7.134 3.51 2.323-1.083c.243-.114.243-.478 0-.59Z" fill="#fff" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="m23.071 10.576-2.197 1.052v2.953l-1.138.542V12.17l-2.213 1.06c-.557.267-.91.841-.91 1.464v6.553c0 .413.423.688.784.51l5.556-2.719c.55-.267.895-.833.895-1.464v-6.488c0-.413-.416-.688-.777-.51Z" fill="#fff" />
      </g>
      <defs>
        <clipPath id="clip0_6_1652"><path fill="#fff" transform="translate(4 2.346)" d="M0 0h24v26.769H0z" /></clipPath>
      </defs>
    </symbol>
    <symbol id="dm_ship_delivery" viewBox="0 0 32 32" fill="none">
      <g clip-path="url(#clip0_6_1626)" fill-rule="evenodd" clip-rule="evenodd">
        <path d="M2.229 8.239c0-.92.276-2.46 2.316-2.62 2.04-.159 10.864-.159 13.218 0 2.355.16 2.745 1.083 2.745 3.908 0 2.824-.015 9.65-.129 11.016-.114 1.366-.745 2.596-2.235 2.596-1.49 0-12.017.142-13.256 0-1.239-.142-2.659-.749-2.838-2.707-.179-1.958.179-11.274.179-12.193Z" fill="#00C2FC" />
        <path
          d="M19.855 20.29c.013.7.023 1.222.023 1.427 0 .932.437 1.257 1.043 1.382.445.092 3.662.049 5.86.02.795-.011 1.458-.02 1.806-.02 1.27 0 1.268-.447 1.267-.768v-.028c0-.12.02-.63.046-1.27.043-1.08.1-2.53.1-3.104 0-.499-.095-.607-.535-.874a53.452 53.452 0 0 0-1.237-.728l-.037-.02c-.343-.187-.643-.35-.773-.603-.038-.075-.143-.323-.285-.658-.362-.851-.96-2.258-1.274-2.747-.416-.645-.693-.666-1.56-.732l-.15-.012a41.366 41.366 0 0 0-2.613-.073c-.897.025-1.505.087-1.658.817-.158.752-.07 5.501-.023 7.992Zm4.006-6.735H22.21c-.09 0-.283 0-.321.429-.047.523-.022 1.278-.008 1.714.004.135.008.239.008.296 0 .408.192.484.397.484h2.192c.489 0 .698-.254.59-.701-.085-.346-.458-1.37-.713-1.93-.1-.218-.24-.292-.494-.292ZM10.063 21.09c1.315 0 2.83.682 2.83 2.686 0 2.005-1.172 2.724-2.674 2.724-1.5 0-2.972-.712-2.972-2.705s1.5-2.706 2.816-2.706Z"
          fill="#0073E6"
        />
        <path d="M24.97 21.882c1.208 0 2.513.9 2.446 2.401-.068 1.502-.934 2.302-2.528 2.21-1.594-.093-2.316-1.26-2.316-2.421 0-1.163 1.192-2.19 2.399-2.19Z" fill="#00C2FC" />
      </g>
      <defs>
        <clipPath id="clip0_6_1626"><path fill="#fff" transform="translate(2 5.5)" d="M0 0h28v21H0z" /></clipPath>
      </defs>
    </symbol>
    <symbol id="dm_ship_delivery_disabled" viewBox="0 0 32 32" fill="none">
      <g clip-path="url(#clip0_6_1636)" fill-rule="evenodd" clip-rule="evenodd">
        <path d="M2.229 8.239c0-.92.276-2.46 2.316-2.62 2.04-.159 10.864-.159 13.218 0 2.355.16 2.745 1.083 2.745 3.908 0 2.824-.015 9.65-.129 11.016-.114 1.366-.745 2.596-2.235 2.596-1.49 0-12.017.142-13.256 0-1.239-.142-2.659-.749-2.838-2.707-.179-1.958.179-11.274.179-12.193Z" fill="#E5E5E5" />
        <path
          d="M19.855 20.29c.013.7.023 1.222.023 1.427 0 .932.437 1.257 1.043 1.382.445.092 3.662.049 5.86.02.795-.011 1.458-.02 1.806-.02 1.27 0 1.268-.447 1.267-.768v-.028c0-.12.02-.63.046-1.27.043-1.08.1-2.53.1-3.104 0-.499-.095-.607-.535-.874a53.452 53.452 0 0 0-1.237-.728l-.037-.02c-.343-.187-.643-.35-.773-.603-.038-.075-.143-.323-.285-.658-.362-.851-.96-2.258-1.274-2.747-.416-.645-.693-.666-1.56-.732l-.15-.012a41.366 41.366 0 0 0-2.613-.073c-.897.025-1.505.087-1.658.817-.158.752-.07 5.501-.023 7.992Zm4.006-6.735H22.21c-.09 0-.283 0-.321.429-.047.523-.022 1.278-.008 1.714.004.135.008.239.008.296 0 .408.192.484.397.484h2.192c.489 0 .698-.254.59-.701-.085-.346-.458-1.37-.713-1.93-.1-.218-.24-.292-.494-.292ZM10.063 21.09c1.315 0 2.83.682 2.83 2.686 0 2.005-1.172 2.724-2.674 2.724-1.5 0-2.972-.712-2.972-2.705s1.5-2.706 2.816-2.706Z"
          fill="#B2B2B2"
        />
        <path d="M24.97 21.882c1.208 0 2.513.9 2.446 2.401-.068 1.502-.934 2.302-2.528 2.21-1.594-.093-2.316-1.26-2.316-2.421 0-1.163 1.192-2.19 2.399-2.19Z" fill="#E5E5E5" />
      </g>
      <defs>
        <clipPath id="clip0_6_1636"><path fill="#fff" transform="translate(2 5.5)" d="M0 0h28v21H0z" /></clipPath>
      </defs>
    </symbol>
    <symbol id="dm_cat_placeholder" viewBox="0 0 32 32">
      <g clip-path="url(#clip0_6_1371)">
        <path d="M3.114 8.633c.167-2.68.25-4.02 1.092-4.84.07-.069.145-.134.222-.196.918-.733 2.258-.65 4.939-.483 2.68.167 4.02.25 4.84 1.092.069.07.134.145.196.222.733.918.65 2.258.483 4.939-.167 2.68-.25 4.02-1.092 4.84a3.04 3.04 0 0 1-.222.196c-.917.733-2.258.65-4.939.483-2.68-.167-4.02-.25-4.84-1.092a3.037 3.037 0 0 1-.196-.222c-.733-.917-.65-2.258-.483-4.939Z" fill="#F72A16" />
        <path d="M3 23c0-2.733 0-4.099.803-4.984.067-.074.139-.146.213-.213C4.901 17 6.267 17 9 17s4.099 0 4.984.803c.075.067.146.139.213.213C15 18.901 15 20.267 15 23s0 4.099-.803 4.984a3.107 3.107 0 0 1-.213.213C13.099 29 11.733 29 9 29s-4.099 0-4.984-.803a3.101 3.101 0 0 1-.213-.213C3 27.099 3 25.733 3 23Z" fill="#06C92E" />
        <path d="M17 9c0-2.733 0-4.099.803-4.984.067-.074.139-.146.213-.213C18.901 3 20.267 3 23 3s4.099 0 4.984.803c.074.067.146.139.213.213C29 4.901 29 6.267 29 9s0 4.099-.803 4.984a3.107 3.107 0 0 1-.213.213C27.099 15 25.733 15 23 15s-4.099 0-4.984-.803a3.107 3.107 0 0 1-.213-.213C17 13.099 17 11.733 17 9Z" fill="#FABC22" />
        <path d="M17.106 22.653c.158-2.684.237-4.027 1.076-4.85.071-.069.145-.135.222-.197.916-.737 2.258-.658 4.943-.5 2.684.158 4.027.237 4.85 1.076.069.071.135.145.197.222.737.916.658 2.258.5 4.943-.158 2.684-.237 4.027-1.076 4.85-.071.069-.145.135-.222.197-.916.737-2.258.658-4.943.5-2.684-.158-4.027-.237-4.85-1.076a3.072 3.072 0 0 1-.197-.222c-.737-.916-.658-2.258-.5-4.943Z" fill="#00C2FC" />
      </g>
      <defs>
        <clipPath id="clip0_6_1371"><path fill="#fff" transform="translate(3 3)" d="M0 0h26v26H0z" /></clipPath>
      </defs>
    </symbol>

    <symbol id="colored_express" viewBox="0 0 16 16">
      <path fill-rule="evenodd" clip-rule="evenodd" d="m4.373 8.14.293 2.474c.052.425.216.455.504.478.235.018 6.29-.755 8.834-1.08l1.077-.137c.605-.074.607-.362.59-.509-.007-.054-.037-.31-.073-.632-.062-.542-.145-1.27-.177-1.532-.026-.215-.09-.529-.42-.619-.329-.09-.974-.257-1.117-.287-.276-.057-.322-.15-.406-.314l-.016-.032c-.095-.185-1.078-1.898-1.319-2.184-.241-.285-.687-.31-1.414-.22l-5.895.724c-.905.111-.856.567-.793 1.076l.332 2.794Zm5.854-3.532-.133.018c-.165.02-.28.073-.244.366.023.19.13 1.015.242 1.442.061.234.166.26.376.235l1.72-.211c.371-.046.377-.3.19-.606-.154-.254-.515-.765-.713-1.046a38.572 38.572 0 0 1-.099-.14c-.11-.158-.41-.166-.562-.152-.267.024-.588.068-.777.094Z" fill="#FABC22" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.56 10.161c.432-.031 1.124.206 1.179.913.055.707-.364 1.072-.875 1.15-.51.08-1.14-.149-1.238-.883-.1-.735.502-1.149.934-1.18ZM13.542 9.293c.462-.057 1.003.228 1.049.81.045.582-.249.93-.863.97-.614.039-.955-.341-1-.824-.045-.483.352-.9.814-.956Z" fill="#0073E6" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.101 6.84c.246-.035 1.37-.173 1.566-.199.197-.026.295-.29.214-.717-.08-.427-.602-.36-1.162-.266-.56.095-1.161.106-1.102.664.06.559.239.551.484.517ZM.479 7.967a64.576 64.576 0 0 1 2.236-.398c.258-.033.593-.014.668.525.074.54-.235.602-.608.67-.373.068-2.029.343-2.304.38-.275.039-.384-.296-.42-.551-.035-.256-.044-.527.428-.626ZM.945 9.894a90.973 90.973 0 0 1 1.929-.329c.276-.042.502.155.544.464.043.308-.083.604-.448.658-.366.054-1.55.247-1.891.273-.342.027-.47-.273-.496-.47-.028-.197.046-.532.362-.596Z" fill="#F72A16" />
    </symbol>
    <symbol id="colored_express_disabled" viewBox="0 0 33 33">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.813 16.83c.261 2.248.513 4.416.561 4.814.1.827.412.885.961.93.449.035 12.011-1.47 16.868-2.101l2.056-.267c1.157-.145 1.16-.703 1.126-.989-.013-.106-.068-.604-.138-1.23-.118-1.054-.276-2.47-.337-2.98-.05-.418-.173-1.029-.802-1.204-.629-.176-1.861-.5-2.133-.558-.527-.111-.616-.291-.776-.612l-.03-.062c-.182-.36-2.058-3.691-2.518-4.247-.461-.556-1.312-.602-2.7-.429L10.695 9.303c-1.728.217-1.634 1.103-1.515 2.093.06.498.352 3.013.633 5.434Zm11.179-6.87-.254.036c-.314.039-.535.141-.466.713.044.37.248 1.973.462 2.805.116.455.317.506.718.456l3.283-.41c.71-.09.72-.583.364-1.18-.294-.494-.983-1.488-1.36-2.033l-.19-.274c-.21-.306-.782-.321-1.074-.295a33.52 33.52 0 0 0-1.483.183Z" fill="#B2B2B2" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.99 20.763c.826-.061 2.147.401 2.252 1.777.106 1.375-.695 2.084-1.67 2.237-.975.153-2.175-.29-2.365-1.719-.19-1.429.959-2.234 1.784-2.295ZM27.322 19.074c.882-.11 1.916.444 2.003 1.576.086 1.132-.475 1.81-1.647 1.886-1.172.076-1.824-.663-1.91-1.603-.085-.94.673-1.749 1.554-1.86ZM3.568 14.301c.469-.066 2.614-.335 2.99-.385.376-.05.562-.563.408-1.394-.154-.831-1.149-.703-2.218-.519-1.07.185-2.218.206-2.105 1.293.114 1.087.457 1.072.925 1.005ZM2.38 16.495c.9-.192 3.778-.709 4.27-.774.492-.065 1.132-.028 1.274 1.021.142 1.05-.447 1.17-1.16 1.303-.713.133-3.874.666-4.399.74-.525.075-.733-.576-.8-1.073-.068-.497-.086-1.025.815-1.217ZM3.27 20.244a170.94 170.94 0 0 1 3.682-.64c.528-.082.958.3 1.04.902.082.6-.159 1.176-.857 1.28-.698.104-2.957.48-3.61.532-.653.052-.895-.531-.947-.915-.053-.383.088-1.035.692-1.159Z"
        fill="#E5E5E5"
      />
    </symbol>
    <symbol id="colored_super_express" viewBox="0 0 33 33"><path d="M16.062 3.499c-1.425 1.593-9.159 11.21-10.584 13.469-1.569 2.149-1.45 2.877.462 3.049 1.704.154 4.785.316 6.502.332 1.276.011 2.19.303 1.818 1.89-.345 1.467-1.087 4.793-1.487 6.363-.401 1.57.613 2.482 2.303.814 1.834-1.81 10.525-12.23 12.208-14.26 1.73-2.087 1.5-3.136-1.124-3.17-2.008-.026-5.595-.027-7.384-.056-1.79-.03-1.643-1.035-1.432-2.182.21-1.147.824-3.06 1.393-5.748.383-1.811-.929-2.454-2.675-.501Z" fill="#0073E6" /></symbol>
    <symbol id="colored_super_express_disabled" viewBox="0 0 33 33"><path d="M16.062 3.499c-1.425 1.593-9.159 11.21-10.584 13.469-1.569 2.149-1.45 2.877.462 3.049 1.704.154 4.785.316 6.502.332 1.276.011 2.19.303 1.818 1.89-.345 1.467-1.087 4.793-1.487 6.363-.401 1.57.613 2.482 2.303.814 1.834-1.81 10.525-12.23 12.208-14.26 1.73-2.087 1.5-3.136-1.124-3.17-2.008-.026-5.595-.027-7.384-.056-1.79-.03-1.643-1.035-1.432-2.182.21-1.147.824-3.06 1.393-5.748.383-1.811-.929-2.454-2.675-.501Z" fill="#B3B3B3" /></symbol>
    <symbol viewBox="0 0 27 27" fill="none" id="colored_socials_whatsapp">
      <path d="m1.03 26.76 1.79-6.52.01-.041-.02-.036a12.764 12.764 0 0 1-1.713-6.402C1.1 6.7 6.857.955 13.932.955a12.768 12.768 0 0 1 9.08 3.756 12.714 12.714 0 0 1 3.755 9.06c-.003 7.06-5.761 12.806-12.835 12.806h-.006c-2.148 0-4.258-.539-6.133-1.559L7.758 25l-.038.01-6.69 1.751Z" fill="#fff" stroke="#DFDFDF" stroke-width=".2" />
      <path d="M13.936 3.035c-5.93 0-10.754 4.812-10.756 10.726a10.685 10.685 0 0 0 1.644 5.71l.256.405-1.087 3.958 4.07-1.065.392.233a10.753 10.753 0 0 0 5.473 1.495h.003c5.926 0 10.75-4.812 10.752-10.727a10.644 10.644 0 0 0-3.146-7.59 10.681 10.681 0 0 0-7.601-3.145Z" fill="#57D163" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7 8.365c-.243-.537-.498-.548-.728-.557l-.62-.008c-.215 0-.566.08-.862.404-.296.323-1.13 1.103-1.13 2.69s1.157 3.12 1.319 3.336c.161.216 2.236 3.576 5.52 4.87 2.731 1.074 3.286.86 3.88.807.592-.054 1.912-.78 2.18-1.534.27-.753.27-1.399.19-1.534-.081-.134-.297-.215-.62-.376-.324-.162-1.913-.942-2.209-1.05-.296-.107-.511-.16-.727.162-.216.323-.835 1.05-1.023 1.264-.189.216-.377.243-.7.082-.324-.162-1.365-.502-2.6-1.601-.96-.856-1.609-1.912-1.798-2.234-.189-.323-.02-.498.142-.658.145-.145.323-.377.485-.565.162-.189.216-.323.323-.538.108-.215.054-.404-.027-.565-.08-.162-.709-1.757-.996-2.395" fill="#fff" />
    </symbol>
    <symbol viewBox="0 0 29 30" fill="none" id="colored_socials_viber">
      <path d="M25.24 2.926C24.494 2.238 21.482.051 14.77.022c0 0-7.913-.478-11.77 3.06C.852 5.232.096 8.374.017 12.27c-.08 3.896-.183 11.198 6.856 13.178h.006l-.004 3.021s-.045 1.223.76 1.473c.974.302 1.546-.627 2.476-1.63.51-.55 1.216-1.358 1.747-1.975 4.814.405 8.516-.521 8.937-.658.971-.315 6.471-1.02 7.366-8.321.923-7.526-.447-12.286-2.922-14.432Z" fill="#fff" />
      <path d="M23.675 4.446c-.63-.572-3.351-2.282-9.027-2.306 0 0-6.722-.442-9.985 2.514-1.816 1.793-2.428 4.46-2.495 7.713-.067 3.254-.415 9.47 5.537 11.124l-.026 5.02c0 .284.045.478.207.518.117.028.291-.032.44-.18.952-.956 3.998-4.632 3.998-4.632 4.089.267 7.341-.54 7.691-.654.825-.265 5.286-.648 6.04-6.747.78-6.286-.286-10.576-2.38-12.37Z" fill="#7360F2" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.886 9.735a.38.38 0 0 1 .399-.36c.783.041 1.435.278 1.899.758.462.478.689 1.146.728 1.948a.38.38 0 0 1-.758.037c-.034-.686-.222-1.153-.516-1.457-.292-.302-.737-.493-1.393-.527a.38.38 0 0 1-.359-.4Z" fill="#fff" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.311 7.69a.38.38 0 0 1 .406-.35c1.594.116 2.877.66 3.811 1.68.925 1.01 1.364 2.267 1.332 3.74a.38.38 0 1 1-.759-.016c.028-1.3-.354-2.36-1.133-3.21-.776-.848-1.861-1.332-3.306-1.438a.38.38 0 0 1-.35-.406Z" fill="#fff" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.64 5.747a.38.38 0 0 1 .383-.377c2.202.015 4.064.748 5.559 2.205 1.507 1.47 2.252 3.47 2.271 5.954a.38.38 0 1 1-.759.006c-.018-2.328-.71-4.118-2.042-5.416-1.345-1.31-3.014-1.976-5.034-1.99a.38.38 0 0 1-.377-.382Z" fill="#fff" />
      <path
        d="M15.34 17.183s.534.045.821-.31l.56-.704c.27-.349.922-.572 1.56-.216.479.273.944.57 1.392.888.424.312 1.291 1.036 1.294 1.036.413.349.509.861.227 1.401l-.002.012a5.846 5.846 0 0 1-1.165 1.435c-.006.002-.006.005-.01.008-.402.335-.797.526-1.184.572a.782.782 0 0 1-.173.01c-.171.002-.341-.024-.503-.077l-.013-.018c-.597-.168-1.594-.59-3.254-1.505a19.367 19.367 0 0 1-2.733-1.806c-.43-.34-.841-.704-1.23-1.09l-.041-.042-.041-.041-.042-.042-.041-.041c-.387-.389-.751-.8-1.09-1.23a19.391 19.391 0 0 1-1.806-2.733c-.916-1.66-1.337-2.656-1.506-3.254l-.018-.012a1.575 1.575 0 0 1-.077-.504.76.76 0 0 1 .01-.172c.048-.387.24-.782.573-1.185.003-.005.006-.005.008-.01a5.831 5.831 0 0 1 1.436-1.165l.01-.003c.541-.281 1.053-.186 1.402.225.002.003.725.87 1.035 1.294.32.449.616.914.889 1.393.355.638.133 1.29-.217 1.56l-.704.56c-.356.287-.309.82-.309.82s1.043 3.95 4.943 4.946Z"
        fill="#fff"
      />
    </symbol>
    <symbol viewBox="0 0 26 26" fill="none" id="colored_socials_vk"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.546 1.546C0 3.093 0 5.582 0 10.56v4.88c0 4.978 0 7.467 1.546 9.013C3.093 26 5.582 26 10.56 26h4.88c4.978 0 7.467 0 9.013-1.547C26 22.907 26 20.418 26 15.44v-4.88c0-4.978 0-7.467-1.547-9.014C22.907 0 20.418 0 15.44 0h-4.88C5.582 0 3.093 0 1.546 1.546ZM4.491 8.45c.14 6.763 3.698 10.826 9.565 10.826h.34v-3.853c2.136.216 3.73 1.794 4.38 3.853h3.08c-.835-3.08-3.002-4.787-4.348-5.437 1.345-.804 3.25-2.743 3.698-5.389h-2.802c-.588 2.152-2.338 4.105-4.009 4.29V8.45h-2.847v7.505c-1.733-.433-3.993-2.537-4.086-7.505H4.491Z" fill="#0073E6" /></symbol>
    <symbol id="colored_familycard" viewBox="0 0 32 32">
      <path
        d="M22.183 17.443c-.238-1.106-.723-1.907-.955-2.292a2.697 2.697 0 0 1-.092-.16c-.085-.204-.074-.355-.06-.563.01-.136.02-.288.006-.478-.017-.22-.057-.736-.424-1.168a1.604 1.604 0 0 0-1.082-.56c-.712-.044-1.171.488-1.659 1.05-.476.549-.967 1.116-1.765 1.32a2.395 2.395 0 0 1-.814.065l-.244-.015c-.395-.023-.799-.047-1.21-.068 1.047-.973 1.638-2.296 1.638-3.707 0-2.86-2.448-5.186-5.457-5.186-2.338 0-4.435 1.445-5.173 3.536-.21.011-.36.15-.468.25-.141.131-.223.2-.382.173-.085-.013-.122-.042-.2-.104-.098-.077-.232-.184-.493-.272-.678-.231-1.008-.088-1.165.071-.249.254-.182.645-.156.793.113.67 1.025 1.344 1.41 1.603.233.156.49.308.761.447-.235.183-.531.476-.642.827-.075.233-.078.432-.01.61.152.394.699.536 1.282.536.317 0 .645-.043.927-.109.292.363.639.69 1.02.966a5.686 5.686 0 0 0-1.782.799A5.58 5.58 0 0 0 3.33 17.62c-.655 1.178-.726 2.217-.672 2.88.189 2.254 2.023 3.548 2.58 3.892.244.149.52.226.803.226h11.734c.448 0 .878-.074 1.276-.22.123-.045.202-.083.273-.117 1.424-.7 2.177-1.92 2.432-2.414.041-.08 1.012-1.983.425-4.425l.002.001Z"
        fill="#F4BE31"
      />
      <path d="M3.877 9.603c-.078-.012-.113-.038-.184-.095-.09-.07-.213-.17-.453-.25-.623-.213-.926-.081-1.071.065-.228.234-.167.593-.143.73.104.614.942 1.234 1.296 1.472.214.144.451.283.7.411-.217.168-.49.437-.591.76-.068.214-.072.397-.009.561.14.361.642.492 1.179.492.098 0 .195-.005.294-.013.18-.033.366-.063.557-.087 2.687-.326 3.841-2.246 3.227-2.346-.615-.1-1.208-.676-1.208-.676-.715-.824-1.699-1.424-2.81-1.412-.193.01-.331.138-.43.23-.13.12-.204.182-.35.158h-.004Z" fill="#CC5B1B" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.191 7.99c-.322.01-.636.214-.828.427-.106.116-.269.128-.364.025-.096-.102-.088-.28.018-.397.269-.298.719-.602 1.218-.619.52-.017 1.027.278 1.402 1.044.063.13.012.305-.116.39-.127.085-.282.049-.345-.082-.314-.642-.684-.799-.985-.789ZM9.38 9.073c-.356.018-.678.234-.863.457a.28.28 0 0 1-.4.034.295.295 0 0 1-.034-.41c.258-.311.717-.633 1.269-.661.576-.029 1.18.264 1.7 1.044a.294.294 0 0 1-.076.404.281.281 0 0 1-.395-.077c-.436-.654-.868-.808-1.201-.79Z" fill="#7C582E" />
      <path d="M14.32 7.237c-.854-2.926-3.518-3.669-5.75-3.524-.404.027-.647.444-.58.85.25 1.492-.108 3.161-.409 3.423l4.587.744c.755.17 2.594.023 2.151-1.494Z" fill="#F4BE31" />
      <path
        d="M29.814 21.24c-.238-1.106-.723-1.907-.955-2.292a2.791 2.791 0 0 1-.092-.16c-.085-.205-.074-.355-.06-.564.01-.135.02-.287.006-.477-.017-.22-.058-.736-.424-1.169a1.605 1.605 0 0 0-1.082-.56c-.712-.043-1.171.489-1.659 1.05-.476.55-.967 1.117-1.765 1.322a2.397 2.397 0 0 1-.814.064l-.244-.015c-.395-.023-.799-.047-1.21-.068 1.047-.973 1.638-2.296 1.638-3.707 0-2.86-2.448-5.186-5.457-5.186-2.338 0-4.435 1.444-5.173 3.536-.21.011-.361.15-.468.25-.141.131-.223.199-.382.173-.085-.013-.122-.042-.2-.104-.098-.077-.232-.184-.493-.272-.678-.231-1.008-.088-1.165.071-.249.254-.182.645-.156.793.113.67 1.024 1.344 1.41 1.603.233.156.49.308.761.447-.235.182-.531.476-.643.827-.074.233-.077.432-.009.61.152.393.699.536 1.282.536.317 0 .645-.043.927-.109.292.363.639.69 1.02.966a5.684 5.684 0 0 0-1.782.799 5.581 5.581 0 0 0-1.664 1.813c-.655 1.178-.726 2.217-.672 2.88.189 2.253 2.023 3.548 2.58 3.892.244.148.52.226.803.226h11.734c.448 0 .878-.074 1.276-.22.123-.046.202-.083.273-.117 1.425-.7 2.177-1.92 2.432-2.414.041-.08 1.012-1.983.425-4.426l.002.002Z"
        fill="#FFD86A"
      />
      <path d="M11.673 13.44c-.085-.013-.122-.041-.2-.103-.098-.077-.232-.184-.493-.273-.678-.23-1.008-.088-1.165.072-.249.254-.182.644-.156.793.113.669 1.024 1.343 1.41 1.603.233.156.49.308.761.447-.235.182-.531.475-.643.827-.074.233-.077.432-.009.61.152.393.699.536 1.282.536.108 0 .213-.006.32-.015.197-.036.4-.068.607-.094 2.923-.355 4.18-2.445 3.51-2.554-.668-.109-1.313-.734-1.313-.734-.778-.897-1.849-1.55-3.057-1.537-.211.01-.361.15-.469.25-.14.13-.222.199-.381.172h-.004Z" fill="#E7651D" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.475 11.549c-.356.018-.678.234-.862.456a.28.28 0 0 1-.402.034.295.295 0 0 1-.033-.409c.259-.312.717-.634 1.27-.661.575-.03 1.18.264 1.699 1.044a.294.294 0 0 1-.076.403.281.281 0 0 1-.395-.076c-.436-.655-.868-.808-1.2-.791ZM17.011 12.869c-.356.018-.678.234-.863.457a.28.28 0 0 1-.4.034.294.294 0 0 1-.034-.41c.259-.311.717-.633 1.27-.661.575-.029 1.18.264 1.699 1.044a.294.294 0 0 1-.076.404.281.281 0 0 1-.395-.077c-.436-.655-.868-.808-1.2-.791Z" fill="#9F7747" />
    </symbol>
    <symbol id="colored_information_in_triangle" viewBox="0 0 32 32">
      <g clip-path="url(#clip0_2866_24144)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.258 6.077c3.388 4.398 7.703 11.886 9.411 14.927 1.708 3.04 2.886 7.063-3.765 7.463-6.65.4-11.67.533-17.191.533-5.522 0-6.777-2.399-4.894-6.797 1.882-4.398 4.768-10.795 7.905-15.46 3.138-4.664 5.145-5.064 8.534-.666Z" fill="#FF8716" />
        <path d="M17.16 14.729c.38-2.148.603-4.455-1.702-4.506-2-.043-2.046 1.487-2.1 3.316-.009.28-.018.566-.033.855-.011.203-.024.407-.037.61-.127 1.974-.247 3.852 1.525 3.907 1.689.053 1.929-1.506 2.208-3.317.043-.284.088-.574.14-.865ZM16.04 22.125c.062-.585.014-1.203-1.287-1.436-1.302-.232-1.53.536-1.55 1.176-.02.64.012 1.232 1.29 1.344 1.276.112 1.486-.5 1.547-1.084Z" fill="#fff" />
      </g>
      <defs>
        <clipPath id="clip0_2866_24144"><path fill="#fff" transform="translate(2 3)" d="M0 0h28v26H0z" /></clipPath>
      </defs>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" id="checkbox_fill"><path d="M18.8 15.5c-.4 1.7-1.8 3-3.4 3.4-1.3.3-3 .6-5.4.6-2.4 0-4.2-.3-5.4-.6-1.7-.4-3-1.7-3.4-3.4-.4-1.3-.7-3.1-.7-5.6 0-2.4.3-4.2.6-5.5.4-1.6 1.7-2.9 3.4-3.4C5.8.8 7.6.5 10 .5s4.2.3 5.5.6c1.6.4 2.9 1.7 3.3 3.3.4 1.3.7 3.2.7 5.6 0 2.4-.3 4.2-.7 5.5Z" /></symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" id="checkbox_stroke"><path d="M10 20c-2.1 0-4-.2-5.5-.6-1.8-.5-3.3-1.9-3.8-3.7C.2 14 0 12.1 0 10s.2-4 .7-5.6C1.2 2.6 2.6 1.2 4.4.7 6 .2 7.9 0 10 0c2.1 0 3.9.2 5.6.7 1.8.5 3.2 1.9 3.7 3.7.5 1.6.7 3.5.7 5.6 0 2.1-.2 4-.7 5.7-.5 1.8-2 3.3-3.8 3.7-1.6.4-3.4.6-5.5.6Zm0-19c-2 0-3.8.2-5.4.6-1.4.4-2.6 1.6-3 3C1.2 6.2 1 8 1 10c0 2 .2 3.9.6 5.4.4 1.5 1.6 2.7 3.1 3 1.5.4 3.3.6 5.3.6s3.8-.2 5.3-.6c1.5-.4 2.7-1.5 3.1-3 .4-1.6.6-3.4.6-5.4 0-2-.2-3.8-.7-5.4-.4-1.4-1.5-2.6-3-3C13.8 1.2 12 1 10 1Z" /></symbol>
  </svg>
</template>
<script>
export default {
  name: 'SymbolVue'
}
</script>

<style></style>
