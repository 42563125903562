<template>
  <section class="Bz w_4 xi">
    <div class="" :style="'max-height:' + height + '; overflow: hidden'">
      <h2 class="UC">
        <span style="vertical-align: inherit">
          <span style="vertical-align: inherit">{{$t('detail.specifications')}}</span>
          <span class="kj_yc" v-show="state" @click="onMore()">{{$t('_n2._yc')}}</span>
        </span>
      </h2>
      <table class="UG">
        <tbody>
          <tr class="UH" v-for="(item, i) in specs" :key="i">
            <th class="UK">
              <span class="UM">
                <span style="vertical-align: inherit">
                  <span style="vertical-align: inherit">{{ item.attrName }}</span>
                </span>
              </span>
            </th>
            <td class="UL">
              <div style="vertical-align: inherit">
                <div class="over-text">{{ item.attrValue }}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w_9 w_8">
      <div class="xa" @click="onMore()">
        <span class="UB">
          <span style="vertical-align: inherit">
            <span style="vertical-align: inherit">{{ state ? $t('_n2._yc') : $t('_n.ckgd') }}</span>
          </span>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'GoodsSpecs',
  props: {
    specs: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      state: false,
      height: '240px'
    }
  },
  methods: {
    onMore() {
      if (this.state) {
        this.height = '240px'
        this.state = false
      } else {
        this.height = 'auto'
        this.state = true
      }
    }
  }
}
</script>

<style scoped>
.sw_1:after {
  bottom: 100%;
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff);
}

.kj_yc {
  font-size: 17px;
  color: #0073e6;
  margin-left: 20px;
  cursor: pointer;
}
</style>
