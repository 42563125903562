<template>
  <picture class="HI">
    <source media="(min-width: 1024px)" srcset="@/static/svg/download.svg" type="image/svg" />
    <source media="(min-width: 600px) and (max-width: 1023px)" srcset="@/static/svg/download.svg" type="image/svg" />
    <source media="(max-width: 599px)" srcset="@/static/svg/download.svg" type="image/svg" />
    <img class="pV pX pW" :src="src" />
  </picture>
</template>

<script>
export default {
  name: 'ComPicture',
  props: ['src']
}
</script>

<style></style>
