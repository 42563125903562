//成为商家
import Layout from '../view/shop/layout.vue'
import ShopIndex from '../view/shop/index.vue'
import ShopSales from '../view/shop/sales.vue'

//成为商家
import Beseller from '../view/shop/beseller.vue'

const Router = [
  {
    path: '/shop',
    name: 'shop',
    component: Layout,
    redirect: '/shop/index',
    children: [
      {
        path: '/shop/index',
        name: 'shopIndex',
        component: ShopIndex
      },
      {
        path: '/shop/sales',
        name: 'shopSales',
        component: ShopSales
      },
      {
        path: '/beseller',
        name: 'beseller',
        component: Beseller,
        meta: { name: 'beseller' }
      }
    ]
  }
]
export default Router
