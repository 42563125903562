<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('adv.select.goods') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>

  <div :style="{ background: '#fff', padding: '20px 20px', minHeight: '800px' }">
    <div class="nav-content">
      <div style="display: flex; align-items: center">
        <a-button
          size="large"
          @click="
            () => {
              $router.go(-1)
            }
          "
        >
          <template #icon>
            <rollback-outlined />
          </template>
          {{ $t('adv.add.back') }}
        </a-button>
        <!-- <a-divider type="vertical" />
        <a-button size="large" @click="onTgShow()" hidden>
          <template #icon>
            <PlusCircleOutlined />
          </template>
          {{ $t('adv.tgye') }}({{ adv_balance }})
        </a-button>
        -->
        <a-divider type="vertical" />
        <!-- 充值点数 -->
        <div style="margin-left: 15px; font-size: 16px">
          {{ $t('adv.tgye') }}：<span style="font-weight: 700">${{ adv_balance }}</span>
        </div>
      </div>
      <div>
        <a-space :size="20">
          <div>{{ $t('products.total') }}:{{ pagination.total }}</div>
          <div>
            <a-cascader size="large" :style="{ width: '400px' }" v-model:value="queryParam.category" :options="categorys" :placeholder="$t('products.search.category')" />
          </div>
          <a-input-search :style="{ width: '400px' }" v-model:value="queryParam.search" :placeholder="$t('base.search.text')" :enter-button="$t('base.search')" size="large" @search="onSearch" />
        </a-space>
      </div>
    </div>
    <div style="margin-top: 10px">
      <div style="display: flex">
        <!-- <a-popconfirm :title="$t('products.ask.add')" :ok-text="$t('products.yes')" :cancel-text="$t('products.no')" @confirm="onAddGoods()" :disabled="selectedRowKeys.length == 0">
          <a-button type="primary" :disabled="selectedRowKeys.length == 0">
            <template #icon>
              <plus-circle-outlined />
            </template>
            {{$t('products.batch.add')}}
          </a-button>
        </a-popconfirm> -->
      </div>
      <a-table
        :rowKey="
          (record) => {
            return record.id
          }
        "
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="getAdvStoreGoods($event)"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="record && column.dataIndex === 'img'">
            <img :src="record.img" width="50" style="margin-right: 5px" />
          </template>
          <template v-if="record && column.dataIndex === 'name'">
            <div style="display: flex; align-items: center">
              <div style="font-size: 13px; height: 44px; overflow: hidden">
                {{ record.name }}
              </div>
            </div>
          </template>
          <template v-if="record && column.dataIndex === 'price'">
            <div>${{ record.price }}</div>
          </template>
          <template v-if="record && column.dataIndex === 'profit_value'">
            <div>
              <a-tag color="green"> ≈ ${{ record.profit_value }}</a-tag>
            </div>
          </template>
          <template v-else-if="column.key === 'action'">
            <span>
              <!-- <a>Detail</a>
              <a-divider type="vertical" /> -->
              <a href="javascript:" style="color: #f90" @click="onSelect(record)">{{ $t('adv.select.btn') }}</a>
            </span>
          </template>
        </template>
      </a-table>
    </div>

    <!-- 充值推广 -->
    <a-modal v-model:visible="rechargeVisible" style="width: 700px" :title="$t('adv.recharge.title')">
      <div>
        <a-form ref="recharge" :model="formRecharge" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" autocomplete="off">
          <!-- 余额 -->
          <a-form-item :label="$t('adv.recharge.balance')">
            <div>{{ balance }} {{ $t('adv.point') }}</div>
          </a-form-item>
          <!-- 点数比例 -->
          <a-form-item :label="$t('adv.point.rate')">
            <div>1 USD = {{ adv_point_rate }} {{ $t('adv.point') }}</div>
          </a-form-item>
          <!-- 充值点数 -->
          <a-form-item :label="$t('adv.recharge.amount')" name="amount" :rules="[{ required: true, message: $t('adv.recharge.input.amount') }]">
            <a-input v-model:value="formRecharge.amount" suffix="USD" @input="onValue($event)" :placeholder="$t('adv.recharge.input.amount')" />
          </a-form-item>
        </a-form>
      </div>
      <template #footer>
        <a-button key="submit" type="primary" :loading="rechargeLoad" @click="onRecharge()">{{ $t('advertise.shop.modal.btn') }}</a-button>
      </template>
    </a-modal>

    <!-- 弹框 -->
    <a-modal v-model:visible="visible" style="width: 900px" :title="$t('adv.select.btn')">
      <div>
        <a-form ref="form" :model="formState" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" autocomplete="off">
          <!-- 余额 -->
          <a-form-item :label="$t('adv.syye')">
            <div>
              $ {{ balance }}
              <!-- {{ $t('adv.point') }}-->
            </div>
          </a-form-item>
          <!-- 余额 -->
          <a-form-item :label="$t('adv.tgye')">
            <div>
              $ {{ adv_balance }}
              <!-- {{ $t('adv.point') }}-->
            </div>
          </a-form-item>
          <!-- 选择板块 -->
          <a-form-item :label="$t('adv.select.mod')">
            <a-radio-group v-model:value="formState.type" name="radioGroup" @change="onType">
              <a-radio value="1">{{ $t('adv.mod1') }}</a-radio>
              <a-radio value="2">{{ $t('adv.mod2') }}</a-radio>
              <a-radio value="3">{{ $t('adv.mod3') }}</a-radio>
              <a-radio value="4">{{ $t('adv.mod4') }}</a-radio>
              <a-radio value="5">{{ $t('adv.mod5') }}</a-radio>
              <a-radio value="6">{{ $t('adv.mod6') }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <!-- 板块图片 -->
          <a-form-item :label="$t('adv.xzbk')">
            <img style="width: 120px" :src="require('../../../static/mod/m' + formState.type + '.png')" alt="" srcset="" />
          </a-form-item>
          <!-- 上推时间 -->
          <a-form-item :label="$t('adv.syme')">
            <div>{{ currentAdv.total }} {{ $t('adv.ge') }}</div>
          </a-form-item>
          <!-- 上推时间 -->
          <a-form-item :label="$t('adv.tgsc')">
            <div>{{ currentCfg.hour }} {{ $t('adv.xs') }}</div>
          </a-form-item>
          <!-- 消费点数 -->
          <a-form-item :label="$t('adv.tgjg')">
            <div>
              $ {{ currentCfg.price }}
              <!-- {{ $t('adv.point') }} -->
            </div>
          </a-form-item>
        </a-form>
      </div>
      <template #footer>
        <a-button key="submit" type="primary" :loading="btnLoad" @click="onSave">{{ $t('advertise.shop.modal.btn') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { RollbackOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  name: 'productsVue',
  components: {
    RollbackOutlined
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: false,
          key: 'id',
          width: '4%'
        },
        {
          title: this.$t('products.table.img'),
          dataIndex: 'img',
          width: '5%'
        },
        {
          title: this.$t('products.table.name'),
          dataIndex: 'name',
          width: '20%'
        },
        {
          title: this.$t('products.table.category'),
          dataIndex: 'cate_name',
          width: '10%'
        },
        {
          title: this.$t('products.table.price'),
          dataIndex: 'price',
          width: '10%'
        },
        {
          title: this.$t('products.table.profit'),
          dataIndex: 'profit_value',
          width: '30%'
        },
        {
          title: this.$t('products.table.action'),
          key: 'action',
          width: '10%'
        }
      ],
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 10 },
      dataSource: [],
      queryParam: {
        search: '',
        category: []
      },

      //筛选
      categorys: [],

      //------------------
      //充值推广
      formRecharge: {
        amount: ''
      },
      rechargeVisible: false,
      rechargeLoad: false,
      adv_point_rate: 0,

      //-------------------
      //当前商品
      current: {},
      //提交信息
      formState: {
        type: '1'
      },
      //弹框显示
      visible: false,
      //加载中
      btnLoad: false,

      //当前推广信息
      AdvList: {},
      currentAdv: {},

      //推广点数
      balance: 0,
      adv_balance: 0,
      advConfig: [],
      currentCfg: {}
    }
  },
  created() {
    this.getAdvStoreGoods(this.pagination)

    // 获取分类
    const that = this
    this.$api.category.getCategorys().then((res) => {
      that.categorys = res.data.options
    })
  },
  methods: {
    onRecharge() {
      const that = this
      this.$refs.recharge.validateFields().then((values) => {
        console.log(values)

        that.rechargeLoad = true

        this.$message.loading({
          content: 'submit...',
          key: 'msg',
          duration: 50
        })

        that.$api.store
          .rechargeAdv(values)
          .then((res) => {
            that.rechargeLoad = false

            that.rechargeVisible = false

            that.formRecharge.amount = ''

            that.$message.success({
              content: res.msg,
              key: 'msg'
            })
            that.getAdvList()
          })
          .catch(() => {
            that.rechargeLoad = false
          })
      })
    },
    // 输入金额
    onValue(e) {
      console.log(e, this.formState.value)
    },
    onTgShow() {
      this.rechargeVisible = true
    },
    onType(e) {
      this.currentCfg = this.advConfig[e.target.value - 1]
      this.currentAdv = this.AdvList[e.target.value]

      console.log(this.currentCfg, this.currentAdv)
    },
    onSelect(record) {
      this.getAdvList()
      this.current = record
      this.visible = true
    },
    getAdvList() {
      this.$api.store.getAdvList().then((res) => {
        this.advConfig = res.data.adv_config
        this.AdvList = res.data.list
        this.balance = res.data.balance
        this.adv_balance = res.data.adv_balance
        this.adv_point_rate = res.data.adv_point_rate
        this.currentAdv = this.AdvList[this.formState.type]
        this.currentCfg = this.advConfig[parseInt(this.formState.type) - 1]
      })
    },
    /**
     * 添加单个商品
     * @param {*} record
     */
    onSave() {
      const that = this
      this.$refs.form.validateFields().then((values) => {
        values.type = that.formState.type
        values.goods_id = that.current.id
        console.log(values)
        that.btnLoad = true
        this.$message.loading({
          content: 'submit...',
          key: 'msg',
          duration: 50
        })
        that.$api.store
          .payAdv(values)
          .then((res) => {
            that.btnLoad = false
            that.visible = false
            that.formState.type = '1'
            that.$message.success({
              content: res.msg,
              key: 'msg'
            })
            that.getAdvList()
            that.getAdvStoreGoods(that.pagination)
          })
          .catch(() => {
            that.btnLoad = false
          })
      })
    },
    /**
     * 搜索
     */
    onSearch() {
      this.getAdvStoreGoods(this.pagination)
    },
    /**
     * 获取仓库产品
     * @param {*} parameter
     */
    getAdvStoreGoods(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.store.getAdvStoreGoods(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data

        that.adv_balance = res.data.adv_balance
      })
    }
  }
})
</script>

<style scoped src="@/static/css/store-css.css"></style>
