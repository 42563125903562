import request from "@/utils/request";

const api = {
  sendCode: "code/sendCode",
  checkCode: "code/checkCode",
};

export default {
  sendCode(parameter) {
    return request({
      url: api.sendCode,
      method: "post",
      data: parameter,
    });
  },
  checkCode(parameter) {
    return request({
      url: api.checkCode,
      method: "post",
      data: parameter,
    });
  },
};
