import request from '@/utils/request'

const api = {
  getOrderTaskList: 'orderTask/getOrderTaskList',
  getStoreTaskList: 'orderTask/getStoreTaskList',
  getTaskContent: 'orderTask/getTaskContent',
  joinTask: 'orderTask/joinTask',
  getMyJoinTaskList: 'orderTask/getMyJoinTaskList',
  getTaskContentJoinList: 'orderTask/getTaskContentJoinList'
}
export default {
  getOrderTaskList(parameter) {
    return request({
      url: api.getOrderTaskList,
      method: 'post',
      data: parameter
    })
  },
  getTaskContentJoinList(parameter) {
    return request({
      url: api.getTaskContentJoinList,
      method: 'post',
      data: parameter
    })
  },
  getMyJoinTaskList(parameter) {
    return request({
      url: api.getMyJoinTaskList,
      method: 'post',
      data: parameter
    })
  },
  getStoreTaskList(parameter) {
    return request({
      url: api.getStoreTaskList,
      method: 'post',
      data: parameter
    })
  },
  getTaskContent(parameter) {
    return request({
      url: api.getTaskContent,
      method: 'post',
      data: parameter
    })
  },
  joinTask(parameter) {
    return request({
      url: api.joinTask,
      method: 'post',
      data: parameter
    })
  }
}
