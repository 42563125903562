<template>
  <a-modal v-model:visible="RechargeVisible" :title="$t('_tx._yhktx')" :style="{ width: '800px' }">
    <!-- 提交信息 -->
    <div :style="{ padding: '0 20px' }">
      <a-form ref="form" :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off">
        <!-- bank_name -->
        <a-form-item :label="$t('bank.title')" name="bank_name" :rules="[{ required: true, message: $t('_tx._sryhmc') }]">
          <a-input v-model:value="params.bank_name" type="text" :placeholder="$t('_tx._sryhmc')" />
        </a-form-item>
        <!-- user_name -->
        <a-form-item :label="$t('bank.name')" name="user_name" :rules="[{ required: true, message: $t('_tx._srskrmc') }]">
          <a-input v-model:value="params.user_name" type="text" :placeholder="$t('_tx._srskrmc')" />
        </a-form-item>
        <!-- account -->
        <a-form-item :label="$t('bank.account')" name="account" :rules="[{ required: true, message: $t('_tx._srskzh') }]">
          <a-input v-model:value="params.account" type="text" :placeholder="$t('_tx._srskzh')" />
        </a-form-item>
        <!-- routing -->
        <a-form-item :label="$t('bank.routing')" name="routing" :rules="[{ required: true, message: $t('_tx._sryhluh') }]">
          <a-input v-model:value="params.routing" type="text" :placeholder="$t('_tx._sryhluh')" />
        </a-form-item>
        <!-- code -->
        <a-form-item :label="$t('bank.code')" name="code" :rules="[{ required: true, message: $t('_tx._sryhdm') }]">
          <a-input v-model:value="params.code" type="text" :placeholder="$t('_tx._sryhdm')" />
        </a-form-item>
        <!-- bankaddress -->
        <a-form-item :label="$t('bank.bankaddress')" name="bank_address" :rules="[{ required: true, message: $t('_tx._sryhdz') }]">
          <a-input v-model:value="params.bank_address" type="text" :placeholder="$t('_tx._sryhdz')" />
        </a-form-item>
        <!-- amount -->
        <a-form-item :label="$t('_tx._txje')" name="amount" :rules="[{ required: true, message: $t('_tx._srtxje') }]">
          <a-input prefix="$" v-model:value="params.amount" type="number" :step="0.00000001" :placeholder="$t('_tx._srtxje')" />
        </a-form-item>
        <!-- 备注 -->
        <a-form-item :label="$t('withdrawal.notice')" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }" style="margin-top: 10px">
          <div style="padding: 5px 0; line-height: 23px; color: #999">
            <div>{{ $t('_tx._txkc', { _sxf: transfer_bank_fee || 10 }) }}</div>
          </div>
        </a-form-item>
        <!-- 备注 -->
        <a-form-item :label="$t('withdrawal.notice')" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }" style="margin-top: 10px">
          <div style="padding: 5px 0; line-height: 23px; color: #999">
            <div>{{ $t('wallet.desc.text') }}</div>
          </div>
        </a-form-item>
        <!-- <a-form-item :wrapper-col="{ offset:10, span: 16 }">
          <a-button type="primary" html-type="submit">{{ $t('bank.submit') }}</a-button>
        </a-form-item> -->
      </a-form>
    </div>
    <template #footer>
      <a-button type="primary" :loading="loadingBtn" @click="onSubmit">{{ $t('bank.submit') }}</a-button>
    </template>
  </a-modal>
</template>

<script>
// import UploadFile from '@/components/UploadFile.vue'
export default {
  name: 'RechargeVue',
  components: {
    // UploadFile
  },
  props: ['transfer_bank_fee'],
  emits: ['Success'],
  data() {
    return {
      //存币
      RechargeVisible: false,

      // 提交信息
      params: {
        bank_name: '',
        user_name: '',
        account: '',
        routing: '',
        code: '',
        bank_address: '',
        amount: 1
      },

      // 提交
      loadingBtn: false
    }
  },
  created() {},
  methods: {
    /**
     * 存币
     */
    show() {
      this.params = {
        bank_name: '',
        user_name: '',
        account: '',
        routing: '',
        code: '',
        bank_address: '',
        amount: ''
      }
      this.RechargeVisible = true
    },
    hide() {
      this.RechargeVisible = false
    },
    onSubmit() {
      const that = this

      this.$refs.form
        .validateFields()
        .then((res) => {
          console.log('value', res)

          if (that.loadingBtn) {
            return
          }
          that.loadingBtn = true

          //提交到后台
          that.$api.store
            .transferBankCard(that.params)
            .then((res) => {
              that.loadingBtn = false
              if (res.code == 0) {
                that.$message.success(res.msg)
                setTimeout(function () {
                  that.hide()
                  that.$emit('Success')
                }, 500)
              }
            })
            .catch((err) => {
              err
              that.loadingBtn = false
            })
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
    }
  }
}
</script>

<style></style>
