<template>
    <div class="nav-header">
    <span class="nv-title">MESSAGE</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <div :style="{ background: '#fff',maxWidth:'900px',margin:'0 auto' }">
    <chat></chat>
  </div>
</template>

<script>
import Chat from '@/components/Chat.vue'
export default {
  components: { Chat },
  name: "storeMessage"
}
</script>

<style>
.nav-header {
    display: flex;
    flex-direction: column;
    padding: 0 0px 20px 0px;
}

.nav-header .nv-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
}
</style>