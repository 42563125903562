import { createRouter, createWebHashHistory } from 'vue-router'
import common from '@/utils/common'
import Base from './base.js'
import Order from './order.js'
import Shop from './shop.js'
import Store from './store.js'
import Member from './member.js'

const routes = [
  //基础
  ...Base,
  //订单
  ...Order,
  //店铺信息
  ...Shop,
  //会员
  ...Member,
  //店铺
  ...Store
]

// console.log(routes)

// const InitRouter = () => {
//   let router = createRouter({
//     history: createWebHashHistory(),
//     routes
//   })

//   return router
// }

// const router = InitRouter()

//createWebHashHistory()
//createWebHistory
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 导航守卫
let isload = false
router.beforeEach(async (to, from, next) => {
  //目的地是否需要登录
  if (to.meta.isLogin) {
    const token = common.isLogin()
    if (to.name !== 'index' && !token) {
      return next({ name: 'index' })
    }
  }

  if (to.meta.type) {
    const info = common.getInfo()
    //console.log(to.meta, info);
    if (to.meta.type !== info.type) {
      return next({ name: 'index' })
    }
  }

  if (isload) {
    // console.log('加载路由1', to)
    return next()
  }
  // console.log('加载路由2', to)

  isload = true

  next({ ...to })
})

router.afterEach(() => {
  // console.log('切换路由')
  //console.log(to, from, next);
  window.scrollTo(0, 0)
})

export default router
