<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('adv.list.title') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <div :style="{ background: '#fff', padding: '20px 20px' }">
    <!-- 第一层 -->
    <div class="nav-content">
      <div style="display: flex; align-items: center">
        <a-button
          size="large"
          @click="
            () => {
              $router.push({ name: 'storeAdvertiseAdd' })
            }
          "
        >
          <template #icon>
            <PlusCircleOutlined />
          </template>
          {{ $t('adv.add.products') }}
        </a-button>
        <a-divider type="vertical" />
        <!-- 充值点数 -->
        <div style="margin-left: 15px; font-size: 16px">
          {{ $t('adv.tgye') }}：<span style="font-weight: 700">${{ adv_balance }}</span>
        </div>
      </div>
      <!-- 右侧搜索框 -->
      <!-- <div>
        <a-space :size="20">
          <div>{{ $t('products.total') }}:{{ pagination.total }}</div>
          <div>
            <a-cascader size="large" :style="{ width: '400px' }" v-model:value="queryParam.category" :options="categorys" :placeholder="$t('products.search.category')" />
          </div>
          <a-input-search :style="{ width: '400px' }" v-model:value="queryParam.search" :placeholder="$t('base.search.text')" :enter-button="$t('base.search')" size="large" @search="onSearch" />
        </a-space>
      </div> -->
    </div>
    <!-- 第二层 -->
    <div class="nav-content">
      <div style="display: flex; align-items: center">

      </div>
      <!-- 右侧搜索框 -->
      <div>
        <a-space :size="20">
          <div>{{ $t('products.total') }}:{{ pagination.total }}</div>
          <div>
            <a-cascader size="large" :style="{ width: '300px' }" v-model:value="queryParam.category" :options="categorys" :placeholder="$t('products.search.category')" />
          </div>
          <a-input-search :style="{ width: '300px' }" v-model:value="queryParam.search" :placeholder="$t('base.search.text')" :enter-button="$t('base.search')" size="large" @search="onSearch" />
        </a-space>
      </div>
    </div>
    <div style="margin-top: 10px">
      <div style="display: flex">
        <!-- <a-popconfirm
          :title="$t('products.ask.delete')"
          :ok-text="$t('products.yes')"
          :cancel-text="$t('products.no')"
          @confirm="onDeleteGoods()"
          :disabled="selectedRowKeys.length == 0"
        >
          <a-button type="primary" :disabled="selectedRowKeys.length == 0">
            <template #icon>
              <close-circle-outlined />
            </template>
            {{ $t('products.batch.delete') }}
          </a-button>
        </a-popconfirm> -->
      </div>
      <a-table
        :rowKey="
          (record) => {
            return record.id
          }
        "
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="getMyAdvStoreGoods"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="record && column.dataIndex === 'img'">
            <img :src="record.img" width="50" style="margin-right: 5px" />
          </template>
          <template v-if="record && column.dataIndex === 'name'">
            <div style="display: flex; align-items: center">
              <div style="font-size: 13px; height: 44px; overflow: hidden">{{ record.name }}</div>
            </div>
          </template>
          <template v-if="record && column.dataIndex === 'price'">
            <div>${{ record.price }}</div>
          </template>
          <template v-if="record && column.dataIndex === 'profit_value'">
            <div>
              <a-tag color="green"> ≈ ${{ record.profit_value }}</a-tag>
            </div>
          </template>
          <template v-if="record && column.dataIndex === 'type'">
            <div>
              {{ typeStr[record.type].title }}
            </div>
          </template>
          <template v-if="record && column.dataIndex === 'status'">
            <div>
              <a-tag color="green" v-if="record.status == 2"> {{ $t('adv.status.s1') }}</a-tag>
              <a-tag color="red" v-else> {{ $t('adv.status.s2') }}</a-tag>
            </div>
          </template>
          <template v-else-if="column.key === 'action'">
            <span>
              <!-- <a>Detail</a>
              <a-divider type="vertical" /> -->
            </span>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { PlusCircleOutlined /*CloseCircleOutlined*/ } from '@ant-design/icons-vue'
export default defineComponent({
  name: 'productsVue',
  components: {
    PlusCircleOutlined
    // CloseCircleOutlined
  },
  setup() {},
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'store_goods_id',
          sorter: false,
          width: '5%'
        },
        {
          title: this.$t('products.table.img'),
          dataIndex: 'img',
          width: '5%'
        },
        {
          title: this.$t('products.table.name'),
          dataIndex: 'name',
          width: '20%'
        },
        {
          title: this.$t('products.table.category'),
          dataIndex: 'cate_name',
          width: '10%'
        },
        {
          title: this.$t('products.table.price'),
          dataIndex: 'price',
          width: '5%'
        },
        {
          title: this.$t('products.table.profit'),
          dataIndex: 'profit_value',
          width: '5%'
        },
        {
          title: this.$t('adv.type'),
          dataIndex: 'type',
          width: '6%'
        },
        {
          title: this.$t('adv.begin.time'),
          dataIndex: 'begin_time',
          width: '10%'
        },
        {
          title: this.$t('adv.end.time'),
          dataIndex: 'end_time',
          width: '10%'
        },
        {
          title: this.$t('adv.status'),
          dataIndex: 'status',
          width: '5%'
        }
      ],
      typeStr: {
        1: { title: this.$t('adv.mod1') },
        2: { title: this.$t('adv.mod2') },
        3: { title: this.$t('adv.mod3') },
        4: { title: this.$t('adv.mod4') },
        5: { title: this.$t('adv.mod5') },
        6: { title: this.$t('adv.mod6') }
      },
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 20 },
      dataSource: [],
      queryParam: {
        search: '',
        category: []
      },

      //筛选
      categorys: [],

      //批量选中
      selectedRowKeys: [],
      selectedRows: [],

      //推广余额
      adv_balance: 0
    }
  },
  created() {
    this.getMyAdvStoreGoods(this.pagination)

    // 获取分类
    const that = this
    this.$api.category.getCategorys().then((res) => {
      that.categorys = res.data.options
    })
  },
  methods: {
    /**
     * 选中框
     * @param {*} selectedRowKeys
     * @param {*} selectedRows
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      console.log(selectedRowKeys, selectedRows)
    },
    onDelete(record) {
      this.$message.loading({ content: 'delete...', key: 'msg', duration: 50 })
      let that = this
      this.$api.store.rmStoreProduct({ ids: [record.id] }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: 'msg'
        })
        that.getStoreProducts(that.pagination)
      })
    },
    /**
     * 批量添加
     */
    onDeleteGoods() {
      this.$message.loading({ content: 'delete...', key: 'msg', duration: 50 })
      let that = this
      this.$api.store.rmStoreProduct({ ids: this.selectedRowKeys }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: 'msg'
        })
        that.selectedRowKeys = []
        that.getMyAdvStoreGoods(that.pagination)
      })
    },
    onAdd() {
      this.$router.push({ name: 'storeAdvertiseAdd' })
    },
    onSearch() {
      this.getMyAdvStoreGoods(this.pagination)
    },
    getMyAdvStoreGoods(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.store.getMyAdvStoreGoods(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data

        that.adv_balance = res.data.adv_balance
      })
    }
  }
})
</script>

<style scoped src="@/static/css/store-css.css"></style>
