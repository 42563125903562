import Layout from '../view/index/layout.vue'
import Index from '../view/index/index.vue'
import Category from '../view/index/category.vue'
import Detail from '../view/index/detail.vue'
import Docs from '../view/index/docs.vue'
import Forgot from '../view/index/forgot.vue'
import Qualification from '../view/index/qualification.vue'

const Router = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: Index
      },
      {
        path: '/category',
        name: 'category',
        component: Category
      },
      {
        path: '/detail',
        name: 'detail',
        component: Detail
      },
      {
        path: '/docs',
        name: 'docs',
        component: Docs
      },
      {
        path: '/qualification',
        name: 'qualification',
        component: Qualification
      }
    ]
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: Forgot
  }
]
export default Router
