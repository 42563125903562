<template>
  <section class="Br">
    <div class="Bu">
      <!-- 商品轮播图 -->
      <div class="_M" data-testid="pictureBlock">
        <!-- 左侧缩略图 -->
        <div class="bdr" style="--itemWidth: 124px; --itemHeight: 124px" data-testid="miniaturesList">
          <div class="bdt">
            <button type="button" role="button" data-testid="miniaturesUp" class="fS fW ga f_1 gh gm sl-prev">
              <div class="fU">
                <div class="fV">
                  <i class="eO eS" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                      <use xlink:href="#arrow_short_top"></use>
                    </svg>
                  </i>
                </div>
              </div>
            </button>
            <swiper
              direction="vertical"
              :modules="modules"
              :spaceBetween="8"
              slidesPerView="auto"
              :slidesPerGroup="1"
              :watchSlidesProgress="true"
              :slideToClickedSlide="true"
              :centeredSlides="true"
              :loop="true"
              :navigation="{
                nextEl: '.sl-next',
                prevEl: '.sl-prev'
              }"
              @swiper="setThumbsSwiper"
              @click="onClick"
            >
              <swiper-slide :class="['bdv']" v-for="(vo, i) in imgs" :key="i">
                <div class="pU p_0 bdw">
                  <picture class="HI">
                    <source srcset="@/static/svg/download.svg" type="image/svg" />
                    <img class="pV pX" :src="vo.img" fetchpriority="auto" width="124" height="124" />
                  </picture>
                </div>
              </swiper-slide>
            </swiper>
            <button type="button" role="button" data-testid="miniaturesDown" class="fS fW ga f_1 gh gm sl-next">
              <div class="fU">
                <div class="fV">
                  <i class="eO eS" aria-hidden="true"
                    ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP"><use xlink:href="#arrow_short_bottom"></use></svg>
                  </i>
                </div>
              </div>
            </button>
          </div>
        </div>
        <!-- 右侧原图 -->
        <div class="bcY" style="--thumbsSize: 124px">
          <div class="bcZ" style="position: relative;">
            <div class="bc_ swiper-backface-hidden" style="position: absolute;z-index: 10;" v-if="attrSkuImg?.id">
              <div class="bc_4">
                <div class="pU p_0 bc_8 p_4">
                  <picture class="HI">
                    <source srcset="@/static/svg/download.svg" type="image/svg" />
                    <img class="pV bc_1 pX" :src="attrSkuImg.img" fetchpriority="high" width="700" height="700" style="transform: translate3d(0px, 0px, 0px) scale(1)" />
                  </picture>
                </div>
              </div>
            </div>
            <swiper :modules="modules_img" effect="fade" :spaceBetween="8" :watchSlidesProgress="true" :thumbs="{ swiper: thumbsSwiper }" class="bc_ swiper-backface-hidden">
              <swiper-slide v-for="(vo, i) in imgs" :key="i">
                <div class="bc_4">
                  <div class="pU p_0 bc_8 p_4">
                    <picture class="HI"
                      ><source srcset="@/static/svg/download.svg" type="image/svg" />
                      <img class="pV bc_1 pX" :src="vo.img" fetchpriority="high" width="700" height="700" style="transform: translate3d(0px, 0px, 0px) scale(1)"
                    /></picture>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'swiper/css/effect-fade'
import 'swiper/css/thumbs'
import { Navigation, Thumbs, Pagination, EffectFade } from 'swiper'
export default {
  name: 'GoodsBannerVue',
  props: {
    imgs: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      //轮播参数
      thumbsSwiper: null,
      modules: [Pagination, Navigation],
      modules_img: [Navigation, EffectFade, Thumbs],

      attrSkuImg: {}
    }
  },
  methods: {
    setThumb(attrSkuImg) {
      this.attrSkuImg = attrSkuImg
    },
    onClick(){
      this.attrSkuImg = {}
    },
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper
    }
  }
}
</script>

<style></style>
