<template>
  <div class="AD_0">
    <div class="AD_B">
      <!-- <h2 class="Cg">
        <span style="vertical-align: inherit">
          <span style="vertical-align: inherit">同类产品</span>
        </span>
      </h2> -->
      <div class="Jd" style="padding: 0 20px">
        <swiper
          :modules="modules"
          :slidesPerView="4"
          :slidesPerGroup="1"
          :spaceBetween="16"
          :navigation="{
            nextEl: '.sl3-next',
            prevEl: '.sl3-prev'
          }"
          :autoplay="{
            delay: 3000,
            disableOnInteraction: false
          }"
          class="Jl"
        >
          <swiper-slide class="_7_2" v-for="(vo, i) in list6" :key="i">
            <section id="6008774" class="Ig Ij Im _7_3">
              <div class="_7_6 _1T">
                <div class="Rd Rg">
                  <div class="Re">
                    <!-- <button type="button" role="button" class="fS fW ga f_ gp gm">
                      <div class="fU">
                        <div class="fV">
                          <span class="_8a"
                            ><i class="eO eS _7_8 _7_9 _8d" aria-hidden="true"
                              ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                <use xlink:href="#heartHollowBg"></use></svg></i
                          ></span>
                        </div>
                        <div class="fV">
                          <span class="_8f">
                            <span style="vertical-align: inherit">
                              <span style="vertical-align: inherit">B最爱</span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </button> -->
                  </div>
                </div>
              </div>
              <div class="AD_bgu AD_bgx AD_bgy">
                <div class="pU p_0 bgw">
                  <picture class="HI">
                    <source media="(min-width: 1024px)" srcset="@/static/svg/download.svg" type="image/svg" />
                    <source media="(min-width: 600px) and (max-width: 1023px)" srcset="@/static/svg/download.svg" type="image/svg" />
                    <source media="(max-width: 599px)" srcset="@/static/svg/download.svg" type="image/svg" />
                    <img class="pV pX pW" :src="vo.goods && vo.goods.img" fetchpriority="auto" style="width: 100%" />
                  </picture>
                </div>
              </div>
              <div class="bg_0 bg_1">
                <div class="bg_2">
                  <span class="bg_3">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">$ {{ vo.goods && vo.goods.price }}</span>
                    </span>
                  </span>
                </div>
              </div>
              <router-link :to="{ name: 'detail', query: { id: vo.id } }" class="Iq">
                <span class="Ip2">
                  <span style="vertical-align: inherit">
                    <span style="vertical-align: inherit">{{ vo.goods && vo.goods.name }}</span>
                  </span>
                </span>
              </router-link>
            </section>
          </swiper-slide>
        </swiper>
        <button type="button" role="button" class="fS Je _7_5 gg ga f_1 gn gi gu gt sl3-prev">
          <div class="fU">
            <div class="fV">
              <i class="eO eS" aria-hidden="true"
                ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP"><use xlink:href="#arrow_short_left"></use></svg>
              </i>
            </div>
          </div>
        </button>
        <button type="button" role="button" class="fS Jf _7_4 gg ga f_1 gn gi gu gt sl3-next">
          <div class="fU">
            <div class="fV">
              <i class="eO eS" aria-hidden="true"
                ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                  <use xlink:href="#arrow_short_right"></use>
                </svg>
              </i>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Navigation, Pagination, Autoplay } from 'swiper'
export default {
  name: 'GoodsFeaturedVue',
  props: {
    store_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      modules: [Pagination, Navigation, Autoplay]
    }
  },
  watch: {
    store_id(id) {
      console.log(id, 'store_id')
    }
  },
  created() {},
  computed: {
    ...mapState({
      list6: (state) => state.goods.list6
    })
  },
  methods: {}
}
</script>

<style scoped>
.AD_0 {
  border-radius: 16px;
  width: 100%;
  /* height: 241px; */
  padding-top: 0;
  background-color: #f5f5f5;
}

.AD_B {
  padding: 20px 0;
}

.AD_bgx.AD_bgy,
.AD_bgx.AD_bgz {
  margin-left: -6px;
  margin-right: -6px;
  padding-top: calc(100% + 36px);
}
.AD_bgx {
  border-radius: 10px 10px 0 0;
}
.AD_bgu {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin: 2px 2px 5px;
}
</style>
