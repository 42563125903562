<template>
  <div id="address-main" class="shipping-address sa-content">
    <!-- 列表 -->
    <div class="next-loading next-loading-inline">
      <div class="next-loading-wrap">
        <div class="address-list-header">
          <button @click="onCreateAddress" type="button" class="next-btn next-medium next-btn-primary">{{$t('address.new')}}</button>
        </div>
        <div class="address-list-wrap">
          <div class="address-item default" v-for="(item,i) in addressList" :key="i">
            <span class="default-tag" v-if="item.default == 2">{{$t('address.default')}}</span>
            <div class="address-main">
              <svg class="icon-svg icon-svg-medium address-item-icon">
                <use xlink:href="#icon-icAccount"></use>
              </svg>
              <span>{{item.contact}}</span>, {{item.phone_country}} {{item.phone}}
            </div>
            <div class="address-detail detail-main">
              <svg class="icon-svg icon-svg-medium address-item-icon">
                <use xlink:href="#icon-icLocation"></use>
              </svg>
              <b>{{item.address}}</b>
            </div>
            <div class="address-opt">
              <button @click="onEditAddress(item)" type="button" class="next-btn next-medium next-btn-primary next-btn-text" role="button">{{$t('address.edit')}}</button>
              <button @click="onRmAddress(item)" type="button" class="next-btn next-medium next-btn-primary next-btn-text" role="button">{{$t('address.delete')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 选择地址 -->
  <create-address ref="address" @setAddress="setAddress" :onlyAdd="true"></create-address>

  <!-- 确认框 -->
  <Confirm ref="confirm" :title="msgTitle" :content="msgContent" @onOk="onRmAddressOk"></Confirm>
</template>

<script>
import CreateAddress from "@/components/member/CreateAddress.vue";
import Confirm from "@/components/member/Confirm";
export default {
  name: "shippingAddress",
  components: {
    CreateAddress,
    Confirm
  },
  data () {
    return {
      addressList: [],

      //消息内容
      msgTitle: "",
      msgContent: "",
      msgRecord: {},
    }
  },
  created () {
    this.getAddress()
  },
  methods: {
    /**
     * 获取用户的收货地址
     */
    getAddress () {
      let that = this;
      this.$api.user.getAddress().then((res) => {
        that.addressList = res.data;
        //当前选中
        if (that.addressList.length > 0) {
          this.$emit("setAddress", this.addressList[0]);
        }
      });
    },
    onCreateAddress () {
      this.$refs.address.open(2);
    },
    setAddress () {
      this.getAddress()
      this.$refs.address.hide()
    },
    onEditAddress (item) {
      this.$refs.address.edit(item);
    },
    /**
     * 确认收货
     */
    onRmAddress (record) {
      this.msgRecord = record;
      this.msgTitle = this.$t('address.del.title');
      this.msgContent = this.$t('address.del.content');
      this.$refs.confirm.open();
    },
    onRmAddressOk () {
      let that = this;
      this.$message.loading({ content: "delete address...", key: "msg" });
      this.$api.user.rmAddress({ id: this.msgRecord.id }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: "msg",
        });
        that.$refs.confirm.hide();
        that.getAddress();
      })
    },
  },
};
</script>
<style scoped src="@/static/css/shipping-address.css"></style>