<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('_xxtz._zntz') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <!-- 3 -->
  <div style="display: flex; align-items: center; justify-content: center">
    <div style="width: 70%">
      <a-card>
        <div class="_dcc" v-if="initLoading">
          <a-spin tip="Loading..."></a-spin>
        </div>
        <div v-else>
          <h2 class="n-title">{{ info.title }}</h2>
          <div class="n-desc">{{ info.create_time }}</div>
          <div v-html="info.content" class="n-content"></div>
        </div>
      </a-card>
      <div class="n-back">
        <a-button
          type="dashed"
          @click="
            () => {
              $router.back(-1)
            }
          "
          >{{ $t('_xxtz._fh') }}</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'settingVue',
  components: {},
  data() {
    return {
      initLoading: true,
      info: {}
    }
  },
  created() {
    this.getNoticeInfo()
  },
  methods: {
    getNoticeInfo() {
      let that = this
      this.$api.notice
        .getNoticeInfo({ id: this.$route.query.id })
        .then((res) => {
          that.initLoading = false
          that.info = res.data
        })
        .catch(() => {
          // that.$message.error(res.msg)
          // setTimeout(function () {
          //   that.$router.back(-1)
          // }, 2000)
        })
    }
  }
}
</script>

<style scoped>
.b-content >>> td {
  border: 1px solid #dfdfdf !important;
}
.nav-header {
  display: flex;
  flex-direction: column;
  padding: 0 0px 20px 0px;
}

.nav-header .nv-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
}
.n-detail:hover .ant-list-item {
  background-color: #f7f7f7;
}

.ant-list-item {
  padding: 12px;
}

.n-title {
  font-size: 18px;
  font-weight: 700;
}

.n-desc {
  margin-bottom: 10px;
  color: #999;
}

.n-content {
  font-size: 15px;
}

.n-back {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
</style>
