<template>
  <div :class="[prefixCls, reverseColor && 'reverse-color' ]">
    <span>
      <slot name="term"></slot>
      <span class="item-text">
        <slot></slot>
      </span>
    </span>
    <span :class="[flag]">
      <caret-up-outlined v-if="flag == 'up'" />
      <caret-down-outlined v-if="flag == 'down'" />
      <!-- <a-icon :type="`caret-${flag}`" /> -->
    </span>
  </div>
</template>

<script>
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons-vue";
export default {
  name: "TrendVue",
  props: {
    prefixCls: {
      type: String,
      default: "ant-pro-trend",
    },
    /**
     * 上升下降标识：up|down
     */
    flag: {
      type: String,
      required: true,
    },
    /**
     * 颜色反转
     */
    reverseColor: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CaretUpOutlined,
    CaretDownOutlined,
  },
};
</script>

<style lang="less" scoped>
.ant-pro-trend {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
}
.ant-pro-trend .up {
  color: #f5222d;
}

.ant-pro-trend .down {
  top: -1px;
  color: #52c41a;
}
</style>
