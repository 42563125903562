<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('store.setting') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>

  <!-- 2 -->
  <a-card style="margin-bottom: 20px">
    <div>
      {{ $t('member.shop.setting') }}
    </div>
    <a-form :model="store" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 8 }" autocomplete="off">
      <a-form-item :label="$t('member.order.notify')" :help="$t('member.order.tips')">
        <a-switch v-model:checked="order_notify" @change="onOrderNotify($event)" />
      </a-form-item>
      <div style="margin-left: 16.66666667%; color: #f14e4e; width: 50%">{{ $t('order.notify.email',{email:'service@rackwo.com'}) }}</div>
    </a-form>
  </a-card>
</template>

<script>
export default {
  name: 'settingVue',
  components: {},
  data() {
    return {
      //通知状态
      order_notify: false
    }
  },
  created() {
    //获取信息
    let that = this
    this.$api.store.getShop().then((res) => {
      //通知状态
      that.order_notify = res.data.order_notify == 2 ? true : false
    })
  },
  methods: {
    // 订单通知
    onOrderNotify(e) {
      console.log(this.order_notify, e)
      const that = this
      this.$api.store
        .saveSetting({ order_notify: this.order_notify ? 2 : 1 })
        .then(() => {})
        .catch((err) => {
          err
          that.order_notify = !that.order_notify
        })
    }
  }
}
</script>
<style scoped src="../../../static/css/store-css.css"></style>

<style>
.a-del {
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.vo-name {
  font-size: 12px;
  white-space: normal;
  height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.op-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.del-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
